import React, { useState, useRef, useEffect } from "react";
import style from '../../../../../Purchase.module.scss'
import CloseIcon from "../../../../../../../shared/ui/icons/Close.jsx";
import DownArrow from "../../../../../../../shared/ui/icons/DownArrow.jsx";
import ArticleStore from "../../../../../../../store/Financing/ArticleStore";
import { API_URL, getData } from "../../../../../../../static/js/request";
import cx from 'classnames';

const FilterSelection = ({ value, action, column, label }) => {

    const selectRef = useRef();
    const searchRef = useRef()

    const [dataList, setDataList] = useState([])
    const [isOpen, setIsOpen] = useState(false);
    const [filter, setFilter] = useState('')

    const getDataList = async () => {

        const url = (column === 'warehouse') ? '/api/v1/general/user/warehouse/' : '/api/v1/purchase/payments/article/'

        await getData(API_URL + url).then((data) => {
            setDataList(data)
        })
    }

    const convertedList = (column === 'warehouse') ?
        (filter ?
            dataList.filter(item => item.name?.toLowerCase().includes(filter.toLowerCase())) :
            dataList
        ) :
        Object.keys(dataList)
            .map(key => ({
                value: Number(key),
                name: dataList[key][1]
            }))
            .filter(item => {
                const itemName = item.name?.toLowerCase();
                const filterValue = filter?.toLowerCase();
                return itemName && (filterValue ? itemName.includes(filterValue) : true);
            });

    const handleOptionClick = (option) => {         

        action(column, column === 'warehouse' ? [option.id] : [option.value])
        setFilter(column === 'warehouse' ? option.name : option.value)
        setIsOpen(false)

    }

    const handleClearClick = () => {       

        action(column, null)
        setFilter('')  
        setIsOpen(false);     
    }

    useEffect(() => {

        getDataList()

        const handleOutsideClick = (e) => {
            if (selectRef.current && !selectRef.current.contains(e.target)) {                
                setIsOpen(false);
                setFilter(value[0])                
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    return (
        <div ref={selectRef} className={style.article}>

            <div className={cx(style.selectContent, isOpen ? style.active : '')}>
                <div className={style.row}>
                    <div className={cx(style.button, isOpen ? style.active : '')} >
                        <div className={style.row} onClick={() => setIsOpen(true)}>                            
                            <div ref={searchRef} className={style.value}><input className={style.filter} placeholder='Введите значение' value={filter} type="text" onChange={(e) => setFilter(e.target.value)} /></div>
                            {value && <div className={style.closeIcon} onClick={() => handleClearClick()}><CloseIcon /></div>}
                            <DownArrow />
                        </div>


                    </div>

                </div>

                {isOpen && (
                    <div className={cx(style.optionList, isOpen ? style.active : '')}>
                        {convertedList.map((option, index) => (
                            <div

                                className={style.option}
                                key={index}
                                onClick={() => handleOptionClick(option)}
                            >

                                <span>{option.name}</span>

                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    )

}

export default FilterSelection