import styles from './styles.module.scss';
import React from 'react';

function LeftSide({tabs, setOpen, openTabs, hovered, setHoveredRow}) {
    const setActive = (tab) => {
        if (openTabs.includes(tab)) {
            setOpen([...openTabs.filter((item) => item !== tab)]);
        } else {
            setOpen([...openTabs, tab]);
        }
    };

    const onEnter = (item) => {
        setHoveredRow(item);
    };

    const onLeave = (item) => {
        setHoveredRow('');
    };

    if (!tabs) {
        return <p className={styles.empty}>Нет данных</p>;
    }

    return (
        <div className={styles.content}>
            <div className={`${styles.item}`}>
                <div
                    className={`${styles.row} ${
                        hovered === 'Активы' ? styles.hover : ''
                    }`}
                    onMouseEnter={() => onEnter('Активы')}
                    onMouseLeave={() => onLeave('Активы')}
                    onClick={() => setActive('Активы')}
                >
                    <p>Активы</p>
                    <div
                        className={`${styles.hoverStatus} ${
                            openTabs.includes('Активы') ? styles.active : ''
                        }`}
                    >
                        {openTabs.includes('Активы') ? (
                            <p className={styles.openText}>Свернуть</p>
                        ) : (
                            <p className={styles.closeText}>Развернуть</p>
                        )}
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                        >
                            <path
                                d="M15.2041 10.7174L9.59364 5.26607C9.2287 4.91131 8.63864 4.91131 8.2737 5.26607C7.90876 5.62067 7.90876 6.194 8.2737 6.5486L13.8842 12.0001L8.2737 17.4514C7.90877 17.8062 7.90877 18.3793 8.2737 18.7339C8.63864 19.0887 9.2287 19.0887 9.59364 18.7339L15.2041 13.2826C15.2041 13.2826 16 12.5705 16 12.0001C16 11.4297 15.2041 10.7174 15.2041 10.7174Z"
                                fill="#1E1E1E"
                            />
                        </svg>
                    </div>
                </div>
                {Object.entries(tabs['Активы']?.nestedEl).map((firstLevel, firstLevelIndex) => {
                    if (firstLevel[0] !== 'Активы') {
                        return (
                            <div
                                key={firstLevelIndex}
                                className={`${styles.rowContentFirstLevel} ${
                                    openTabs.includes(firstLevel[0]) ? styles.active : ''
                                } ${
                                    !(
                                        firstLevel[1].nestedEl &&
                                        Object.entries(firstLevel[1].nestedEl).length > 0
                                    )
                                        ? styles.unhovered
                                        : ''
                                }`}
                                style={{height: openTabs.includes('Активы') ? 'auto' : 0}}
                            >
                                <div
                                    style={{
                                        paddingLeft: firstLevel[1].nestedEl ? '30px' : '30px',
                                    }}
                                    className={`${styles.row} ${
                                        hovered === firstLevel[0] ? styles.hover : ''
                                    }`}
                                    onMouseEnter={() => onEnter(firstLevel[0])}
                                    onMouseLeave={() => onLeave(firstLevel[0])}
                                    onClick={() => setActive(firstLevel[0])}
                                >
                                    {firstLevel[1].nestedEl ? (
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="6"
                                            height="10"
                                            viewBox="0 0 6 10"
                                            fill="none"
                                        >
                                            <path
                                                d="M5.40311 4.08385L1.19523 0.190051C0.921526 -0.0633507 0.478983 -0.0633507 0.205278 0.190051C-0.0684266 0.443335 -0.0684266 0.852859 0.205278 1.10614L4.41315 5.00006L0.205279 8.89386C-0.0684262 9.14726 -0.0684262 9.55667 0.205279 9.80995C0.478984 10.0634 0.921526 10.0634 1.19523 9.80995L5.40311 5.91615C5.40311 5.91615 6 5.40748 6 5.00006C6 4.59263 5.40311 4.08385 5.40311 4.08385Z"
                                                fill="#1E1E1E"
                                            />
                                        </svg>
                                    ) : null}
                                    <p>{firstLevel[0]}</p>
                                </div>
                                {firstLevel[1].nestedEl &&
                                    Object.entries(firstLevel[1].nestedEl)?.map((secondLevel, secondLevelIndex) => (
                                        <div
                                            key={secondLevelIndex}
                                            className={`${styles.rowContentSecondLevel} ${
                                                openTabs.includes(secondLevel[0]) ? styles.active : ''
                                            } ${
                                                !(
                                                    secondLevel[1].nestedEl &&
                                                    Object.entries(secondLevel[1].nestedEl).length > 0
                                                )
                                                    ? styles.unhovered
                                                    : ''
                                            }`}
                                            style={{
                                                height: openTabs.includes(firstLevel[0]) ? 'auto' : 0,
                                            }}
                                        >
                                            <div
                                                style={{
                                                    paddingLeft: secondLevel[1].nestedEl
                                                        ? '60px'
                                                        : '60px',
                                                }}
                                                className={`${styles.row} ${
                                                    hovered === secondLevel[0] ? styles.hover : ''
                                                }`}
                                                onMouseEnter={() => onEnter(secondLevel[0])}
                                                onMouseLeave={() => onLeave(secondLevel[0])}
                                                onClick={() => setActive(secondLevel[0])}
                                            >
                                                {secondLevel[1].nestedEl &&
                                                Object.entries(secondLevel[1].nestedEl).length > 0 ? (
                                                    <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="6"
                                                            height="10"
                                                            viewBox="0 0 6 10"
                                                            fill="none"
                                                        >
                                                            <path
                                                            d="M5.40311 4.08385L1.19523 0.190051C0.921526 -0.0633507 0.478983 -0.0633507 0.205278 0.190051C-0.0684266 0.443335 -0.0684266 0.852859 0.205278 1.10614L4.41315 5.00006L0.205279 8.89386C-0.0684262 9.14726 -0.0684262 9.55667 0.205279 9.80995C0.478984 10.0634 0.921526 10.0634 1.19523 9.80995L5.40311 5.91615C5.40311 5.91615 6 5.40748 6 5.00006C6 4.59263 5.40311 4.08385 5.40311 4.08385Z"
                                                            fill="#1E1E1E"
                                                            />
                                                        </svg>
                                                    ) : null}
                                                <p>{secondLevel[0]}</p>
                                            </div>
                                            {secondLevel[1].nestedEl &&
                                                Object.entries(secondLevel[1].nestedEl).length > 0 &&
                                                Object.entries(secondLevel[1].nestedEl)?.map(
                                                    (thirdLevel, thirdLevelIndex) => (
                                                        <div
                                                            key={thirdLevelIndex}
                                                            className={`${styles.rowContentThirdLevel} ${
                                                                openTabs.includes(thirdLevel[0])
                                                                    ? styles.active
                                                                    : ''
                                                            } ${
                                                                !(
                                                                    thirdLevel[1].nestedEl &&
                                                                    Object.entries(thirdLevel[1].nestedEl)
                                                                        .length > 0
                                                                )
                                                                    ? styles.unhovered
                                                                    : ''
                                                            }`}
                                                            style={{
                                                                height: openTabs.includes(secondLevel[0])
                                                                    ? 'auto'
                                                                    : 0,
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    paddingLeft: thirdLevel[1].nestedEl
                                                                        ? '100px'
                                                                        : '100px',
                                                                }}
                                                                className={`${styles.row} ${
                                                                    hovered === thirdLevel[0] ? styles.hover : ''
                                                                }`}
                                                                onMouseEnter={() => onEnter(thirdLevel[0])}
                                                                onMouseLeave={() => onLeave(thirdLevel[0])}
                                                                onClick={() => setActive(thirdLevel[0])}
                                                            >
                                                                {thirdLevel[1].nestedEl &&
                                                                Object.entries(thirdLevel[1].nestedEl).length >
                                                                0 ? (
                                                                    <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="6"
                                                                            height="10"
                                                                            viewBox="0 0 6 10"
                                                                            fill="none"
                                                                        >
                                                                            <path
                                                                            d="M5.40311 4.08385L1.19523 0.190051C0.921526 -0.0633507 0.478983 -0.0633507 0.205278 0.190051C-0.0684266 0.443335 -0.0684266 0.852859 0.205278 1.10614L4.41315 5.00006L0.205279 8.89386C-0.0684262 9.14726 -0.0684262 9.55667 0.205279 9.80995C0.478984 10.0634 0.921526 10.0634 1.19523 9.80995L5.40311 5.91615C5.40311 5.91615 6 5.40748 6 5.00006C6 4.59263 5.40311 4.08385 5.40311 4.08385Z"
                                                                            fill="#1E1E1E"
                                                                            />
                                                                        </svg>
                                                                    ) : null}
                                                                <p>{thirdLevel[0]}</p>
                                                            </div>
                                                            {thirdLevel[1].nestedEl &&
                                                                Object.entries(thirdLevel[1].nestedEl).length >
                                                                0 &&
                                                                Object.entries(thirdLevel[1].nestedEl)?.map(
                                                                    (fourthLevel, fourthLevelIndex) => (
                                                                        <div
                                                                            key={fourthLevelIndex}
                                                                            className={`${
                                                                                styles.rowContentFourthLevel
                                                                            } ${
                                                                                openTabs.includes(fourthLevel[0])
                                                                                    ? styles.active
                                                                                    : ''
                                                                            } ${
                                                                                !(
                                                                                    fourthLevel[1].nestedEl &&
                                                                                    Object.entries(
                                                                                        fourthLevel[1].nestedEl
                                                                                    ).length > 0
                                                                                )
                                                                                    ? styles.unhovered
                                                                                    : ''
                                                                            }`}
                                                                            style={{
                                                                                height: openTabs.includes(thirdLevel[0])
                                                                                    ? 'auto'
                                                                                    : 0,
                                                                            }}
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    paddingLeft: fourthLevel[1].nestedEl
                                                                                        ? '120px'
                                                                                        : '120px',
                                                                                }}
                                                                                className={`${styles.row} ${
                                                                                    hovered === fourthLevel[0]
                                                                                        ? styles.hover
                                                                                        : ''
                                                                                }`}
                                                                                onMouseEnter={() =>
                                                                                    onEnter(fourthLevel[0])
                                                                                }
                                                                                onMouseLeave={() =>
                                                                                    onLeave(fourthLevel[0])
                                                                                }
                                                                                onClick={() =>
                                                                                    setActive(fourthLevel[0])
                                                                                }
                                                                            >
                                                                                {fourthLevel[1].nestedEl &&
                                                                                Object.entries(fourthLevel[1].nestedEl)
                                                                                    .length > 0 ? (
                                                                                        <svg
                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                            width="6"
                                                                                            height="10"
                                                                                            viewBox="0 0 6 10"
                                                                                            fill="none"
                                                                                        >
                                                                                            <path
                                                                                                d="M5.40311 4.08385L1.19523 0.190051C0.921526 -0.0633507 0.478983 -0.0633507 0.205278 0.190051C-0.0684266 0.443335 -0.0684266 0.852859 0.205278 1.10614L4.41315 5.00006L0.205279 8.89386C-0.0684262 9.14726 -0.0684262 9.55667 0.205279 9.80995C0.478984 10.0634 0.921526 10.0634 1.19523 9.80995L5.40311 5.91615C5.40311 5.91615 6 5.40748 6 5.00006C6 4.59263 5.40311 4.08385 5.40311 4.08385Z"
                                                                                                fill="#1E1E1E"
                                                                                            />
                                                                                        </svg>
                                                                                    ) : null}
                                                                                <p>{fourthLevel[0]}</p>
                                                                            </div>
                                                                            {fourthLevel[1].nestedEl &&
                                                                                Object.entries(fourthLevel[1].nestedEl)
                                                                                    .length > 0 &&
                                                                                Object.entries(
                                                                                    fourthLevel[1].nestedEl
                                                                                )?.map((fifthLevel, fifthLevelIndex) => (
                                                                                    <div
                                                                                        key={fifthLevelIndex}
                                                                                        className={`${
                                                                                            styles.rowContentFourthLevel
                                                                                        } ${
                                                                                            openTabs.includes(fifthLevel[0])
                                                                                                ? styles.active
                                                                                                : ''
                                                                                        } ${
                                                                                            !(
                                                                                                fifthLevel[1].nestedEl &&
                                                                                                Object.entries(
                                                                                                    fifthLevel[1].nestedEl
                                                                                                ).length > 0
                                                                                            )
                                                                                                ? styles.unhovered
                                                                                                : ''
                                                                                        }`}
                                                                                        style={{
                                                                                            height: openTabs.includes(
                                                                                                fourthLevel[0]
                                                                                            )
                                                                                                ? 'auto'
                                                                                                : 0,
                                                                                        }}
                                                                                    >
                                                                                        <div
                                                                                            style={{
                                                                                                paddingLeft: fifthLevel[1]
                                                                                                    .nestedEl
                                                                                                    ? '140px'
                                                                                                    : '140px',
                                                                                            }}
                                                                                            className={`${styles.row} ${
                                                                                                hovered === fifthLevel[0]
                                                                                                    ? styles.hover
                                                                                                    : ''
                                                                                            }`}
                                                                                            onMouseEnter={() =>
                                                                                                onEnter(fifthLevel[0])
                                                                                            }
                                                                                            onMouseLeave={() =>
                                                                                                onLeave(fifthLevel[0])
                                                                                            }
                                                                                            onClick={() =>
                                                                                                setActive(fifthLevel[0])
                                                                                            }
                                                                                        >
                                                                                            {fifthLevel[1].nestedEl &&
                                                                                            Object.entries(
                                                                                                fifthLevel[1].nestedEl
                                                                                            ).length > 0 ? (
                                                                                                <svg
                                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                                        width="6"
                                                                                                        height="10"
                                                                                                        viewBox="0 0 6 10"
                                                                                                        fill="none"
                                                                                                    >
                                                                                                        <path
                                                                                                        d="M5.40311 4.08385L1.19523 0.190051C0.921526 -0.0633507 0.478983 -0.0633507 0.205278 0.190051C-0.0684266 0.443335 -0.0684266 0.852859 0.205278 1.10614L4.41315 5.00006L0.205279 8.89386C-0.0684262 9.14726 -0.0684262 9.55667 0.205279 9.80995C0.478984 10.0634 0.921526 10.0634 1.19523 9.80995L5.40311 5.91615C5.40311 5.91615 6 5.40748 6 5.00006C6 4.59263 5.40311 4.08385 5.40311 4.08385Z"
                                                                                                        fill="#1E1E1E"
                                                                                                        />
                                                                                                    </svg>
                                                                                                ) : null}
                                                                                            <p>{fifthLevel[0]}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                ))}
                                                                        </div>
                                                                    )
                                                                )}
                                                        </div>
                                                    )
                                                )}
                                        </div>
                                    ))}
                            </div>
                        );
                    }
                })}
            </div>
            <div className={`${styles.item}`}>
                <div
                    className={`${styles.row} ${
                        hovered === 'Пассивы' ? styles.hover : ''
                    }`}
                    onMouseEnter={() => onEnter('Пассивы')}
                    onMouseLeave={() => onLeave('Пассивы')}
                    onClick={() => setActive('Пассивы')}
                >
                    <p>Пассивы</p>
                    {Object.keys(tabs['Пассивы']?.nestedEl).length > 0 && (
                        <div
                            className={`${styles.hoverStatus} ${
                                openTabs.includes('Пассивы') ? styles.active : ''
                            }`}
                        >
                            {openTabs.includes('Пассивы') ? (
                                <p className={styles.openText}>Свернуть</p>
                            ) : (
                                <p className={styles.closeText}>Развернуть</p>
                            )}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <path
                                    d="M15.2041 10.7174L9.59364 5.26607C9.2287 4.91131 8.63864 4.91131 8.2737 5.26607C7.90876 5.62067 7.90876 6.194 8.2737 6.5486L13.8842 12.0001L8.2737 17.4514C7.90877 17.8062 7.90877 18.3793 8.2737 18.7339C8.63864 19.0887 9.2287 19.0887 9.59364 18.7339L15.2041 13.2826C15.2041 13.2826 16 12.5705 16 12.0001C16 11.4297 15.2041 10.7174 15.2041 10.7174Z"
                                    fill="#1E1E1E"
                                />
                            </svg>
                        </div>
                    )}
                </div>
                {Object.entries(tabs['Пассивы'].nestedEl).map((firstLevel, firstLevelIndex) => {
                    if (firstLevel[0] !== 'Пассивы') {
                        return (
                            <div
                                key={firstLevelIndex}
                                className={`${styles.rowContentFirstLevel} ${
                                    openTabs.includes(firstLevel[0]) ? styles.active : ''
                                } ${
                                    !(
                                        firstLevel[1].nestedEl &&
                                        Object.entries(firstLevel[1].nestedEl).length > 0
                                    )
                                        ? styles.unhovered
                                        : ''
                                }`}
                                style={{height: openTabs.includes('Пассивы') ? 'auto' : 0}}
                            >
                                <div
                                    style={{
                                        paddingLeft: firstLevel[1].nestedEl ? '30px' : '30px',
                                    }}
                                    className={`${styles.row} ${
                                        hovered === firstLevel[0] ? styles.hover : ''
                                    }`}
                                    onMouseEnter={() => onEnter(firstLevel[0])}
                                    onMouseLeave={() => onLeave(firstLevel[0])}
                                    onClick={() => setActive(firstLevel[0])}
                                >
                                    {firstLevel[1].nestedEl ? (
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="6"
                                            height="10"
                                            viewBox="0 0 6 10"
                                            fill="none"
                                        >
                                            <path
                                                d="M5.40311 4.08385L1.19523 0.190051C0.921526 -0.0633507 0.478983 -0.0633507 0.205278 0.190051C-0.0684266 0.443335 -0.0684266 0.852859 0.205278 1.10614L4.41315 5.00006L0.205279 8.89386C-0.0684262 9.14726 -0.0684262 9.55667 0.205279 9.80995C0.478984 10.0634 0.921526 10.0634 1.19523 9.80995L5.40311 5.91615C5.40311 5.91615 6 5.40748 6 5.00006C6 4.59263 5.40311 4.08385 5.40311 4.08385Z"
                                                fill="#1E1E1E"
                                            />
                                        </svg>
                                    ) : null}
                                    <p>{firstLevel[0]}</p>
                                </div>
                                {firstLevel[1].nestedEl &&
                                    Object.entries(firstLevel[1].nestedEl)?.map((secondLevel, secondLevelIndex) => (
                                        <div
                                            key={secondLevelIndex}
                                            className={`${styles.rowContentSecondLevel} ${
                                                openTabs.includes(secondLevel[0]) ? styles.active : ''
                                            } ${
                                                !(
                                                    secondLevel[1].nestedEl &&
                                                    Object.entries(secondLevel[1].nestedEl).length > 0
                                                )
                                                    ? styles.unhovered
                                                    : ''
                                            }`}
                                            style={{
                                                height: openTabs.includes(firstLevel[0]) ? 'auto' : 0,
                                            }}
                                        >
                                            <div
                                                style={{
                                                    paddingLeft: secondLevel[1].nestedEl
                                                        ? '60px'
                                                        : '60px',
                                                }}
                                                className={`${styles.row} ${
                                                    hovered === secondLevel[0] ? styles.hover : ''
                                                }`}
                                                onMouseEnter={() => onEnter(secondLevel[0])}
                                                onMouseLeave={() => onLeave(secondLevel[0])}
                                                onClick={() => setActive(secondLevel[0])}
                                            >
                                                {secondLevel[1].nestedEl &&
                                                Object.entries(secondLevel[1].nestedEl).length > 0 ? (
                                                    <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="6"
                                                            height="10"
                                                            viewBox="0 0 6 10"
                                                            fill="none"
                                                        >
                                                            <path
                                                            d="M5.40311 4.08385L1.19523 0.190051C0.921526 -0.0633507 0.478983 -0.0633507 0.205278 0.190051C-0.0684266 0.443335 -0.0684266 0.852859 0.205278 1.10614L4.41315 5.00006L0.205279 8.89386C-0.0684262 9.14726 -0.0684262 9.55667 0.205279 9.80995C0.478984 10.0634 0.921526 10.0634 1.19523 9.80995L5.40311 5.91615C5.40311 5.91615 6 5.40748 6 5.00006C6 4.59263 5.40311 4.08385 5.40311 4.08385Z"
                                                            fill="#1E1E1E"
                                                            />
                                                        </svg>
                                                    ) : null}
                                                <p>{secondLevel[0]}</p>
                                            </div>
                                            {secondLevel[1].nestedEl &&
                                                Object.entries(secondLevel[1].nestedEl).length > 0 &&
                                                Object.entries(secondLevel[1].nestedEl)?.map(
                                                    (thirdLevel, thirdLevelIndex) => (
                                                        <div
                                                            key={thirdLevelIndex}
                                                            className={`${styles.rowContentThirdLevel} ${
                                                                openTabs.includes(thirdLevel[0])
                                                                    ? styles.active
                                                                    : ''
                                                            } ${
                                                                !(
                                                                    thirdLevel[1].nestedEl &&
                                                                    Object.entries(thirdLevel[1].nestedEl)
                                                                        .length > 0
                                                                )
                                                                    ? styles.unhovered
                                                                    : ''
                                                            }`}
                                                            style={{
                                                                height: openTabs.includes(secondLevel[0])
                                                                    ? 'auto'
                                                                    : 0,
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    paddingLeft: thirdLevel[1].nestedEl
                                                                        ? '100px'
                                                                        : '100px',
                                                                }}
                                                                className={`${styles.row} ${
                                                                    hovered === thirdLevel[0] ? styles.hover : ''
                                                                }`}
                                                                onMouseEnter={() => onEnter(thirdLevel[0])}
                                                                onMouseLeave={() => onLeave(thirdLevel[0])}
                                                                onClick={() => setActive(thirdLevel[0])}
                                                            >
                                                                {thirdLevel[1].nestedEl &&
                                                                Object.entries(thirdLevel[1].nestedEl).length >
                                                                0 ? (
                                                                    <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="6"
                                                                            height="10"
                                                                            viewBox="0 0 6 10"
                                                                            fill="none"
                                                                        >
                                                                            <path
                                                                            d="M5.40311 4.08385L1.19523 0.190051C0.921526 -0.0633507 0.478983 -0.0633507 0.205278 0.190051C-0.0684266 0.443335 -0.0684266 0.852859 0.205278 1.10614L4.41315 5.00006L0.205279 8.89386C-0.0684262 9.14726 -0.0684262 9.55667 0.205279 9.80995C0.478984 10.0634 0.921526 10.0634 1.19523 9.80995L5.40311 5.91615C5.40311 5.91615 6 5.40748 6 5.00006C6 4.59263 5.40311 4.08385 5.40311 4.08385Z"
                                                                            fill="#1E1E1E"
                                                                            />
                                                                        </svg>
                                                                    ) : null}
                                                                <p>{thirdLevel[0]}</p>
                                                            </div>
                                                            {thirdLevel[1].nestedEl &&
                                                                Object.entries(thirdLevel[1].nestedEl).length >
                                                                0 &&
                                                                Object.entries(thirdLevel[1].nestedEl)?.map(
                                                                    (fourthLevel, fourthLevelIndex) => (
                                                                        <div
                                                                            key={fourthLevelIndex}
                                                                            className={`${
                                                                                styles.rowContentFourthLevel
                                                                            } ${
                                                                                openTabs.includes(fourthLevel[0])
                                                                                    ? styles.active
                                                                                    : ''
                                                                            } ${
                                                                                !(
                                                                                    fourthLevel[1].nestedEl &&
                                                                                    Object.entries(
                                                                                        fourthLevel[1].nestedEl
                                                                                    ).length > 0
                                                                                )
                                                                                    ? styles.unhovered
                                                                                    : ''
                                                                            }`}
                                                                            style={{
                                                                                height: openTabs.includes(thirdLevel[0])
                                                                                    ? 'auto'
                                                                                    : 0,
                                                                            }}
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    paddingLeft: fourthLevel[1].nestedEl
                                                                                        ? '120px'
                                                                                        : '120px',
                                                                                }}
                                                                                className={`${styles.row} ${
                                                                                    hovered === fourthLevel[0]
                                                                                        ? styles.hover
                                                                                        : ''
                                                                                }`}
                                                                                onMouseEnter={() =>
                                                                                    onEnter(fourthLevel[0])
                                                                                }
                                                                                onMouseLeave={() =>
                                                                                    onLeave(fourthLevel[0])
                                                                                }
                                                                                onClick={() =>
                                                                                    setActive(fourthLevel[0])
                                                                                }
                                                                            >
                                                                                {fourthLevel[1].nestedEl &&
                                                                                Object.entries(fourthLevel[1].nestedEl)
                                                                                    .length > 0 ? (
                                                                                        <svg
                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                            width="6"
                                                                                            height="10"
                                                                                            viewBox="0 0 6 10"
                                                                                            fill="none"
                                                                                        >
                                                                                            <path
                                                                                                d="M5.40311 4.08385L1.19523 0.190051C0.921526 -0.0633507 0.478983 -0.0633507 0.205278 0.190051C-0.0684266 0.443335 -0.0684266 0.852859 0.205278 1.10614L4.41315 5.00006L0.205279 8.89386C-0.0684262 9.14726 -0.0684262 9.55667 0.205279 9.80995C0.478984 10.0634 0.921526 10.0634 1.19523 9.80995L5.40311 5.91615C5.40311 5.91615 6 5.40748 6 5.00006C6 4.59263 5.40311 4.08385 5.40311 4.08385Z"
                                                                                                fill="#1E1E1E"
                                                                                            />
                                                                                        </svg>
                                                                                    ) : null}
                                                                                <p>{fourthLevel[0]}</p>
                                                                            </div>
                                                                            {fourthLevel[1].nestedEl &&
                                                                                Object.entries(fourthLevel[1].nestedEl)
                                                                                    .length > 0 &&
                                                                                Object.entries(
                                                                                    fourthLevel[1].nestedEl
                                                                                )?.map((fifthLevel, fifthLevelIndex) => (
                                                                                    <div
                                                                                        key={fifthLevelIndex}
                                                                                        className={`${
                                                                                            styles.rowContentFourthLevel
                                                                                        } ${
                                                                                            openTabs.includes(fifthLevel[0])
                                                                                                ? styles.active
                                                                                                : ''
                                                                                        } ${
                                                                                            !(
                                                                                                fifthLevel[1].nestedEl &&
                                                                                                Object.entries(
                                                                                                    fifthLevel[1].nestedEl
                                                                                                ).length > 0
                                                                                            )
                                                                                                ? styles.unhovered
                                                                                                : ''
                                                                                        }`}
                                                                                        style={{
                                                                                            height: openTabs.includes(
                                                                                                fourthLevel[0]
                                                                                            )
                                                                                                ? 'auto'
                                                                                                : 0,
                                                                                        }}
                                                                                    >
                                                                                        <div
                                                                                            style={{
                                                                                                paddingLeft: fifthLevel[1]
                                                                                                    .nestedEl
                                                                                                    ? '140px'
                                                                                                    : '140px',
                                                                                            }}
                                                                                            className={`${styles.row} ${
                                                                                                hovered === fifthLevel[0]
                                                                                                    ? styles.hover
                                                                                                    : ''
                                                                                            }`}
                                                                                            onMouseEnter={() =>
                                                                                                onEnter(fifthLevel[0])
                                                                                            }
                                                                                            onMouseLeave={() =>
                                                                                                onLeave(fifthLevel[0])
                                                                                            }
                                                                                            onClick={() =>
                                                                                                setActive(fifthLevel[0])
                                                                                            }
                                                                                        >
                                                                                            {fifthLevel[1].nestedEl &&
                                                                                            Object.entries(
                                                                                                fifthLevel[1].nestedEl
                                                                                            ).length > 0 ? (
                                                                                                <svg
                                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                                        width="6"
                                                                                                        height="10"
                                                                                                        viewBox="0 0 6 10"
                                                                                                        fill="none"
                                                                                                    >
                                                                                                        <path
                                                                                                        d="M5.40311 4.08385L1.19523 0.190051C0.921526 -0.0633507 0.478983 -0.0633507 0.205278 0.190051C-0.0684266 0.443335 -0.0684266 0.852859 0.205278 1.10614L4.41315 5.00006L0.205279 8.89386C-0.0684262 9.14726 -0.0684262 9.55667 0.205279 9.80995C0.478984 10.0634 0.921526 10.0634 1.19523 9.80995L5.40311 5.91615C5.40311 5.91615 6 5.40748 6 5.00006C6 4.59263 5.40311 4.08385 5.40311 4.08385Z"
                                                                                                        fill="#1E1E1E"
                                                                                                        />
                                                                                                    </svg>
                                                                                                ) : null}
                                                                                            <p>{fifthLevel[0]}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                ))}
                                                                        </div>
                                                                    )
                                                                )}
                                                        </div>
                                                    )
                                                )}
                                        </div>
                                    ))}
                            </div>
                        );
                    }
                })}
            </div>
            {Object.keys(tabs)
                .filter((tab) => tab !== 'Активы' && tab !== 'Пассивы')
                .map((tab, tabIndex) => (
                    <div
                        key={tabIndex}
                        className={`${styles.row} ${
                            tab === 'Проверка' ? styles.check : ''
                        } ${hovered === tab ? styles.hover : ''}`}
                        onMouseEnter={() => onEnter(tab)}
                        onMouseLeave={() => onLeave(tab)}
                    >
                        {tab}
                    </div>
                ))}
        </div>
    );
}

export default LeftSide;
