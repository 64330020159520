import React, { useRef } from "react";
import style from '../../../Purchase.module.scss'
import TableItem from './item/index.jsx';
import useSyncScroll from "../../../../../static/js/useSyncScroll.jsx";
import { useNavigate } from "react-router-dom";
import cx from 'classnames';
import ItemAction from "./action.jsx";


const TableLine = ({ data, columns, headRef, itemLink, removeItem }) => {

    const elementRef = useRef(null);
    const navigate = useNavigate()

    useSyncScroll(headRef, elementRef, { horizontal: true });

    return (
        <div className={style.row}>

            <div className={style.xScroll} ref={elementRef}>                

                <div style={{ display: 'flex' }} onClick={() => navigate(`${itemLink.value}${data.id}`)}>
                    {Object.values(columns).map((column, columnIndex) =>

                        column.isShow &&
                        <TableItem key={columnIndex} item={data} column={column} />

                    )}

                </div>

                <div style={{ minWidth: '15px' }} className={cx(style.column, style.action)}>
                    <ItemAction remove={removeItem} id={data.id} />
                </div>

            </div>



        </div>
    )
}

export default TableLine