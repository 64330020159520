import React, { useEffect, useRef, useState } from 'react';
import style from '../../components/custom/CustomSelect/style.module.scss';
import cx from 'classnames';
import DownArrow from '../../shared/ui/icons/DownArrow.jsx';

const CustomSelectNew = ({ required, options, defaultOption, title, onOptionChange, width, fieldName, value, externalStyle, needSearch = false }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [filter, setFilter] = useState('')
    const [selectedOption, setSelectedOption] = useState(defaultOption);
    const selectRef = useRef();

    const filteredData = options.filter((item) => item.name?.toLowerCase().includes(filter?.toLowerCase()));

    const getSelectedOption = () => {

        if (value) {
            return options.find(opt => opt.name === value || opt.id === value);
        }

    }

    const updateSelectedOption = () => {

        const currentOption = getSelectedOption();

        if (currentOption) {
            setSelectedOption(currentOption);
            if (needSearch) {
                setFilter(currentOption.name)
            }
        }
    }


    const handleOutsideClick = (e) => {
        if (selectRef.current && !selectRef.current.contains(e.target)) {
            setIsOpen(false);
            updateSelectedOption()
        }
    };

    useEffect(() => {

        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };


    }, []);

    useEffect(() => {

        updateSelectedOption()

    }, [options, value]);

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setIsOpen(false)

        if (needSearch) {
            setFilter(option.name)
        }

        if (onOptionChange) {
            onOptionChange(option, fieldName); // вызов функции обратного вызова с выбранным значением

        }

    }

    return (
        <div ref={selectRef} className={externalStyle ? externalStyle.select : style.customSelect} style={{ width: width ? width : '' }}>

            {
                title
                &&
                <div className={externalStyle ? externalStyle.title : style.title}>
                    {title}
                    {required && <span className={externalStyle ? externalStyle.required : style.required}>*</span>}
                </div>
            }

            <div className={cx(externalStyle ? externalStyle.selectContent : style.customSelectContent, isOpen ? (externalStyle ? externalStyle.active : style.active) : '')}>

                <div
                    className={cx(externalStyle ? externalStyle.button : style.button, isOpen ? (externalStyle ? externalStyle.active : style.active) : '', (selectedOption.name !== defaultOption.name) ? (externalStyle ? externalStyle.selected : style.selected) : '')}
                    onClick={() => { !isOpen && setIsOpen(true) }}>
                    {

                        needSearch ?

                            <input className={cx(externalStyle ? externalStyle.filter : '')} placeholder='Введите значение' value={filter} type="text" onChange={(e) => setFilter(e.target.value)} />

                            :

                            <span className={selectedOption.style ? (externalStyle ? externalStyle[selectedOption.style] : style[selectedOption.style]) : ''}>{selectedOption.name || defaultOption.name}</span>

                    }

                    <DownArrow />
                </div>


                {isOpen && (
                    <div className={cx(externalStyle ? externalStyle.optionList : style.optionList, isOpen ? (externalStyle ? externalStyle.active : style.active) : '')}>
                        {filteredData.map((option, index) => (
                            <div

                                className={externalStyle ? externalStyle.option : style.option}
                                key={index}
                                onClick={() => handleOptionClick(option)}
                            >

                                {option.style ?
                                    <span className={externalStyle ? externalStyle[option.style] : style[option.style]}>{option.name}</span>
                                    :
                                    option.name
                                }

                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default CustomSelectNew;