// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ZLDh2niOUR8LYWLd8VFq {\n  background: transparent;\n}\n.ZLDh2niOUR8LYWLd8VFq.xmaPnTtdtGFyCuh2gmN_ {\n  overflow-x: auto;\n  padding-top: 5px;\n  position: sticky;\n}\n.ZLDh2niOUR8LYWLd8VFq.xmaPnTtdtGFyCuh2gmN_ .mdvsvLeT4kA_u6oQMSK6 {\n  height: 1px;\n}\n.ZLDh2niOUR8LYWLd8VFq.P3x1tXYC0e4ayxBrZGUc {\n  position: absolute;\n  right: 20px;\n  top: 215px;\n  /*width: 9px;*/\n  overflow-y: auto;\n  margin-left: 0px;\n}\n.ZLDh2niOUR8LYWLd8VFq.P3x1tXYC0e4ayxBrZGUc .mdvsvLeT4kA_u6oQMSK6 {\n  width: 10px;\n}\n.ZLDh2niOUR8LYWLd8VFq.P3x1tXYC0e4ayxBrZGUc.gyqh2dI1T6ebY2gwMWIe {\n  right: auto;\n  top: auto;\n  margin-left: 1232px;\n  margin-top: 190px;\n}", "",{"version":3,"sources":["webpack://./src/components/custom/ScrollBar/styles.modules.scss"],"names":[],"mappings":"AAAA;EAEE,uBAAA;AAAF;AAEE;EACE,gBAAA;EACA,gBAAA;EACA,gBAAA;AAAJ;AAEI;EACE,WAAA;AAAN;AAKE;EACE,kBAAA;EACA,WAAA;EACA,UAAA;EACA,cAAA;EACA,gBAAA;EACA,gBAAA;AAHJ;AAKI;EACE,WAAA;AAHN;AAMI;EACE,WAAA;EACA,SAAA;EACA,mBAAA;EACA,iBAAA;AAJN","sourcesContent":[".scrollbarContainer {\n\n  background: transparent;\n\n  &.horizontal {\n    overflow-x: auto;\n    padding-top: 5px;\n    position: sticky;\n\n    .scrollbarContent {\n      height: 1px;\n    }\n\n  }\n\n  &.vertical {\n    position: absolute;\n    right: 20px;\n    top: 215px;    \n    /*width: 9px;*/\n    overflow-y: auto;\n    margin-left: 0px;\n\n    .scrollbarContent {      \n      width: 10px;\n    }\n\n    &.modal{\n      right: auto;\n      top: auto;\n      margin-left: 1232px;\n      margin-top: 190px;\n    }\n\n  }\n\n\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scrollbarContainer": "ZLDh2niOUR8LYWLd8VFq",
	"horizontal": "xmaPnTtdtGFyCuh2gmN_",
	"scrollbarContent": "mdvsvLeT4kA_u6oQMSK6",
	"vertical": "P3x1tXYC0e4ayxBrZGUc",
	"modal": "gyqh2dI1T6ebY2gwMWIe"
};
export default ___CSS_LOADER_EXPORT___;
