import React from "react";
import ReactDOM from "react-dom";
import styles from "./styles.module.scss";
import LineSVG from "../../shared/ui/icons/Line.jsx";

const ExecutionDescription = ({ isVisible, position, plan, fact }) => {
    if (!isVisible) return null;

    const result = (fact / plan) * 100;

    return ReactDOM.createPortal(
        <div
            className={styles.executionDescription}
            style={{
                top: position.top,
                left: position.left,
                position: "absolute",
                zIndex: 9999,
            }}
        >
            <div className={styles.row}>
                <div>Продано:</div>
                <div>{fact}</div>
            </div>
            <div className={styles.row}>
                <div>План:</div>
                <div>{plan}</div>
            </div>            
            <div className={`${styles.row} ${styles.bottom}`}>
                <div>Выполнение плана:</div>
                <div>{result.toFixed(2)}%</div>
            </div>
        </div>,
        document.body
    );
};

export default ExecutionDescription;
