import React, { useRef } from 'react';
import { formatNumber2 } from '../../../../../../static/js/commonFunc';
import { format, parseISO } from 'date-fns';
import style from '../../../../Purchase.module.scss'
import cx from 'classnames';
import PlanColumn from './plan.jsx';

const TableItem = ({ item, column }) => {

    const prepareStatusClass = (value) => {
        switch (value) {
            case 'Заказ выполнен':
                return 'success'
            case 'Частично выполнен':
                return 'warning'
            case 'Не выполнен':
                return 'error'
            case 'В пути':
                return 'onWay'
        }

    }

    const prepareDateDifference = (prev, current) => {

        const prevDate = new Date(prev);
        const currentDate = new Date(current);

        const diffInMilliseconds = Math.abs(currentDate - prevDate);

        const days = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
        const hours = Math.floor((diffInMilliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

        return (<span>Время доставки: {days}д. {hours}ч.</span>)

    }

    const prepareColumn = (item, column) => {
        switch (column.type) {
            case 'pay':
                return <span className={style.pay}>{formatNumber2(item[column.value])}</span>

            case 'object':
                return item[column.value].name

            case 'id':
            case 'count':
                return <span className={style.center}>{item[column.value]}</span>

            case 'status':
                return <span className={cx(style.center, style.status, style[prepareStatusClass(item[column.value])])}>{item[column.value]}</span>

            case 'date':
                return <><span className={style.date}>{format(parseISO(item[column.value]), 'dd.MM.yyyy')}</span><span>{format(parseISO(item[column.value]), 'HH:mm')}</span></>

            case 'sending':
                return <div className={style.moving}>
                    <div style={{ width: column.width }} className={style.warehouse}><p title={item.sender_warehouse.name}>{item.sender_warehouse.name}</p></div>
                    <div className={style.bottom}><span className={cx(style.description, style.onWay)}>Отправка: </span><span className={style.date}>{format(parseISO(item[column.value]), 'dd.MM.yyyy')}</span><span>{format(parseISO(item[column.value]), 'HH:mm')}</span></div>
                </div>

            case 'arrival':
                return <div className={style.moving}>
                    <div style={{ width: column.width }} className={style.warehouse}><p title={item.sender_warehouse.name}>{item.destination_warehouse.name}</p></div>
                    <div className={style.bottom}><span className={cx(style.description, style.onWay)}>Прибытие: </span><span className={style.date}>{format(parseISO(item[column.value]), 'dd.MM.yyyy')}</span><span>{format(parseISO(item[column.value]), 'HH:mm')}</span></div>
                </div>

            case 'delivery_date':
                return <div className={style.moving}>
                    <div>{prepareDateDifference(item.sending, item.delivery_date)}</div>
                    <div className={style.bottom}><span className={cx(style.description, (item.status === 'Заказ выполнен' ? style.success : style.warning))}>{item.status === 'Заказ выполнен' ? 'Принят' : 'Ожидается'}: </span><span className={style.date}>{format(parseISO(item.delivery_date), 'dd.MM.yyyy')}</span><span>{format(parseISO(item.delivery_date), 'HH:mm')}</span></div>
                </div>

            case 'plan':                
                return <PlanColumn plan={item.plan.plan} fact={item.plan.fact} />


            default:
                return item[column.value]
        }
    }

    return (
        <div style={{ minWidth: column.width }} className={style.column}>{prepareColumn(item, column)}</div>
    )
}

export default TableItem