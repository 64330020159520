import React from "react";
import style from '../../../../../Purchase.module.scss'
import CloseIcon from "../../../../../../../shared/ui/icons/Close.jsx";
import MagnifierIcon from "../../../../../../../shared/ui/icons/Magnifier.jsx";
import { formatNumber2 } from "../../../../../../../static/js/commonFunc";
CloseIcon

const FilterComment = ({ value, action }) => {    

    return (
        <div className={style.comment}>
            <div className={style.icon}><MagnifierIcon /></div>
            <div className={style.value}><input type="text" value={value ? value : ''} onChange={(e) => action('comment',[e.target.value])} /></div>
            {value && <div className={style.closeIcon} onClick={() => action('comment',null)}><CloseIcon /></div>}
        </div>
    )

}

export default FilterComment