import React, { useEffect, useRef, useState } from 'react';
import style from './header.module.scss';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import cx from 'classnames';
import { useUserStore } from '../../../store/Login/useUserStore';
import { observer } from 'mobx-react-lite';
import { API_URL, getData } from '../../../static/js/request';
import logo from '../../../views/Landing/images/logo.svg';
import styles from '../../../views/FAQ/ui/Header/Header.module.scss';
import SearchBar from '../../../views/FAQ/ui/SearchBar/SearchBar.jsx';

const Header = observer(({ full }) => {
    const navigate = useNavigate();
    const userStore = useUserStore();

    const [isHovered, setIsHovered] = useState(false);
    const [userData, setUserData] = useState('');
    const [reportIsActive, setReportIsActive] = useState(false);
    const [purchaseIsActive, setPurchaseIsActive] = useState(false);
    const [analyticIsActive, setAnalyticIsActive] = useState(false);
    const [shedulerIsActive, setShedulerActive] = useState(false);
    const menuRef = useRef(null);

    const location = useLocation();

    useEffect(() => {
        if (location.pathname.includes('financing')) {
            setReportIsActive(true);
            setShedulerActive(false);
            setAnalyticIsActive(false);
            setPurchaseIsActive(false);
        } else if (location.pathname.includes('scheduler')) {
            setShedulerActive(true);
            setReportIsActive(false);
            setAnalyticIsActive(false);
            setPurchaseIsActive(false);
        } else if (location.pathname.includes('purchase')) {
            setShedulerActive(false);
            setPurchaseIsActive(true);
            setReportIsActive(false);
            setAnalyticIsActive(false);
        } else if (location.pathname.includes('report')) {
            setReportIsActive(true);
            setShedulerActive(false);
            setAnalyticIsActive(false);
            setPurchaseIsActive(false);
        } else if (
            location.pathname.includes('analytics') ||
            location.pathname.includes('economy')
        ) {
            setReportIsActive(false);
            setShedulerActive(false);
            setAnalyticIsActive(true);
            setPurchaseIsActive(false);
        } else {
            setShedulerActive(false);
            setReportIsActive(false);
            setAnalyticIsActive(false);
            setPurchaseIsActive(false);
        }
    }, [location]);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    useEffect(() => {
        if (
            !userStore.isLogged &&
            location.pathname !== '/signup/' &&
            location.pathname !== '/policy'
        ) {
            navigate('/login');
        }
        if (!userData) {
            getData(API_URL + '/api/v1/account/').then((data) => {
                if (data.detail === 'Недопустимый токен.') {
                    handleOut();
                }
                setUserData(data);
            });
        }
    }, [userStore.isLogged, navigate]);

    const handleOut = () => {
        userStore.handleOut().then(() => {
            navigate('/login');
        });
    };

    const [faqSearch, setFaqSearch] = useState(false);

    useEffect(() => {
        // console.log(faqSearch);
    }, [faqSearch]);

    return (
        <header className={style.header}>
            <div className={`${style.faqSearch} ${faqSearch ? style.active : ''}`}>
                <SearchBar active={faqSearch} onClose={() => setFaqSearch(false)} />
            </div>
            <div
                className={style['header-nav']}
                style={{ maxWidth: full ? '100vw' : '' }}
            >
                {location.pathname.includes('faq') ? (
                    <div className={styles.content}>
                        <Link to={'/faq'} className={styles.logo}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="120"
                                height="24"
                                viewBox="0 0 120 24"
                                fill="none"
                            >
                                <path
                                    d="M0 18.4367V1.73898H8.17164C9.83826 1.62033 11.499 2.03879 12.9104 2.93301C13.4144 3.28689 13.823 3.76 14.0997 4.31016C14.3764 4.86031 14.5126 5.47039 14.4963 6.08599C14.5115 6.89297 14.284 7.68592 13.8433 8.36211C13.373 9.02412 12.7267 9.54116 11.9776 9.85465C11.1692 10.219 10.2894 10.3975 9.40299 10.377L9.8694 9.38823C10.8215 9.37589 11.7666 9.55348 12.6493 9.91062C13.409 10.2151 14.0593 10.7418 14.5149 11.4218C14.9802 12.157 15.2142 13.0149 15.1866 13.8845C15.2119 14.5403 15.0713 15.192 14.7778 15.779C14.4843 16.3661 14.0474 16.8695 13.5075 17.2427C12.0006 18.1389 10.2567 18.5554 8.50746 18.4367H0ZM3.84328 15.5263H8.35821C9.1474 15.5813 9.93615 15.4132 10.6343 15.0412C10.8951 14.8759 11.1058 14.6427 11.2439 14.3665C11.382 14.0904 11.4421 13.7818 11.4179 13.4741C11.4225 13.1718 11.3536 12.8729 11.2171 12.6031C11.0806 12.3334 10.8806 12.1009 10.6343 11.9255C9.9384 11.5461 9.14926 11.3715 8.35821 11.4218H3.56343V8.58599H7.68657C8.43021 8.65566 9.17872 8.52015 9.85075 8.1942C10.0958 8.03342 10.2939 7.81064 10.425 7.54849C10.5561 7.28635 10.6154 6.99418 10.597 6.70167C10.614 6.41196 10.5539 6.12293 10.4228 5.86402C10.2917 5.60512 10.0943 5.38558 9.85075 5.22779C9.19732 4.83714 8.44775 4.63683 7.68657 4.64943H3.84328V15.5263Z"
                                    fill="#414042"
                                />
                                <path
                                    d="M22.0532 18.6421C21.0647 18.6622 20.0857 18.4447 19.1987 18.0078C18.4043 17.5865 17.7544 16.9366 17.3331 16.1421C16.8054 15.1296 16.5422 14 16.5681 12.8585V5.60107H20.2995V12.3175C20.2308 13.1566 20.4695 13.9921 20.9711 14.6682C21.2142 14.914 21.5054 15.1071 21.8263 15.2355C22.1473 15.3639 22.4913 15.4248 22.8368 15.4145C23.3701 15.4215 23.8965 15.2931 24.3667 15.0414C24.8271 14.7829 25.1931 14.3842 25.4114 13.9033C25.6779 13.3179 25.8117 12.6808 25.8032 12.0376V5.60107H29.5346V18.4369H26.1204V14.9108L26.7547 15.9369C26.3635 16.7903 25.7098 17.4962 24.889 17.9518C24.0189 18.4221 23.0422 18.6599 22.0532 18.6421Z"
                                    fill="#414042"
                                />
                                <path
                                    d="M35.9894 18.6421C34.9247 18.6483 33.864 18.5102 32.8364 18.2317C31.9837 18.0416 31.1697 17.7072 30.4297 17.2429L31.717 14.575C32.3652 14.9756 33.0694 15.2775 33.8066 15.4705C34.5765 15.6963 35.3737 15.8155 36.176 15.825C36.8145 15.8741 37.4552 15.7651 38.0416 15.5078C38.2036 15.4304 38.3403 15.3087 38.4361 15.1568C38.5318 15.005 38.5826 14.8291 38.5827 14.6496C38.5811 14.5063 38.5403 14.3662 38.4646 14.2445C38.389 14.1227 38.2814 14.0241 38.1536 13.9593C37.8078 13.7872 37.4358 13.6737 37.0528 13.6235L35.4483 13.3809C34.8898 13.304 34.3355 13.1981 33.7879 13.0638C33.2488 12.9402 32.7325 12.7325 32.258 12.4481C31.7945 12.1862 31.4083 11.8065 31.1386 11.3474C30.8241 10.7783 30.6815 10.1302 30.7282 9.48168C30.7141 8.70677 30.9642 7.95013 31.4372 7.33616C32.0075 6.68243 32.7514 6.20373 33.5827 5.95556C34.6002 5.56724 35.6843 5.38339 36.773 5.41452C37.6756 5.41717 38.5757 5.51093 39.4595 5.69437C40.2551 5.84088 41.019 6.12499 41.717 6.53392L40.4857 9.18317C39.9107 8.83015 39.2798 8.57777 38.62 8.43691C38.0087 8.29309 37.3822 8.22418 36.7543 8.23168C36.1139 8.18115 35.4713 8.29682 34.8886 8.5675C34.7259 8.64579 34.5876 8.76716 34.489 8.91846C34.3903 9.06975 34.335 9.24519 34.3289 9.42571C34.3208 9.57103 34.3553 9.71558 34.4282 9.84154C34.5011 9.96751 34.6093 10.0694 34.7394 10.1347C35.0861 10.3164 35.4566 10.4483 35.8401 10.5265C36.3066 10.5265 36.8103 10.6944 37.37 10.769C37.9297 10.8436 38.4707 10.9556 39.0304 11.0862C39.5583 11.2262 40.0661 11.4331 40.5416 11.7018C40.9903 11.9605 41.3684 12.3258 41.6424 12.7653C41.9686 13.3303 42.1181 13.9801 42.0715 14.6309C42.0861 15.3945 41.8355 16.1395 41.3625 16.7391C40.8178 17.3997 40.1011 17.8968 39.2916 18.1757C38.2298 18.5366 37.1096 18.6948 35.9894 18.6421Z"
                                    fill="#414042"
                                />
                                <path
                                    d="M44.3642 23.2688C43.6928 23.2637 43.026 23.1567 42.3866 22.9516C41.8016 22.7849 41.2603 22.492 40.8008 22.0934L42.1441 19.3882C42.4404 19.6473 42.7819 19.8497 43.1515 19.9852C43.5004 20.1306 43.8743 20.2067 44.2523 20.2091C44.7003 20.2392 45.1441 20.1067 45.5023 19.836C45.8833 19.5006 46.1777 19.0782 46.3605 18.6046L47.0135 17.0188L47.312 16.627L51.9202 5.54492H55.5023L49.7 19.239C49.3713 20.1234 48.8915 20.944 48.2821 21.6643C47.7987 22.2155 47.1912 22.644 46.5097 22.9143C45.8193 23.1512 45.0941 23.271 44.3642 23.2688ZM46.6403 18.9404L40.8381 5.60089H44.7747L49.215 16.3472L46.6403 18.9404Z"
                                    fill="#414042"
                                />
                                <path
                                    d="M55.6719 18.4367V0.731445H59.4032V8.19413L59.142 11.9255V15.7501V18.362L55.6719 18.4367ZM63.2465 18.6419C62.2282 18.6687 61.2219 18.4171 60.3361 17.9143C59.4923 17.4101 58.837 16.6434 58.4704 15.7314C57.9998 14.5454 57.7776 13.2755 57.8174 12.0001C57.7695 10.7224 57.9986 9.44953 58.489 8.26876C58.8832 7.37379 59.532 6.61463 60.3547 6.08592C61.2205 5.60258 62.1991 5.35792 63.1905 5.37697C64.33 5.35655 65.4552 5.63303 66.4555 6.17921C67.42 6.71439 68.215 7.50944 68.7502 8.47398C69.331 9.54999 69.6204 10.7591 69.5898 11.9814C69.6206 13.1979 69.3311 14.401 68.7502 15.4703C68.215 16.4348 67.42 17.2298 66.4555 17.765C65.4818 18.3351 64.3748 18.6376 63.2465 18.6419ZM62.6122 15.6568C63.1939 15.6634 63.7669 15.5153 64.2726 15.2277C64.7626 14.9237 65.1671 14.5 65.448 13.9964C65.7368 13.4172 65.8838 12.7778 65.8771 12.1307C65.8882 11.4831 65.7409 10.8427 65.448 10.265C65.1723 9.76256 64.7662 9.34365 64.2726 9.05234C63.7702 8.75679 63.1949 8.60813 62.6122 8.62324C62.0237 8.60956 61.4428 8.75801 60.9331 9.05234C60.44 9.27217 60.0148 9.62004 59.7017 10.0598C59.3989 10.6338 59.251 11.2768 59.2726 11.9255C59.2576 12.5734 59.4051 13.2148 59.7017 13.7911C59.9696 14.2981 60.3692 14.7234 60.8584 15.0225C61.3728 15.3926 61.9801 15.6122 62.6122 15.6568Z"
                                    fill="#414042"
                                />
                                <path
                                    d="M76.8838 18.6419C75.5877 18.6743 74.3043 18.3791 73.1525 17.7837C72.1041 17.233 71.2286 16.403 70.6227 15.3856C70.0169 14.3681 69.7043 13.2028 69.7197 12.0188C69.6993 10.8151 70.023 9.63067 70.6525 8.60458C71.2839 7.61556 72.1832 6.82622 73.2458 6.32846C74.3757 5.77628 75.6168 5.48926 76.8745 5.48926C78.1322 5.48926 79.3733 5.77628 80.5032 6.32846C81.5316 6.83913 82.4021 7.61936 83.0219 8.58593C83.6464 9.62104 83.9694 10.81 83.9547 12.0188C83.9893 13.2042 83.6868 14.3752 83.0824 15.3956C82.4781 16.4161 81.5967 17.2441 80.5405 17.7837C79.4114 18.3678 78.155 18.6626 76.8838 18.6419ZM76.8838 15.5822C77.4711 15.586 78.0495 15.4382 78.5629 15.1531C79.0522 14.854 79.4517 14.4287 79.7197 13.9217C80.0162 13.3455 80.1638 12.704 80.1488 12.0561C80.1704 11.4075 80.0225 10.7644 79.7197 10.1904C79.4561 9.68522 79.0551 9.26483 78.5629 8.97772C78.1954 8.74698 77.7855 8.59183 77.3573 8.52134C76.9291 8.45086 76.4911 8.46644 76.069 8.5672C75.6469 8.66795 75.2491 8.85184 74.8989 9.10813C74.5487 9.36441 74.2531 9.68794 74.0294 10.0598C73.72 10.632 73.5657 11.2752 73.5816 11.9255C73.5723 12.575 73.7263 13.2165 74.0294 13.7912C74.2723 14.3459 74.6717 14.8178 75.1787 15.1492C75.6857 15.4805 76.2782 15.6569 76.8838 15.6568V15.5822Z"
                                    fill="#414042"
                                />
                                <path
                                    d="M88.7863 18.6419C87.9004 18.6568 87.0218 18.4786 86.2117 18.1195C85.5386 17.8249 84.9675 17.3382 84.5699 16.7203C84.1764 16.1351 83.9624 15.4478 83.9542 14.7427C83.9365 14.0362 84.1315 13.3407 84.5139 12.7464C84.9761 12.1065 85.6293 11.6297 86.3796 11.3845C87.3981 11.0295 88.4732 10.8651 89.5512 10.8994H92.9654V13.0636H89.9244C89.2716 12.9876 88.6127 13.1391 88.0587 13.4927C87.9055 13.6281 87.783 13.7947 87.6993 13.9813C87.6157 14.1679 87.5728 14.3703 87.5736 14.5748C87.566 14.7959 87.6131 15.0155 87.7107 15.214C87.8084 15.4125 87.9535 15.5839 88.1333 15.7128C88.5848 16.0131 89.1221 16.1573 89.6632 16.1233C90.2795 16.1317 90.8862 15.9703 91.4169 15.6568C91.9128 15.3588 92.289 14.8969 92.4803 14.3509L93.0587 16.0673C92.8145 16.8498 92.2895 17.5143 91.5848 17.933C90.7341 18.4202 89.7664 18.6653 88.7863 18.6419ZM92.7042 18.4367V15.9367L92.4617 15.3957V10.8994C92.4815 10.5544 92.4267 10.2092 92.3011 9.88728C92.1756 9.56535 91.9822 9.27423 91.7341 9.03371C91.116 8.54492 90.3372 8.30531 89.5512 8.36207C88.8656 8.36138 88.1847 8.47487 87.5363 8.69789C86.9279 8.87655 86.3584 9.16764 85.8572 9.5561L84.5139 6.96282C85.2813 6.44061 86.1335 6.05555 87.0326 5.82476C88.0162 5.54882 89.0334 5.41069 90.055 5.41431C91.6886 5.31567 93.3036 5.80544 94.6072 6.79491C95.1828 7.35011 95.6272 8.0268 95.9081 8.77561C96.1889 9.52443 96.299 10.3265 96.2304 11.1233V18.4367H92.7042Z"
                                    fill="#414042"
                                />
                                <path
                                    d="M98.207 18.4366V5.60081H101.752V9.22021L101.248 8.1941C101.621 7.3192 102.278 6.59597 103.114 6.14186C104.059 5.6377 105.121 5.39308 106.192 5.4329V8.84708H105.763H105.353C104.449 8.80915 103.566 9.12354 102.89 9.72395C102.55 10.0824 102.289 10.5087 102.125 10.9749C101.961 11.4411 101.897 11.9367 101.938 12.4292V18.4366H98.207Z"
                                    fill="#414042"
                                />
                                <path
                                    d="M112.424 18.6419C111.28 18.6589 110.153 18.369 109.159 17.8023C108.193 17.2628 107.393 16.4692 106.846 15.5076C106.265 14.4383 105.975 13.2352 106.006 12.0188C105.976 10.7964 106.265 9.5873 106.846 8.5113C107.393 7.54961 108.193 6.75603 109.159 6.21652C110.159 5.67034 111.285 5.39387 112.424 5.41428C113.415 5.39523 114.394 5.63989 115.26 6.12324C116.098 6.63457 116.751 7.39901 117.126 8.30607C117.616 9.48684 117.845 10.7597 117.797 12.0374C117.843 13.3115 117.627 14.5814 117.163 15.7688C116.796 16.6807 116.141 17.4475 115.297 17.9516C114.419 18.4349 113.426 18.6733 112.424 18.6419ZM113.077 15.6568C113.652 15.6584 114.218 15.5105 114.719 15.2277C115.209 14.9237 115.613 14.5 115.894 13.9964C116.197 13.4218 116.351 12.7803 116.342 12.1307C116.358 11.4804 116.204 10.8373 115.894 10.265C115.618 9.76256 115.212 9.34365 114.719 9.05234C114.221 8.76106 113.653 8.61259 113.077 8.62324C112.488 8.60761 111.907 8.7562 111.398 9.05234C110.9 9.3383 110.493 9.75854 110.223 10.265C109.913 10.8373 109.759 11.4804 109.775 12.1307C109.766 12.7803 109.919 13.4218 110.223 13.9964C110.498 14.5041 110.904 14.9291 111.398 15.2277C111.91 15.5152 112.489 15.6632 113.077 15.6568ZM116.435 18.5113V15.8247V12.0934L116.193 8.36204V0.731445H119.924V18.4367L116.435 18.5113Z"
                                    fill="#414042"
                                />
                            </svg>
                            <p>База знаний</p>
                        </Link>
                        <nav className={styles.nav}>
                            <NavLink to={'/faq/categories'}>Категории</NavLink>
                            <NavLink to={'/faq/videos'}>Видео</NavLink>
                            <NavLink to={'/faq/webinars'}>Вебинары</NavLink>
                            <NavLink to={'/faq/req'}>Обратная связь</NavLink>
                            <Link to={'/'} rel="noreferrer">
                                Перейти в сервис
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                >
                                    <path
                                        d="M17.5 7.5V2.5M17.5 2.5H12.5M17.5 2.5L10.8333 9.16667M8.33333 4.16667H6.5C5.09987 4.16667 4.3998 4.16667 3.86503 4.43915C3.39462 4.67883 3.01217 5.06128 2.77248 5.53169C2.5 6.06647 2.5 6.76653 2.5 8.16667V13.5C2.5 14.9002 2.5 15.6002 2.77248 16.135C3.01217 16.6054 3.39462 16.9878 3.86503 17.2275C4.3998 17.5 5.09987 17.5 6.5 17.5H11.8333C13.2335 17.5 13.9335 17.5 14.4683 17.2275C14.9387 16.9878 15.3212 16.6054 15.5608 16.135C15.8333 15.6002 15.8333 14.9002 15.8333 13.5V11.6667"
                                        stroke="#35373A"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </Link>
                        </nav>
                    </div>
                ) : (
                    <div
                        className={cx(
                            style['flex'],
                            style['jc-sb'],
                            style['ai-c'],
                            style['gap-20']
                        )}
                    >
                        <div
                            className={cx(
                                style['header-nav__item'],
                                style['header-nav__item--logo']
                            )}
                        >
                            <NavLink
                                to="/"
                                className={({ isActive }) => (isActive ? style.active : '')}
                            >
                                <img src={logo} alt="" />
                            </NavLink>
                        </div>

                        {userStore.isLogged && (
                            <>
                                <div className={cx(style['header-nav__item'])} ref={menuRef}>
                                    <span className={reportIsActive ? style['activeItem'] : ''}>
                                        Финансы
                                    </span>

                                    <div className={style['sub-menu']}>

                                        <div className={style['sub-menu__item']}>

                                            <div className={`${style['sub-menu__item_title']}`}>
                                                Закупки
                                            </div>

                                            <NavLink
                                                to="/purchase/orders/"
                                                className={({ isActive }) =>
                                                    isActive ? style.active : ''
                                                }
                                            >
                                                Заказы
                                            </NavLink>

                                            <NavLink
                                                to="/purchase/acceptance/"
                                                className={({ isActive }) =>
                                                    isActive ? style.active : ''
                                                }
                                            >
                                                Приемки
                                            </NavLink>

                                            <NavLink
                                                to="/purchase/shipment/"
                                                className={({ isActive }) =>
                                                    isActive ? style.active : ''
                                                }
                                            >
                                                Отгрузки
                                            </NavLink>

                                        </div>

                                        <div className={style['sub-menu__item']}>
                                            <div className={`${style['sub-menu__item_title']}`}>
                                                Отчеты
                                            </div>

                                            <NavLink
                                                to="/reports/"
                                                className={({ isActive }) =>
                                                    isActive ? style.active : ''
                                                }
                                            >
                                                ОПиУ (P&L)
                                            </NavLink>

                                            <NavLink
                                                to="/financing/dds/"
                                                className={({ isActive }) =>
                                                    isActive ? style['active'] : ''
                                                }
                                            >
                                                ДДС (Cashflow)
                                            </NavLink>
                                            <NavLink
                                                to="/financing/planfact"
                                                className={({ isActive }) =>
                                                    isActive ? style.active : ''
                                                }
                                            >
                                                План/Факт
                                            </NavLink>
                                            <NavLink
                                                to="/reports/balance"
                                                className={({ isActive }) =>
                                                    isActive ? style.active : ''
                                                }
                                            >
                                                Баланс
                                            </NavLink>
                                            <NavLink
                                                to="/reports/advertisement/"
                                                className={({ isActive }) =>
                                                    isActive ? style.active : ''
                                                }
                                            >
                                                Реклама
                                            </NavLink> 
                                        </div>
                                        <div className={style['sub-menu__item']}>
                                            <div className={style['sub-menu__item_title']}>
                                                Деньги
                                            </div>
                                            <NavLink
                                                to="/financing/banks/"
                                                className={({ isActive }) =>
                                                    isActive ? style['active'] : ''
                                                }
                                            >
                                                Банки
                                            </NavLink>

                                            <NavLink
                                                to="/financing/rules/"
                                                className={({ isActive }) =>
                                                    isActive ? style['active'] : ''
                                                }
                                            >
                                                Правила
                                            </NavLink>

                                            <NavLink
                                                to={
                                                    !location.pathname.includes('/financing/cashbox/')
                                                        ? '/financing/cashbox'
                                                        : '/financing/cashbox'
                                                }
                                                className={({ isActive }) =>
                                                    isActive ? style['active'] : ''
                                                }
                                            >
                                                Кассы
                                            </NavLink>

                                            <NavLink
                                                to="/staff"
                                                className={({ isActive }) =>
                                                    isActive ? style.active : ''
                                                }
                                            >
                                                ФОТ
                                            </NavLink>

                                            <NavLink
                                                to="/financing/loans/"
                                                className={({ isActive }) =>
                                                    isActive ? style['active'] : ''
                                                }
                                            >
                                                Займы
                                            </NavLink>

                                        </div>
                                    </div>
                                </div>

                                {/*<div className={cx(style['header-nav__item'])} ref={menuRef}>*/}
                                {/*    <span className={purchaseIsActive ? style['activeItem'] : ''}>*/}
                                {/*        Закупки*/}
                                {/*    </span>*/}
                                {/*    <div className={style['sub-menu']}>*/}
                                {/*        <div className={style['sub-menu__item']}>*/}
                                {/*        <NavLink*/}
                                {/*            to="/purchase/orders/"*/}
                                {/*            className={({ isActive }) =>*/}
                                {/*                isActive ? style.active : ''*/}
                                {/*            }*/}
                                {/*        >*/}
                                {/*            Заказы поставщикам*/}
                                {/*        </NavLink>*/}
                                {/*        <NavLink*/}
                                {/*            to="/purchase/instock/"*/}
                                {/*            className={({ isActive }) =>*/}
                                {/*                isActive ? style.active : ''*/}
                                {/*            }*/}
                                {/*        >*/}
                                {/*            Приемки*/}
                                {/*        </NavLink>*/}
                                {/*        <NavLink*/}
                                {/*            to="/purchase/outstock/"*/}
                                {/*            className={({ isActive }) =>*/}
                                {/*                isActive ? style.active : ''*/}
                                {/*            }*/}
                                {/*        >*/}
                                {/*            Отгрузки*/}
                                {/*        </NavLink>*/}

                                {/*        </div>*/}
                                {/*    </div>*/}

                                {/*</div>*/}

                                <div className={cx(style['header-nav__item'])} ref={menuRef}>
                                    <span className={analyticIsActive ? style['activeItem'] : ''}>
                                        Аналитика
                                    </span>

                                    <div className={style['sub-menu']}>
                                        <div className={style['sub-menu__item']}>
                                            <NavLink
                                                to="/analytics/projects/"
                                                className={({ isActive }) =>
                                                    isActive ? style.active : ''
                                                }
                                            >
                                                Проекты
                                            </NavLink>

                                            <NavLink
                                                to="/reports/advertisement/"
                                                className={({ isActive }) =>
                                                    isActive ? style.active : ''
                                                }
                                            >
                                                Реклама
                                            </NavLink>
                                            <NavLink
                                                to="/economy/sell"
                                                className={({ isActive }) =>
                                                    location.pathname.includes('economy')
                                                        ? style.active
                                                        : ''
                                                }
                                            >
                                                Unit-экономика
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>

                                <div className={cx(style['header-nav__item'])} ref={menuRef}>
                                    <span className={shedulerIsActive ? style['activeItem'] : ''}>
                                        Автоматизация
                                    </span>

                                    <div className={style['sub-menu']}>
                                        <div className={style['sub-menu__item']}>
                                            <NavLink
                                                to="/scheduler/logistics"
                                                className={({ isActive }) =>
                                                    isActive ? style.active : ''
                                                }
                                            >
                                                Планировщик поставок
                                            </NavLink>

                                            <NavLink
                                                to="/scheduler/answering"
                                                className={({ isActive }) =>
                                                    isActive ? style.active : ''
                                                }
                                            >
                                                Автоответчик на отзывы
                                            </NavLink>
                                        </div>
                                    </div>
                                </div> 
                            </>
                        )}
                    </div>
                )}

                {userStore.isLogged && (
                    <div className={cx(style['header-nav__item'], style.relative)}>
                        <Link
                            to="/faq"
                            style={{
                                marginLeft: 'auto',
                                position: 'absolute',
                                left: '-210px',
                                top: '17px',
                            }}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="16"
                                viewBox="0 0 20 16"
                                fill="none"
                            >
                                <path
                                    d="M3.65505 11.4616H8.65505C8.96274 11.4616 9.23197 11.1924 9.23197 10.8847V1.84623C9.23197 1.19238 8.53966 0.692383 8.0012 0.692383H3.65505C3.34736 0.692383 3.07812 0.961614 3.07812 1.26931V10.3078V10.8847C3.07812 11.1924 3.34736 11.4616 3.65505 11.4616Z"
                                    fill="#8496AF"
                                />
                                <path
                                    d="M18.9231 2.46143C18.6923 2.3845 18.4615 2.57681 18.4615 2.84604V12.423C18.4615 12.7307 18.1923 12.9999 17.8846 12.9999H2.11538C1.80769 12.9999 1.53846 12.7307 1.53846 12.423V2.8845C1.53846 2.61527 1.23077 2.42297 1 2.53835C0.423077 2.80758 0 3.42297 0 4.15374V12.9999C0 13.846 0.692308 14.5384 1.53846 14.5384H7.88462C8.19231 14.5384 8.46154 14.8076 8.46154 15.1153C8.46154 15.423 8.73077 15.6922 9.03846 15.6922H10.9615C11.2692 15.6922 11.5385 15.423 11.5385 15.1153C11.5385 14.8076 11.8077 14.5384 12.1154 14.5384H18.4615C19.3077 14.5384 20 13.846 20 12.9999V4.15374C20 3.34604 19.7308 2.65374 18.9231 2.46143Z"
                                    fill="#8496AF"
                                />
                                <path
                                    d="M11.3465 11.4616H16.3465C16.6541 11.4616 16.9234 11.1924 16.9234 10.8847V10.3078V1.26931C16.9234 0.961614 16.6541 0.692383 16.3465 0.692383H12.0003C11.4234 0.692383 10.7695 1.19238 10.7695 1.84623V10.8847C10.7695 11.1924 11.0388 11.4616 11.3465 11.4616Z"
                                    fill="#8496AF"
                                />
                            </svg>
                        </Link>
                        {location.pathname.includes('/faq') &&
                        location.pathname.length > 5 && (
                            <div
                                onClick={() => setFaqSearch(true)}
                                className={style.inputWrap}
                            >
                                <svg
                                    id={'noClose'}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="17"
                                    viewBox="0 0 16 17"
                                    fill="none"
                                >
                                    <path
                                        id={'noClose'}
                                        d="M14.9595 16.256L14.9604 16.256C15.0525 16.2563 15.1436 16.2375 15.2282 16.2008C15.3124 16.1643 15.3883 16.1109 15.4512 16.0439C15.5783 15.9114 15.6491 15.7344 15.6491 15.5505C15.6491 15.3663 15.578 15.189 15.4505 15.0564L15.4505 15.0564L15.4491 15.055L11.1147 10.6652C12.1118 9.50932 12.6473 8.01763 12.6135 6.48165C12.5786 4.89817 11.9413 3.38886 10.8335 2.26759C9.72568 1.14626 8.23258 0.499271 6.66447 0.461605C5.09637 0.423938 3.5745 0.998509 2.41517 2.06542C1.25589 3.13229 0.548326 4.60934 0.439056 6.18937C0.329786 7.7694 0.827171 9.33143 1.82821 10.5508C2.82929 11.7702 4.25714 12.5531 5.81516 12.7362C7.32956 12.9143 8.85316 12.5123 10.0866 11.6128L14.4719 16.0454C14.5351 16.111 14.6106 16.1635 14.694 16.1996L14.7537 16.062L14.694 16.1996C14.7779 16.236 14.8681 16.2551 14.9595 16.256ZM1.71228 6.5394C1.7123 5.58279 1.993 4.64779 2.51869 3.85264C3.04439 3.05748 3.79143 2.43796 4.66515 2.07219C5.53885 1.70643 6.50015 1.61076 7.42756 1.7972C8.35498 1.98364 9.20709 2.44389 9.87604 3.11998C10.545 3.79609 11.0008 4.6577 11.1854 5.59594C11.3701 6.53419 11.2753 7.50669 10.9131 8.3904C10.5509 9.2741 9.93773 10.0291 9.15126 10.5603C8.36485 11.0913 7.44046 11.3747 6.49497 11.3747C5.22739 11.3736 4.01173 10.8641 3.11492 9.95777C2.21806 9.05134 1.71342 7.82196 1.71228 6.5394Z"
                                        fill="#8496AF"
                                        stroke="#8496AF"
                                        strokeWidth="0.3"
                                    />
                                </svg>
                                Поиск
                            </div>
                        )}
                        <div
                            className={cx(style.widgetLK, isHovered ? style.hoverActive : '')}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                        >
                            <div className={style.widgetLKPre}>
                                <div className={style.widgetLKUserInfo}>
                                    <div>
                                        {userData.last_name}{' '}
                                        {userData.first_name &&
                                            `${userData.first_name?.substring(0, 1)}.`}{' '}
                                        {userData.middle_name &&
                                            `${userData.middle_name?.substring(0, 1)}.`}
                                    </div>
                                    {/*{userData.email && <div>{userData.email}</div>}*/}
                                </div>
                                <svg
                                    width="46"
                                    height="46"
                                    viewBox="0 0 46 46"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <rect width="46" height="46" rx="23" fill="#1A936F" />
                                    <path
                                        d="M31.59 33C31.59 29.13 27.74 26 23 26C18.26 26 14.41 29.13 14.41 33M23 23C24.3261 23 25.5979 22.4732 26.5355 21.5355C27.4732 20.5979 28 19.3261 28 18C28 16.6739 27.4732 15.4021 26.5355 14.4645C25.5979 13.5268 24.3261 13 23 13C21.6739 13 20.4022 13.5268 19.4645 14.4645C18.5268 15.4021 18 16.6739 18 18C18 19.3261 18.5268 20.5979 19.4645 21.5355C20.4022 22.4732 21.6739 23 23 23Z"
                                        stroke="white"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </div>

                            <div className={style.hoverComponent}>
                                {isHovered ? (
                                    <div>
                                        <div className={style.widgetLKItem}>
                                            <svg
                                                width="18"
                                                height="19"
                                                viewBox="0 0 18 19"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M15.6706 7.90595L14.5946 7.67203C14.501 7.38556 14.3851 7.10614 14.2483 6.83698L14.844 5.91039C14.9504 5.74483 14.927 5.52747 14.7879 5.3884L13.1116 3.7121C12.9725 3.57303 12.7552 3.54964 12.5896 3.65602L11.663 4.25171C11.3939 4.11488 11.1144 3.99899 10.828 3.90543L10.5941 2.82941C10.5523 2.63715 10.382 2.5 10.1853 2.5H7.81471C7.61797 2.5 7.44771 2.63715 7.40595 2.82941L7.17203 3.90543C6.88556 3.99899 6.60614 4.11488 6.33698 4.25171L5.41039 3.65602C5.24483 3.54964 5.02747 3.57303 4.8884 3.7121L3.2121 5.3884C3.07303 5.52747 3.04964 5.74483 3.15602 5.91039L3.75171 6.83698C3.61488 7.10614 3.49899 7.38556 3.40543 7.67203L2.32941 7.90595C2.13715 7.94782 2 8.11797 2 8.31471V10.6853C2 10.882 2.13715 11.0522 2.32941 11.0941L3.40543 11.328C3.49899 11.6144 3.61488 11.8939 3.75171 12.163L3.15602 13.0896C3.04964 13.2552 3.07303 13.4725 3.2121 13.6116L4.8884 15.2879C5.02747 15.427 5.24483 15.4504 5.41039 15.344L6.33698 14.7483C6.60614 14.8851 6.88556 15.001 7.17203 15.0946L7.40595 16.1706C7.44771 16.3629 7.61797 16.5 7.81471 16.5H10.1853C10.382 16.5 10.5523 16.3629 10.5941 16.1706L10.828 15.0946C11.1144 15.001 11.3939 14.8851 11.663 14.7483L12.5896 15.344C12.7552 15.4504 12.9725 15.4271 13.1116 15.2879L14.7879 13.6116C14.927 13.4725 14.9504 13.2552 14.844 13.0896L14.2483 12.163C14.3851 11.8939 14.501 11.6144 14.5946 11.328L15.6706 11.0941C15.8629 11.0522 16 10.882 16 10.6853V8.31471C16 8.11797 15.8629 7.94782 15.6706 7.90595V7.90595ZM11.51 9.5C11.51 10.884 10.384 12.01 9 12.01C7.61604 12.01 6.49004 10.884 6.49004 9.5C6.49004 8.11604 7.61604 6.99004 9 6.99004C10.384 6.99004 11.51 8.11604 11.51 9.5V9.5Z"
                                                    stroke="#1E1E1E"
                                                />
                                            </svg>
                                            <NavLink to="/settings/account/"> Настройки </NavLink>
                                        </div>
                                        <a
                                            href="#"
                                            className={style.widgetLKItem}
                                            onClick={handleOut}
                                        >
                                            <svg
                                                width="18"
                                                height="19"
                                                viewBox="0 0 18 19"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M3.93438 15.1022H8.98757C9.29806 15.1022 9.54902 15.3532 9.54902 15.6637C9.54902 15.9742 9.29806 16.2251 8.98757 16.2251H3.93438C3.00572 16.2251 2.25 15.4694 2.25 14.5407V4.43441C2.25 3.50575 3.00572 2.75 3.93438 2.75H8.98757C9.29806 2.75 9.54902 3.00102 9.54902 3.3115C9.54902 3.62199 9.29806 3.87296 8.98757 3.87296H3.93438C3.62448 3.87296 3.37293 4.12448 3.37293 4.43441V14.5408C3.37293 14.8507 3.62445 15.1022 3.93438 15.1022ZM12.189 5.71897L15.6027 9.08775C15.71 9.19388 15.77 9.33706 15.77 9.48747C15.77 9.63793 15.7094 9.78169 15.6027 9.88723L12.189 13.256C12.0801 13.3638 11.9375 13.4177 11.7949 13.4177C11.65 13.4177 11.5052 13.3616 11.3951 13.2504C11.1779 13.0298 11.1795 12.6744 11.4007 12.4565L13.8403 10.049H7.30317C6.99269 10.049 6.74172 9.79798 6.74172 9.48749C6.74172 9.17701 6.99269 8.92604 7.30317 8.92604H13.8403L11.4007 6.5185C11.1795 6.30065 11.1773 5.94524 11.3951 5.72459C11.613 5.50394 11.9689 5.50112 12.189 5.71897Z"
                                                    fill="#1E1E1E"
                                                />
                                            </svg>
                                            Выйти
                                        </a>
                                    </div>
                                ) : (
                                    <div></div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </header>
    );
});

export default Header;
