import React from 'react'

const BorderedPlusIcon = _ => {

    return (
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.25" y="0.25" width="23.5" height="23.5" rx="3.75" fill="white" />
            <rect x="0.25" y="0.25" width="23.5" height="23.5" rx="3.75" stroke="#DCE7F1" strokeWidth="0.5" />
            <path d="M5.96061 12.7106H11.2894V18.0394C11.2894 18.2279 11.3643 18.4086 11.4975 18.5419C11.6308 18.6751 11.8115 18.75 12 18.75C12.1885 18.75 12.3692 18.6751 12.5025 18.5419C12.6357 18.4086 12.7106 18.2279 12.7106 18.0394V12.7106H18.0394C18.2279 12.7106 18.4086 12.6357 18.5419 12.5025C18.6751 12.3692 18.75 12.1885 18.75 12C18.75 11.8115 18.6751 11.6308 18.5419 11.4975C18.4086 11.3643 18.2279 11.2894 18.0394 11.2894H12.7106V5.96061C12.7106 5.77214 12.6357 5.5914 12.5025 5.45813C12.3692 5.32487 12.1885 5.25 12 5.25C11.8115 5.25 11.6308 5.32487 11.4975 5.45813C11.3643 5.5914 11.2894 5.77214 11.2894 5.96061V11.2894H5.96061C5.77215 11.2894 5.5914 11.3643 5.45813 11.4975C5.32487 11.6308 5.25 11.8115 5.25 12C5.25 12.1885 5.32487 12.3692 5.45813 12.5025C5.5914 12.6357 5.77215 12.7106 5.96061 12.7106Z" fill="#35373A" />
        </svg>
    )

}

export default BorderedPlusIcon


