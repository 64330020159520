import React, { useRef, useState, useEffect } from "react"
import cx from 'classnames'
import styles from './styles.modules.scss'

import useSyncScroll from "../../../static/js/useSyncScroll.jsx"

const ScrollBar = ({ targetRef, horizontal = false, params }) => {

    const elementRef = useRef(null);
    const { scrollSize } = useSyncScroll(targetRef, elementRef, { horizontal });

    const style = params.modal ? { height: params.height } : {
        marginLeft: params.left ? `${params.left}px` : 'auto',
        marginRight: params.right ? `${params.right}px` : undefined,
        top: params.top ? `${params.top}px` : 'auto',
        height: params.height ? `${params.height}px` : undefined
    };    

    return (
        <div
            className={cx(styles.scrollbarContainer, horizontal ? styles.horizontal : styles.vertical, params.modal && styles.modal)}
            ref={elementRef}
            style={style}
        >
            <div
                style={{
                    width: horizontal ? `${scrollSize}px` : null,
                    height: horizontal ? 'null' : `${scrollSize}px`
                }}
                className={styles.scrollbarContent}>

            </div>
        </div>
    )

}

export default ScrollBar