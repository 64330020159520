const token = localStorage.getItem('token');

const myHeaders = new Headers();
if (token) {
    myHeaders.append('Authorization', `Token ${token}`);
}
myHeaders.append('Content-Type', 'application/json');

// export const API_URL = 'https://busyboard.pro';
// export const API_URL = 'http://127.0.0.1:8000';
// export const API_URL = 'http://77.222.46.255:8000';
export const API_URL = 'https://busyboard-test.ru';
// export const API_URL = 'http://77.222.46.255:8000';

export const getData = (url = '', data) => {
    return fetch(url, {
        method: 'GET',
        mode: 'cors',
        headers: myHeaders,
        data: JSON.stringify(data),
    })
        .then((response) => response.json())
        .catch((error) => {
            console.log(error);
            if(window.location.href !== window.location.origin + '/500') {
                // window.location = window.location.origin + '/500'
            }
        });
};

export const asyncGetData = async (url = '') => {
    try {
        const response = await fetch(url, {
            method: 'GET',
            mode: 'cors',
            headers: myHeaders,
        });

        if (!response.ok) {
            throw new Error(`Ошибка HTTP: ${response.status} - ${response.statusText}`);
        }

        return response;
    } catch (error) {
        console.error('Ошибка при выполнении запроса:', error);
        if (window.location.href !== `${window.location.origin}/500`) {
            // window.location = `${window.location.origin}/500`;
        }
        throw error;
    }
};

export const postData = (url = '', data = {}) => {
    return fetch(url, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(data),
    }).catch((error) => console.log(error));
};

export const postDataLogin = (url = '', data = {}) => {
    const loginHeader = new Headers();
    loginHeader.append('Content-Type', 'application/json');

    return fetch(url, {
        method: 'POST',
        headers: loginHeader,
        body: JSON.stringify(data),
    }).catch((error) => console.log(error));
};

export const postDataFile = (url = '', data = {}) => {
    const myHeadersFile = new Headers();
    if (token) {
        myHeadersFile.append('Authorization', `Token ${token}`);
    }
    return fetch(url, {
        method: 'POST',
        headers: myHeadersFile,
        body: data,
    }).catch((error) => console.log(error));
};

export const putDataNew = (url = '', data = {}) => {
    console.log(data)
    return fetch(url, {
        method: 'PUT',
        headers: myHeaders,
        body: JSON.stringify(data),
    }).catch((error) => console.log(error));
};

export const putData = (url = '', data = {}, files = false) => {
    if (files) {
        const myHeadersFile = new Headers();

        if (token) {
            myHeadersFile.append('Authorization', `Token ${token}`);
        }

        return fetch(url, {
            method: 'PUT',
            headers: myHeadersFile,
            body: data,
        }).catch((error) => console.log(error));
    } else {
        console.log({ ...data });
        return fetch(url, {
            method: 'PUT',
            headers: myHeaders,
            body: data,
        }).catch((error) => console.log(error));
    }
};

export const delData = (url = '', data = {}) => {
    return fetch(url, {
        method: 'DELETE',
        headers: myHeaders,
        body: JSON.stringify(data),
    }).catch((error) => console.log(error));
};

export const delCashbox = (url = '') => {
    return fetch(url, {
        method: 'DELETE',
        headers: myHeaders,
    }).catch((error) => console.log(error));
};
