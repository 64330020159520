import React, { useRef, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import IconCalendar from '../../shared/ui/icons/IconCalendar.jsx';
import CloseIcon from '../../shared/ui/icons/Close.jsx';
import RigthArrow from '../../shared/ui/icons/RigthArrow.jsx';
import dayjs from 'dayjs';
import { ru } from 'date-fns/locale';
import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const DateSelection = ({ selectedDate, setSelectedDate }) => {

    const elementRef = useRef();

    const today = new Date();
    const [currentDate, setDate] = useState(new Date())
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [isOpen, toggleOpen] = useState(false)    

    const resetDate = () => {
        setDate(null)
    }

    const apply = () => {
        setSelectedDate(currentDate);
    }

    const handleYearChange = (offset) => {
        const newYear = currentYear + offset;
        const date = currentDate ? currentDate : new Date()
        setCurrentYear(newYear);        
        setDate(new Date(newYear, date.getMonth(), date.getDate()));
    };

    const minDate = new Date(currentYear, today.getMonth(), today.getDate() - 183);
    const maxDate = new Date(currentYear, today.getMonth(), today.getDate() + 183);

    useEffect(() => {

        const handleOutsideClick = (e) => {
            if (elementRef.current && !elementRef.current.contains(e.target)) {
                toggleOpen(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    return (
        <div className={styles.calendar}>
            <div className={styles.value}>
                <button onClick={() => toggleOpen(true)} className={styles.calendarBtn}>
                    <IconCalendar />
                    <span>
                        {selectedDate
                            ? `${dayjs(selectedDate).format('DD.MM.YYYY')}`
                            : 'Выбрать'}
                    </span>
                </button>
            </div>

            {isOpen && (
                <div className={styles.dateSelect} ref={elementRef}>

                    <div className={styles.title}>
                        <div><b>Календарь</b></div>
                        <div><button onClick={resetDate}>Сбросить</button></div>
                    </div>

                    <div className={styles.dateValue}>
                        <div className={styles.label}>Дата</div>
                        <div className={styles.value}>
                            <input
                                disabled
                                type="text"
                                value={currentDate ? dayjs(currentDate).format('DD.MM.YYYY') : (selectedDate ? dayjs(selectedDate).format('DD.MM.YYYY') : 'Не выбрана')}
                            />
                            {selectedDate && <div className={styles.closeIcon} onClick={resetDate}><CloseIcon /></div>}
                        </div>
                    </div>

                    <div className={styles.yearSelect}>
                        <button onClick={() => handleYearChange(-1)}>
                            <RigthArrow isLeft={true} />
                        </button>
                        <span>{currentYear}</span>
                        <button onClick={() => handleYearChange(1)}>
                            <RigthArrow />
                        </button>
                    </div>

                    <Calendar
                        date={currentDate ? currentDate : (selectedDate ? new Date(selectedDate) : new Date())}
                        minDate={minDate}
                        maxDate={maxDate}
                        onChange={(newDate) => setDate(newDate)}
                        direction='vertical'
                        scroll={{
                            enabled: true,                             
                            monthHeight: 330
                        }}
                        locale={ru}
                        className={styles.selection}
                        fixedHeight={true}
                        weekdayDisplayFormat='EEEEEE'
                        monthDisplayFormat='LLLL yyyy'
                        showMonthAndYearPickers={false}
                        showMonthArrow={false}
                    />
                    <div className={styles.button}>
                        <button className={styles.saveChanges} onClick={() => {
                            apply();
                            toggleOpen(false);
                        }}>Сохранить
                        </button>

                    </div>

                </div>
            )}

        </div>

    );
};

export default DateSelection;