import React from "react";
import style from '../../../../../Purchase.module.scss'
import CloseIcon from "../../../../../../../shared/ui/icons/Close.jsx";
import { formatNumber2 } from "../../../../../../../static/js/commonFunc";
CloseIcon

const FilterSum = ({ value, action }) => {

    const handleChange = (value) => {
        if (/^\d*$/.test(value)) {
            action('amount', [value]);
        }
    };

    return (
        <div className={style.sum}>
            <div className={style.label}>Сумма от</div>
            <div className={style.value}><input type="text" value={value} onChange={(e) => handleChange(e.target.value)} /></div>
            {value && <div className={style.closeIcon} onClick={() => action('amount',null)}><CloseIcon /></div>}
        </div>
    )

}

export default FilterSum