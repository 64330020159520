import React, { useRef, useState, useEffect } from 'react';
import TableHead from './head.jsx';
import TableRow from './row.jsx';
import style from '../../../Purchase.module.scss'
import ScrollBar from '../../../../../components/custom/ScrollBar/index.jsx';

const ListTable = ({ data, columns, filterRef, setSortingBy, itemLink, removeItem }) => {

    const headRef = useRef(null);
    const bodyRef = useRef(null);

    const [yScrollHeigth, setYScrollHeigth] = useState(null)
    const [scrollOffset, setScrollOffset] = useState(null)
    const [scrollHeigth, setScrollHeigth] = useState(null)

    useEffect(() => {

        const resizeObserver = new ResizeObserver(() => {
            const height = filterRef.current.offsetHeight;
            setYScrollHeigth(778 - height)
            setScrollOffset(152 + height)
            setScrollHeigth(793 - height)
        });

        if (filterRef.current) {
            resizeObserver.observe(filterRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };

    }, [filterRef]);

    return (

        <section className={style.section} style={{ height: yScrollHeigth }}>

            <TableHead columns={columns} headRef={headRef} setSortingBy={setSortingBy} />

            <div className={style.body} >

                <div ref={bodyRef} className={style.yScroll} >

                    <TableRow data={data} columns={columns} headRef={headRef} itemLink={itemLink} removeItem={removeItem} />

                </div>

            </div>

            <ScrollBar targetRef={headRef} horizontal={true} params={{ left: 1 }} />

            <ScrollBar targetRef={bodyRef} params={{ height: scrollHeigth, top: scrollOffset }} />

        </section>


    )

}

export default ListTable