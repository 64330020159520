import React, { useState } from 'react';
import cx from 'classnames';
import { observer } from 'mobx-react-lite';
import loansStyle from './loansStyle.module.scss';
import { API_URL, getData } from '../../../static/js/request';
import {
    formatNumber,
} from '../../../static/js/commonFunc';
import { confirmAlert } from 'react-confirm-alert';
import CustomConfirmAlertLoans from './CustomConfirmAlertLoans.jsx';
import { LoansStore } from '../../../store/Financing/LoansStore';
import LoanAction from './loanAction.jsx';
// import loans from './Loans';

const LoansSummary = observer(({showModal, setModalData, remove}) => {
    const [state] = useState(() => new LoansStore());
    const [activeIndex, setActiveIndex] = useState(null);
    const [activeDetailIndexList, setActiveDetailIndexList] = useState([]);
    const [activeLoanIndexList, setActiveLoanIndexList] = useState([]);

    const getInfo = (id, index) => {
        setActiveIndex(activeIndex === index ? null : index);
        getData(API_URL + `/api/v1/loan/organisations/${id}/`).then((data) => {
            state.summaryView[index].elem = data;
        });
    };

    const toggleItemList = (item, list, setList) => {
        if (list.includes(item)) {
            setList(list.filter(el => el !== item));
        } else {
            setList([...list, item]);
        }
    };

    const showDetail = (id) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <React.Fragment>
                        <CustomConfirmAlertLoans id={id} API_URL={API_URL} />
                    </React.Fragment>
                );
            },
        });
    };

    return (
        <div className={cx(loansStyle.section, loansStyle.table)}>
            <div className={loansStyle.BodyTable}>
                {state.summaryView?.map((field, index) => (
                    <div
                        key={index}
                        className={cx(
                            loansStyle['loans-line'],
                            activeIndex === index && loansStyle.active,
                        )}
                    >
                        <div className={cx(loansStyle.BodyTableLine)}>
                            <div className={cx(loansStyle.LoansTableLineItemName)}>
                                {field.name}
                            </div>
                            <div className={cx(loansStyle.LoansTableLineItemProgress)}>
                                <div className={loansStyle['progress']}>
                                    <progress
                                        className={loansStyle.progressBar}
                                        max={field.total_loan_amount ? field.total_loan_amount : 100}
                                        value={
                                            isNaN(field.total_loan_repaid / field.total_loan_amount)
                                                ? 0
                                                : Math.round(
                                                    (field.total_loan_repaid / field.total_loan_amount) * 100,
                                                )
                                        }
                                    ></progress>
                                    <div className={loansStyle['progress-value']}></div>
                                    <div className={loansStyle['progress-bg']}>
                                        <div className={loansStyle['progress-detail-value']}>
                                            {formatNumber(field.total_loan_repaid)} /{' '}
                                            {formatNumber(field.total_loan_amount)}
                                        </div>
                                        <div className={loansStyle['progress-bar']}></div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={cx(loansStyle.LoansTableLineItemArrow)}
                                onClick={() => getInfo(field.id, index)}
                            >
                                <svg
                                    width="25"
                                    height="25"
                                    viewBox="0 0 25 25"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M14.0326 15.4541L19.4839 9.84364C19.8387 9.4787 19.8387 8.88864 19.4839 8.5237C19.1293 8.15877 18.556 8.15877 18.2014 8.5237L12.7499 14.1342L7.2986 8.5237C6.94384 8.15877 6.37067 8.15877 6.01607 8.5237C5.66131 8.88864 5.66131 9.4787 6.01607 9.84364L11.4674 15.4541C11.4674 15.4541 12.1795 16.25 12.7499 16.25C13.3203 16.25 14.0326 15.4541 14.0326 15.4541Z"
                                        fill="#1E1E1E"
                                    />
                                </svg>
                            </div>
                        </div>
                        {field.elem && field.elem.length ? (
                            <div className={loansStyle['loans-details-container']}>
                                {field.elem.map((detail, detailIndex) => (
                                    <div key={detailIndex}>
                                        <div className={loansStyle['loans-line__detail']}>
                                            <div
                                            // onClick={() => {
                                            //     if (detail.lender_type !== 'Частное лицо' && detail.lender_type !== 'Юридическое лицо') {
                                            //         showDetail(detail.id);
                                            //     }
                                            // }}
                                            // className={loansStyle['loans-line__detail-ico']}
                                            >
                                                {detail.lender_type}
                                                {/* <svg
                                                    width="13"
                                                    height="13"
                                                    viewBox="0 0 13 13"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M6.25057 4.98048C6.37286 4.98012 6.49004 4.93139 6.57652 4.84492C6.66299 4.75844 6.71173 4.64126 6.71208 4.51897V4.05746C6.71208 3.93506 6.66346 3.81767 6.57691 3.73112C6.49036 3.64457 6.37297 3.59595 6.25057 3.59595C6.12817 3.59595 6.01079 3.64457 5.92424 3.73112C5.83769 3.81767 5.78906 3.93506 5.78906 4.05746V4.51897C5.78941 4.64126 5.83815 4.75844 5.92462 4.84492C6.0111 4.93139 6.12828 4.98012 6.25057 4.98048Z" />
                                                    <path
                                                        d="M5.32674 6.82683H5.78825V9.44217C5.78825 9.56457 5.83688 9.68196 5.92343 9.76851C6.00998 9.85506 6.12736 9.90368 6.24976 9.90368C6.37216 9.90368 6.48955 9.85506 6.5761 9.76851C6.66265 9.68196 6.71127 9.56457 6.71127 9.44217V6.36532C6.71092 6.24303 6.66218 6.12584 6.57571 6.03937C6.48924 5.9529 6.37205 5.90416 6.24976 5.90381H5.32674C5.20434 5.90381 5.08696 5.95243 5.00041 6.03898C4.91386 6.12553 4.86523 6.24292 4.86523 6.36532C4.86523 6.48772 4.91386 6.60511 5.00041 6.69166C5.08696 6.77821 5.20434 6.82683 5.32674 6.82683Z" />
                                                    <path
                                                        d="M6.25 12.75C7.43669 12.75 8.59672 12.3981 9.58342 11.7388C10.5701 11.0795 11.3391 10.1425 11.7933 9.0461C12.2474 7.94974 12.3662 6.74335 12.1347 5.57946C11.9032 4.41557 11.3318 3.34648 10.4926 2.50736C9.65352 1.66825 8.58443 1.0968 7.42054 0.865291C6.25665 0.63378 5.05025 0.7526 3.9539 1.20673C2.85754 1.66085 1.92047 2.42989 1.26118 3.41658C0.601894 4.40328 0.25 5.56331 0.25 6.75C0.251736 8.34077 0.884435 9.86588 2.00928 10.9907C3.13412 12.1156 4.65923 12.7483 6.25 12.75ZM6.25 1.6112C7.26636 1.6112 8.25989 1.91258 9.10497 2.47724C9.95004 3.0419 10.6087 3.84447 10.9976 4.78347C11.3866 5.72246 11.4883 6.7557 11.2901 7.75253C11.0918 8.74936 10.6024 9.66501 9.88368 10.3837C9.16501 11.1024 8.24936 11.5918 7.25253 11.7901C6.2557 11.9883 5.22246 11.8866 4.28346 11.4976C3.34447 11.1087 2.5419 10.45 1.97724 9.60497C1.41258 8.7599 1.11119 7.76636 1.11119 6.75C1.11223 5.38742 1.65397 4.08095 2.61746 3.11746C3.58095 2.15397 4.88742 1.61223 6.25 1.6112Z" />
                                                </svg> */}
                                            </div>
                                            <div>
                                                <span className={loansStyle.colorGreen}>
                                                    {formatNumber(detail.total_loan_repaid)}
                                                </span>{' '}
                                                / {formatNumber(detail.total_loan_amount)}
                                            </div>
                                            <div className={loansStyle['progress']}>
                                                <progress
                                                    className={loansStyle.progressBar}
                                                    max={detail.total_loan_amount ? detail.total_loan_amount : 100}
                                                    value={
                                                        isNaN(detail.total_loan_repaid / detail.total_loan_amount)
                                                            ? 0
                                                            : Math.round(
                                                                (detail.total_loan_repaid / detail.total_loan_amount) * 100,
                                                            )
                                                    }
                                                ></progress>
                                                <div className={loansStyle['progress-value']}></div>
                                                <div className={loansStyle['progress-bg']}>
                                                    <div className={loansStyle['progress-bar']}></div>
                                                </div>
                                            </div>
                                            <div
                                                onClick={() => toggleItemList(detailIndex, activeDetailIndexList, setActiveDetailIndexList)}
                                                className={cx(loansStyle.LoansTableLineItemArrow)}
                                            >
                                                <svg
                                                    width="25"
                                                    height="25"
                                                    viewBox="0 0 25 25"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M14.0326 15.4541L19.4839 9.84364C19.8387 9.4787 19.8387 8.88864 19.4839 8.5237C19.1293 8.15877 18.556 8.15877 18.2014 8.5237L12.7499 14.1342L7.2986 8.5237C6.94384 8.15877 6.37067 8.15877 6.01607 8.5237C5.66131 8.88864 5.66131 9.4787 6.01607 9.84364L11.4674 15.4541C11.4674 15.4541 12.1795 16.25 12.7499 16.25C13.3203 16.25 14.0326 15.4541 14.0326 15.4541Z"
                                                        fill="#1E1E1E"
                                                    />
                                                </svg>
                                            </div>
                                        </div>
                                        {activeDetailIndexList.includes(detailIndex) && detail.lenders && detail.lenders.length ? (
                                            <div className={loansStyle['loans-details-container__loans']}>
                                                {detail.lenders.map((lender, lenderIndex) => (
                                                    <div key={lenderIndex}>
                                                        <div
                                                            className={loansStyle['loans-line__detail__loans']}
                                                        >
                                                            <div>{lender.lender_name}</div>
                                                            <div>
                                                                <span className={loansStyle.colorGreen}>
                                                                    {formatNumber(lender.total_loan_repaid)}
                                                                </span>{' '}
                                                                / {formatNumber(lender.total_loan_amount)}
                                                            </div>
                                                            <div className={loansStyle['progress']}>
                                                                <progress
                                                                    className={loansStyle.progressBar}
                                                                    max={lender.total_loan_amount ? lender.total_loan_amount : 100}
                                                                    value={
                                                                        isNaN(lender.total_loan_repaid / lender.total_loan_amount)
                                                                            ? 0
                                                                            : Math.round(
                                                                                (lender.total_loan_repaid / lender.total_loan_amount) * 100,
                                                                            )
                                                                    }
                                                                ></progress>
                                                                <div className={loansStyle['progress-value']}></div>
                                                                <div className={loansStyle['progress-bg']}>
                                                                    <div className={loansStyle['progress-bar']}></div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                onClick={() => toggleItemList(lender.id, activeLoanIndexList, setActiveLoanIndexList)}
                                                                className={cx(loansStyle.LoansTableLineItemArrow)}
                                                            >
                                                                <svg
                                                                    width="25"
                                                                    height="25"
                                                                    viewBox="0 0 25 25"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path
                                                                        d="M14.0326 15.4541L19.4839 9.84364C19.8387 9.4787 19.8387 8.88864 19.4839 8.5237C19.1293 8.15877 18.556 8.15877 18.2014 8.5237L12.7499 14.1342L7.2986 8.5237C6.94384 8.15877 6.37067 8.15877 6.01607 8.5237C5.66131 8.88864 5.66131 9.4787 6.01607 9.84364L11.4674 15.4541C11.4674 15.4541 12.1795 16.25 12.7499 16.25C13.3203 16.25 14.0326 15.4541 14.0326 15.4541Z"
                                                                        fill="#1E1E1E"
                                                                    />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        {activeLoanIndexList.includes(lender.id) && lender.loans && lender.loans.length ? (
                                                            <div className={loansStyle['loans-details-container__loans']}>
                                                                {lender.loans.map((loan, loanIndex) => (
                                                                    <div key={loanIndex} className={loansStyle['loans-line__detail__loans']}>
                                                                        <div>{loan.name}</div>
                                                                        <div>
                                                                            <span className={loansStyle.colorGreen}>
                                                                                {formatNumber(loan.total_loan_repaid)}
                                                                            </span>{' '}
                                                                            / {formatNumber(loan.loan_amount)}
                                                                        </div>
                                                                        <div className={loansStyle['progress']}>
                                                                            <progress
                                                                                className={loansStyle.progressBar}
                                                                                max={loan.loan_amount ? loan.loan_amount : 100}
                                                                                value={
                                                                                    isNaN(loan.total_loan_repaid / loan.loan_amount)
                                                                                        ? 0
                                                                                        : Math.round(
                                                                                            (loan.total_loan_repaid / loan.loan_amount) * 100
                                                                                        )
                                                                                }
                                                                            ></progress>
                                                                            <div className={loansStyle['progress-value']}></div>
                                                                            <div className={loansStyle['progress-bg']}>
                                                                                <div className={loansStyle['progress-bar']}></div>
                                                                            </div>
                                                                        </div>
                                                                        <LoanAction
                                                                            showModal={showModal}
                                                                            setData={setModalData}
                                                                            remove={remove}
                                                                            params={{
                                                                                organisation: field.name,
                                                                                date_provision: loan.date_provision ?? null,
                                                                                payment_refund_date: loan.payment_refund_date ?? null,
                                                                                lender_type: detail.lender_type,
                                                                                lender_name: lender.lender_name,
                                                                                percentages: loan.percentages ?? 0,
                                                                                loan_currency: loan.loan_currency,
                                                                                loan_amount: loan.loan_amount ?? 0,
                                                                                name: loan.name,
                                                                                loan_id: loan.id
                                                                            }}
                                                                        />
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        ) : null}
                                                    </div>

                                                ))}
                                            </div>

                                        ) : null}
                                    </div>
                                ))}
                            </div>
                        ) : null}
                    </div>
                ))}
            </div>
        </div>
    );
});

export default LoansSummary;
