import React, {useEffect, useRef} from "react";
import style from '../../Purchase.module.scss'

const Confirm = ({action, message, show, toggle}) => {

    const elementRef = useRef(null)

    useEffect(() => {
    
            const element = elementRef.current;
    
            const handleOutsideClick = (e) => {
    
                if (element && !element.contains(e.target)) {
                    toggle(false)
                }
            };
    
            document.addEventListener('mousedown', handleOutsideClick);
    
            return () => {
                document.removeEventListener('mousedown', handleOutsideClick);
            };
    
        }, [show]);

    return(
        show

        &&

        <div className={style.modalWrap}>
            <div className={style.content} ref={elementRef}>

                <div className={style.message}>
                    <div className={style.head}>
                        Подтвердите действие
                    </div>

                    <div>{message}</div>
                </div>

                <div className={style.buttons}>
                    <button className={style.create} onClick={() => {toggle(false);action()}}>Сохранить</button>
                    <button className={style.cancel} onClick={() => toggle(false)}>Отменить</button>
                </div>

            </div>
        </div>
    )
}

export default Confirm