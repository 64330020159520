import React, { useState, useEffect } from "react";
import Item from "../../view/item/index.jsx";
import { API_URL, postData, getData, putDataNew } from "../../../../static/js/request.js";
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { popupNotification } from "../../../../static/js/popupNotification.jsx";

const AcceptanceItem = () => {

    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const [orderParams] = useState(location.state?.itemParams || null)

    const prepareOrderProducts = (data) => {
        return data.map(item => ({
            product: item.product,
            count_plan: 0,
            count_fact: item.count,
            count_diff: 0,
            price: item.price
        }))
    }

    const [acceptanceParams, setAcceptanceParams] = useState({
        id: '',
        user: '',
        itemType: "acceptance",
        formTitle: 'Приемка',
        dialogMessage: 'Сохранить приемку и перейти к созданию отгрузки',
        actionDate: orderParams?.actionDate || '',
        status: '',
        columnWidth: '356px',
        columns: {
            warehouse: {
                label: 'Склад',
                alias: 'warehouse',
                placeholder: 'Выберите склад',
                value: '',
                options: [],
                needSearch:true
            },
            organisation: {
                label: 'Организация',
                alias: 'organisation',
                placeholder: 'Выберите организацию',
                value: orderParams?.columns.organisation.value || '',
                required: true,
                options: []
            },
            counterparty: {
                label: 'Контрагент',
                alias: 'counterparty',
                placeholder: 'Выберите контрагента',
                value: orderParams?.columns.counterparty.value || '',
                required: true,
                options: [],
                needSearch:true
            }

        },
        comment: '',
        products: orderParams?.products ? prepareOrderProducts(orderParams.products) : [],
        purchase: orderParams?.id || null,
        conducted: false

    })

    const itemAction = async () => {        

        const requestData = {
            id: acceptanceParams.id || 0,
            user: acceptanceParams.user || 0,
            warehouse: acceptanceParams.columns.warehouse.value,
            organisation: acceptanceParams.columns.organisation.value,
            status: acceptanceParams.status,
            counterparty: acceptanceParams.columns.counterparty.value,
            acceptance_datetime: acceptanceParams.actionDate,
            comment: acceptanceParams.comment,
            purchase: acceptanceParams.purchase,
            products: acceptanceParams.products,
            conducted: acceptanceParams.conducted
        }

        if (id) {

            await putDataNew(API_URL + '/api/v1/purchase/acceptance/', requestData)
                .then((response) => {
                    popupNotification(response, 'update')
                })
        }

        else {
            await postData(API_URL + '/api/v1/purchase/acceptance/', requestData)
                .then((response) => {
                    popupNotification(response, 'create')
                    if(response.ok){
                        return response.json();
                    }
                })
                .then((response) => {
                    if (response.id) {
                        setAcceptanceParams(prev => ({
                            ...prev,
                            id: response.id
                        }))
                        navigate(`/purchase/acceptance/item/${response.id}`)                       
                    }
                })
        }



    }

    useEffect(() => {

        if (id) {
            getData(API_URL + `/api/v1/purchase/acceptance/${id}/`)
                .then((data) => {

                    setAcceptanceParams(prev => ({
                        ...prev,
                        comment: data['comment'],
                        id: data['id'],
                        user: data['user'],
                        status: data['status'],
                        actionDate: data['acceptance_datetime'],
                        columns: {
                            ...prev.columns,
                            organisation: {
                                ...prev.columns.organisation,
                                value: data['organisation']['id']
                            },
                            counterparty: {
                                ...prev.columns.counterparty,
                                value: data['counterparty']['id']
                            },
                            warehouse: {
                                ...prev.columns.warehouse,
                                value: data['warehouse']['id']
                            }

                        },
                        purchase: data['purchase'],
                        products: data['products'],
                        conducted: data['conducted']


                    }))
                });
        }

        else {
            getData(API_URL + '/api/v1/account/').then((data) => {
                setAcceptanceParams(prev => ({
                    ...prev,
                    user: data['id']
                }))
            });
        }

    }, [])

    return (

        <Item
            title={id ? "Изменение приемки" : "Создание приемки"}
            itemParams={acceptanceParams}
            setItemParams={(item) => setAcceptanceParams(item)}
            itemAction={() => itemAction()}
        />

    )

}

export default AcceptanceItem