import React, { useRef } from "react";
import style from '../../../../../Purchase.module.scss'
import Head from "./head.jsx";
import Line from "./line.jsx";
import cx from 'classnames';
import ScrollBar from "../../../../../../../components/custom/ScrollBar/index.jsx";
import { useParams } from 'react-router-dom';

const DocumentTable = ({ bodyRef, alertRef, type, user, documentType, list, added = false, setSortingBy, toggleUpdate }) => {

    const { id } = useParams()

    let addedList, searchedList

    if (type === 'acceptance') {
        addedList = list.filter(item => item.purchase == id)
        searchedList = list.filter(item => !item.purchase)
    }

    else {
        const addedIDs = new Set(added.map((item) => item.operation_cashbox));
        addedList = list.filter((item) => addedIDs.has(item.id));
        searchedList = list.filter((item) => !addedIDs.has(item.id));
    }

    const getPayID = (id) => {
        const searched = added.filter(item => item.operation_cashbox == id)
        return searched[0].id
    }

    return (
        <section style={{ maxHeight: '480px' }} className={cx(style.section, style.document)}>

            <Head type={type} setSortingBy={setSortingBy} />

            <div className={style.body} >

                <div ref={bodyRef} className={style.yScroll} >

                    {
                        addedList.length > 0 &&
                        <Line
                            name="Добавленные"
                            user={user}
                            data={addedList}
                            type={type}
                            documentType={documentType}
                            toggleUpdate={toggleUpdate}
                            getPayID={(value) => getPayID(value)}
                            alertRef={alertRef}
                        />
                    }

                    <Line
                        name="Поиск"
                        user={user}
                        data={searchedList}
                        type={type}
                        documentType={documentType}
                        toggleUpdate={toggleUpdate}
                        alertRef={alertRef}
                    />

                </div>

            </div>


        </section>
    )

}

export default DocumentTable