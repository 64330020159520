import React, { useState, useEffect } from 'react'
import style from '../../../Purchase.module.scss'
import DateSelection from '../../../../../feature/DateSelection/index.jsx';
import CustomSelectNew from '../../../../../feature/SelectWithLabel/index.jsx';
import MultiSelection from '../../../../../components/custom/MultiSelection/index.jsx';
import { NewCustomInput } from '../../../../../components/custom/NewCustomInput/NewCustomInput.jsx';
import cx from 'classnames';
import { getFilterOptions } from './filterOptions.jsx';
import { API_URL, getData } from "../../../../../static/js/request";

const FilterSelection = ({ isShow, columns, filters, setFilters }) => {

    const [organisations, setOrganizations] = useState(null)
    const [products, setProducts] = useState(null)
    const [counterParty, setCounterParty] = useState(null)
    const [warehouses, setWarehouses] = useState(null)
    const [shippingMethods, setShippingMethods] = useState(null)

    const getOrganizationData = async () => {

        await getData(API_URL + '/api/v1/organisation/').then((data) => {
            setOrganizations(data)
        });

    }

    const getProducts = async () => {
        await getData(API_URL + `/api/v1/general/user/products/`).then((data) => {
            setProducts(data)
        });
    }

    const getCounterParty = async () => {
        await getData(API_URL + '/api/v1/general/user/counterparty/').then((data) => {
            setCounterParty(data)
        });
    }

    const getWarehouses = async () => {
        await getData(API_URL + '/api/v1/general/user/warehouse/').then((data) => {
            setWarehouses(data)
        })
    }

    const getShippingMethods = async () => {
        await getData(API_URL + '/api/v1/purchase/shipment/methods/').then((data) => {
            setShippingMethods(data)
        })
    }

    const getOptions = (key) => {
        switch (true) {
            case (key === 'organisation'):
                return organisations
            case (key === 'purchase_product_set' || key === 'acceptanceproducts' || key === 'shipmentproduct'):
                return products
            case (key === 'counterparty'):
                return counterParty
            case (key === 'shipping_method'):
                return shippingMethods
            case (key === 'warehouse_id' || key === 'sender_warehouse' || key === 'destination_warehouse'):
                return warehouses
            default:
                return getFilterOptions(key)
        }
    }

    useEffect(() => {
        getOrganizationData()
        getProducts()
        getCounterParty()
        getWarehouses()
        getShippingMethods()
    }, [])

    const getFilter = (column) => {

        const key = column.filterValue

        const filterColumn = filters.find(item => item.key === key);

        switch (true) {
            case (key === 'purchase_date' || key === 'acceptance_datetime' || key === 'created_at'):
                return <DateSelection
                    selectedDate={filterColumn ? filterColumn.value : null}
                    setSelectedDate={(value) => setFilters(key, value)}
                />

            case (key === 'transport_invoice_number' || key === 'comment'):
                return <NewCustomInput
                    type={'text'}
                    value={filterColumn ? filterColumn.value : null}
                    onChange={(value) => setFilters(key, value)}
                    width={'100%'}
                    placeholder="Не выбран"

                />

            default:
                return <MultiSelection
                    data={getOptions(key)}
                    defaultOption={{ name: 'Выбрать' }}
                    action={(value) => setFilters(key, value, true)}
                    value={filterColumn ? filterColumn.value : null}
                    needSearch={column.needSearch ?? false}
                />

        }
    }

    return (
        isShow &&

        <div className={cx(style.bottomRow, style.filterParams)}>
            {Object.values(columns)
                .filter(item => item.filterValue)
                .map((item, index) =>
                    <div className={style.column} key={index}>
                        <div className={style.label}>{item.label}</div>
                        <div className={style.value}>{getFilter(item)}</div>

                    </div>
                )}
        </div>

    )
}

export default FilterSelection