import React, { useEffect, useState, useRef } from "react";
import style from '../../../../Purchase.module.scss'
import EditIcon from '../../../../../../shared/ui/icons/Edit.jsx';
import cx from 'classnames';
import PresetForm from "../presetForm.jsx";

const FilterItem = ({ data, current, setCurrent, remove }) => {

    const [show, setShow] = useState(false);
    const elementRef = useRef(null);

    useEffect(() => {

        const handleOutsideClick = (e) => {
            if (elementRef.current && !elementRef.current.contains(e.target)) {
                setShow(false)
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    return (

        <div ref={elementRef} style={{ position: 'relative' }}>

            <div className={cx(style.filterValue, (current === data.id || show) && style.active)}>
                <p title={data.name} onClick={() => setCurrent(data.id)}>
                    {data.name}
                </p>

                <button onClick={() => setShow(prev => !prev)}>
                    <EditIcon />
                </button>

            </div>

            {
                show &&

                <PresetForm remove={remove} id={data.id} defaultName={data.name} leftOutset='124px' />
            }

        </div>

    )

}

export default FilterItem;