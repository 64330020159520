import React, { useState, useEffect } from "react";
import { API_URL, getData, postData } from "../../../../../../static/js/request.js";
import style from '../../../../Purchase.module.scss'
import cx from 'classnames';
import DocumentList from "./document.jsx";
import PlusIcon from "../../../../../../shared/ui/icons/Plus.jsx";
import ChainIcon from "../../../../../../shared/ui/icons/Chain.jsx";
import { Link } from "react-router-dom";

const AddictionSide = ({ itemParams }) => {
    
    const [showPays, togglePays] = useState(false)
    const [needUpdate, toggleUpdate] = useState(false)
    const [showAcceptance, toggleAcceptance] = useState(false)
    const [paysList, setPaysList] = useState([])
    const [linkedPays, setLinkedPays] = useState([])
    const [acceptance, setAcceptance] = useState([])
    const [sortingBy, setSortingBy] = useState({
        key: '',
        value: ''
    })
    const [filters, setFilters] = useState([]);

    const handleFilterChange = (key, value) => {
        setFilters(prev => {
            const existingFilterIndex = prev.findIndex(filter => filter.key === key);

            if (existingFilterIndex !== -1) {
                if (value === null) {
                    return prev.filter(filter => filter.key !== key);
                } else {
                    const newFilters = [...prev];
                    newFilters[existingFilterIndex].value = value;
                    return newFilters;
                }
            } else {
                if (value !== null) {
                    return [...prev, { key: key, value: value }];
                }
                return prev;
            }
        });
    };

    const clearFilters = () => {
        setFilters([])
        setSortingBy({
            key: '',
            value: ''
        })
    }

    const prepareRequestData = () => {
        return {
            sorted: sortingBy.key ? [{ key: sortingBy.key, value: sortingBy.value }] : [],
            filters: filters.length ? filters : []
        }
    }

    const getPays = () => {

        postData(API_URL + `/api/v1/cashbox/operations/all/`, prepareRequestData())
            .then((response) => response.json())
            .then((result) => setPaysList(result))

        getData(API_URL + `/api/v1/purchase/payments/${itemParams.itemType}/${itemParams.id}/`)
            .then((response) => setLinkedPays(response))

    }

    const getAcceptance = () => {

        postData(API_URL + `/api/v1/purchase/acceptance/list/`, prepareRequestData())
            .then((response) => response.json())
            .then((result) => setAcceptance(result.data))

    }

    useEffect(() => {

        if (itemParams.id) {
            getPays()
            getAcceptance()
        }

    }, [itemParams.id])


    useEffect(() => {

        if (showPays) {

            getPays()

        }

        if (showAcceptance) {

            getAcceptance()

        }

        toggleUpdate(false)

    }, [sortingBy, filters, needUpdate])

    const getCountStyle = (array) => {

        const length = array.length

        switch (true) {
            case (length > 1):
                return style.success;
            case (length < 1):
                return style.error;
            default:
                return style.warning;
        }
    }

    return (

        <>

            <div className={style.rigth}>

                {itemParams.formTitle === 'Заказ поставщику'
                    &&
                    itemParams.id
                    &&
                    <Link
                        to={`/purchase/acceptance/item/`}
                        state={{ itemParams }}
                    >
                        <button className={style.newInstock}>
                            <PlusIcon />
                            <span>Создать приемку от заказа</span>
                        </button>
                    </Link>

                }

                {
                    itemParams.formTitle === 'Заказ поставщику'
                    &&
                    itemParams.id
                    &&
                    <button onClick={() => { getAcceptance(); toggleAcceptance(prev => !prev) }} className={style.chain}>
                        <ChainIcon />
                        <span>Приемки</span>
                        <span className={cx(style.count, getCountStyle(acceptance.filter(item => item.purchase == itemParams.id)))}>{acceptance.filter(item => item.purchase == itemParams.id).length}</span>
                    </button>
                }

                {itemParams.id
                    &&
                    <button onClick={() => { getPays(); togglePays(prev => !prev) }} className={style.chain}>
                        <ChainIcon />
                        <span>Платежи</span>
                        <span className={cx(style.count, getCountStyle(linkedPays))}>{linkedPays.length}</span>
                    </button>
                }

            </div>

            {itemParams.id
                &&
                showAcceptance
                &&
                <DocumentList
                    type="acceptance"
                    itemParams={itemParams}
                    setSortingBy={(value) => setSortingBy(value)}
                    filters={filters}
                    setFilters={(key, value) => handleFilterChange(key, value)}
                    clearFilters={() => clearFilters()}
                    show={showAcceptance}
                    toggle={(val) => toggleAcceptance(val)}
                    list={acceptance}
                    toggleUpdate={(value) => toggleUpdate(value)}
                />
            }

            {itemParams.id
                &&
                showPays
                &&
                <DocumentList
                    type="pays"
                    itemParams={itemParams}
                    setSortingBy={(value) => setSortingBy(value)}
                    filters={filters}
                    setFilters={(key, value) => handleFilterChange(key, value)}
                    clearFilters={() => clearFilters()}
                    show={showPays}
                    toggle={(val) => togglePays(val)}
                    list={paysList}
                    added={linkedPays}
                    toggleUpdate={(value) => toggleUpdate(value)}
                />
            }

        </>

    )


}

export default AddictionSide