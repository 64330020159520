export const transformFilters = (data) => { 
    
    if (!Array.isArray(data) || data.length === 0) {
        return []; 
    }

    return data 
        .map((item) => {
            const transformedValue = Array.isArray(item.value)
                ? item.value
                    .map((v) => {
                        if (typeof v === 'object') {
                            return v.searchParam ? v[v.searchParam] : v.id;
                        }
                        return v;
                    })
                    .filter(Boolean)
                : [item.value];

            if (Array.isArray(transformedValue) && transformedValue.length === 0) {
                return null;
            }

            return {
                key: item.key,
                value: transformedValue
            };
        })
        .filter(Boolean);

}