// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".I65buqKRjmGQUAZ1_L12 {\n  background: #454F5C;\n  color: white;\n  padding: 10px;\n  border-radius: 5px;\n  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);\n  width: 200px;\n}\n.I65buqKRjmGQUAZ1_L12 .ruP3tBLmcyih1c0DlWya {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  color: var(--Color-white, #FFFFFF);\n  align-items: center;\n  font-size: 12px;\n}\n.I65buqKRjmGQUAZ1_L12 .ruP3tBLmcyih1c0DlWya.Fp4M9u5u28DQM685wQTJ {\n  border-top: 1px solid var(--Color-gray, #8496AF);\n  font-weight: 500;\n  padding-top: 5px;\n}", "",{"version":3,"sources":["webpack://./src/feature/ExecutionDescription/styles.module.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,YAAA;EACA,aAAA;EACA,kBAAA;EACA,yCAAA;EACA,YAAA;AACJ;AACI;EACI,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,kCAAA;EACA,mBAAA;EACA,eAAA;AACR;AACQ;EACE,gDAAA;EACA,gBAAA;EACA,gBAAA;AACV","sourcesContent":[".executionDescription {\n    background: #454F5C;\n    color: white;\n    padding: 10px;\n    border-radius: 5px;\n    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);\n    width: 200px;\n\n    .row {\n        display: flex;\n        flex-direction: row;\n        justify-content: space-between;\n        color: var(--Color-white, #FFFFFF);\n        align-items: center;\n        font-size: 12px;\n  \n        &.bottom {\n          border-top: 1px solid var(--Color-gray, #8496AF);\n          font-weight: 500;\n          padding-top: 5px;\n        }\n  \n      }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"executionDescription": "I65buqKRjmGQUAZ1_L12",
	"row": "ruP3tBLmcyih1c0DlWya",
	"bottom": "Fp4M9u5u28DQM685wQTJ"
};
export default ___CSS_LOADER_EXPORT___;
