import React, { useRef, useEffect, useState } from "react";
import style from '../../../../Purchase.module.scss'
import cx from 'classnames';
import DocumentTable from "./table/index.jsx";
import DocumentFilter from "./filter/index.jsx";
import CloseIcon from "../../../../../../shared/ui/icons/Close.jsx";
import { Link } from "react-router-dom";
import ScrollBar from "../../../../../../components/custom/ScrollBar/index.jsx";

const DocumentList = ({ type, itemParams, setSortingBy, filters, setFilters, clearFilters, list, added = false, show, toggle, toggleUpdate }) => {

    const elementRef = useRef(null)
    const alertRef = useRef(null)
    const bodyRef = useRef(null);
    const [hasVerticalScrollbar, setHasVerticalScrollbar] = useState(false);
    
    useEffect(() => {

        const element = elementRef.current;

        const handleOutsideClick = (e) => {            
    
            if (element.current && !element.current.contains(e.target) && alertRef.current && !alertRef.current.contains(e.target)) {
                toggle(false)
            }
        };

        const checkVerticalScrollbar = () => {
            if (bodyRef.current) {
                setHasVerticalScrollbar(bodyRef.current.scrollHeight > bodyRef.current.clientHeight);
            }
        };

        checkVerticalScrollbar();
        window.addEventListener('resize', checkVerticalScrollbar);
        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            window.removeEventListener('resize', checkVerticalScrollbar);
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [list, show]);

    return (
        show

        &&

        <div className={cx(style.modalWrap, style.document)}>
            <div className={style.content} ref={elementRef}>

                <div className={style.pageTitle}><span>{type === 'acceptance' ? 'Связанные документы' : 'Платежи'}</span></div>

                <DocumentFilter 
                    filters={filters} 
                    setFilters={setFilters} 
                    type={type} />

                <DocumentTable 
                    bodyRef={bodyRef}
                    alertRef={alertRef}
                    type={type}
                    user={itemParams.user}
                    documentType={itemParams.itemType}
                    list={list}
                    added={added}
                    setSortingBy={setSortingBy}
                    toggleUpdate={toggleUpdate}
                     />

                <div className={cx(style.buttons, type === 'acceptance' ? style.acceptance : style.pays)}>

                    {
                        type === 'acceptance' &&
                        <div className={style.left}>
                            <Link
                                to={`/purchase/acceptance/item/`}
                                state={{ itemParams }}
                            >
                                <button className={style.chain}>                                    
                                    <span>Создать приемку от заказа</span>
                                </button>
                            </Link>
                        </div>
                    }

                    <div className={style.rigth}>
                        <button className={style.cancel} onClick={() => { clearFilters(); toggle(prev => !prev) }}>Отменить</button>
                        <button className={style.create} onClick={() => { clearFilters(); toggle(prev => !prev) }}>Сохранить</button>
                    </div>

                </div>

                <div className={style.close} onClick={() => { clearFilters(); toggle(false) }}><CloseIcon /></div>

                {
                    hasVerticalScrollbar &&
                    <ScrollBar targetRef={bodyRef} params={{ modal: true, height: 400 }} />
                }

            </div>

        </div>
    )


}

export default DocumentList