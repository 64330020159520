import React, { useEffect, useRef } from "react";
import style from '../PlanFact.module.scss'
import { Checkbox } from "@mui/material";
import LineSVG from "../../../../shared/ui/icons/Line.jsx";

const TableSettings = ({ show, showSettings, showSettingsButtonRef, columnsVisibility, allColumnsSelected, toggleSelectAllColumns, toggleColumnVisibility }) => {

    const tableSettingsRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (tableSettingsRef.current && !tableSettingsRef.current.contains(event.target) && showSettingsButtonRef.current && !showSettingsButtonRef.current.contains(event.target)) {
                showSettings(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showSettings]);

    return (

        show

        &&

        <div ref={tableSettingsRef} className={style.tableSettings}>
            <div className={style.header}>Настройка таблицы</div>

            <div className={style.column}>

                <div className={style.head}>
                    <span className={style.label}>Столбцы</span>
                    <LineSVG width="98" />
                    <span className={style.checkAll}>Выбрать все
                        <Checkbox
                            checked={allColumnsSelected}
                            onChange={() => toggleSelectAllColumns()}
                            color="primary"
                        />
                    </span>


                </div>

                <div className={style.item}>

                    <Checkbox
                        color="primary"
                        checked={columnsVisibility.orders_count}
                        onChange={() => toggleColumnVisibility('orders_count')}
                    />
                    <span>Заказы шт</span>
                </div>

                <div className={style.item}>

                    <Checkbox
                        color="primary"
                        checked={columnsVisibility.orders_sum}
                        onChange={() => toggleColumnVisibility('orders_sum')}
                    />
                    <span>Заказы руб</span>
                </div>

                <div className={style.item}>

                    <Checkbox
                        color="primary"
                        checked={columnsVisibility.sales_count}
                        onChange={() => toggleColumnVisibility('sales_count')}
                    />
                    <span>Продажи шт</span>
                </div>

                <div className={style.item}>

                    <Checkbox
                        color="primary"
                        checked={columnsVisibility.sales_sum}
                        onChange={() => toggleColumnVisibility('sales_sum')}
                    />
                    <span>Продажи руб</span>
                </div>

                <div className={style.item}>

                    <Checkbox
                        color="primary"
                        checked={columnsVisibility.stock}
                        onChange={() => toggleColumnVisibility('stock')}
                    />
                    <span>Склад</span>
                </div>

            </div>

            <div className={style.column}>

                <div className={style.head}>
                    <span className={style.label}>Строки</span>
                    <LineSVG />
                    <span className={style.checkAll}>Выбрать все<Checkbox
                        color="primary"
                    /></span>


                </div>

                <div className={style.item}>

                    <Checkbox
                        color="primary"
                    />
                    <span>По артикулам</span>
                </div>

                <div className={style.item}>

                    <Checkbox
                        color="primary"
                    />
                    <span>По размерам</span>
                </div>

                <div className={style.item}>

                    <Checkbox
                        color="primary"
                    />
                    <span>По тегам</span>
                </div>



            </div>

        </div>

    )

}

export default TableSettings