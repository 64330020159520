import React from "react";
import LineSVG from "../../../../../../../shared/ui/icons/Line.jsx";
import Row from "./row.jsx";
import style from '../../../../../Purchase.module.scss'

const Line = ({ name, user, data, type, documentType, toggleUpdate, getPayID = false, alertRef }) => {
    return (

        <>

            <div className={style.divider}>{name} {name === "Добавленные" && <span>{data.length}</span>}</div>

            <Row
                name={name}
                user={user}
                data={data}
                type={type}
                documentType={documentType}
                toggleUpdate={toggleUpdate}
                getPayID={getPayID}
                alertRef={alertRef}
            />

        </>

    )
}

export default Line