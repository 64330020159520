import React, { useEffect, useMemo, useRef, useState } from 'react';
import styles from './styles.module.scss';
import YearSelect from '../Balance/YearSelect/index.jsx';
import ContentItem from './ContentItem.jsx';
import CheckboxList from './CheckboxList/index.jsx';
import { DateRangePicker } from 'react-date-range';
import { ru } from 'date-fns/locale';
import dayjs from 'dayjs';
import { API_URL, getData } from '../../static/js/request';
import { useQuery } from "react-query";

export const onMainScroll = (event, scrollId) => {
    const scrollPosition = event.currentTarget.scrollLeft;
    document
        .querySelectorAll(scrollId || '#scrollableOfMain')
        .forEach((scroll) => {
            scroll.scrollTo(scrollPosition, 0);
        });
};

//const statisticQuery = async () => (await getData(API_URL + `/api/v1/advertisement/wb/ad-statistics/`));

function Advertisement() {
    const [tagsSelect, setSelectTags] = useState([]);
    const [brandsSelect, setSelectBrands] = useState([]);
    const [categoriesSelect, setSelectCategories] = useState([]);
    const [articlesSelect, setSelectArticles] = useState([]);
    const [companiesSelect, setSelectCompanies] = useState([]);
    const [savedDates, setSavedDates] = useState({
        startDate: null,
        endDate: null,
    });

    const [calendarOpen, setOpenCalendar] = useState(false);

    const [disabledMetric, setDisabledMetric] = useState([]);
    const [metricFilter, setMetricFIlter] = useState(false);

    const removeTag = (item) => {
        setSelectTags((state) => state.filter((tag) => tag !== item));
    };

    const removeBrand = (item) => {
        setSelectBrands((state) => state.filter((tag) => tag !== item));
    };

    const removeCategory = (item) => {
        setSelectCategories((state) => state.filter((tag) => tag !== item));
    };

    const removeArticle = (item) => {
        setSelectArticles((state) => state.filter((tag) => tag !== item));
    };

    const removeCompany = (item) => {
        setSelectCompanies((state) => state.filter((tag) => tag !== item));
    };
    const [data, setData] = useState(null);

    const [infoLoading, setInfoLoading] = useState(false);
    const [offset, setOffset] = useState(1);
    const [hasNext, setHasNext] = useState(true);

    const getInfo = async () => {
        setInfoLoading(true);
        const productsId = products
            .filter((temp) => articlesSelect.includes(temp.vendorCode))
            .map((temp) => temp.id);

        const companiesId = companies
            .filter((temp) => companiesSelect.includes(temp.name))
            .map((temp) => temp.id);

        const tagsId = tags
            .filter((temp) => tagsSelect.includes(temp.name))
            .map((temp) => temp.id);

        try {
            const paramsString = `${savedDates.startDate
                    ? `dateFrom=${dayjs(savedDates.startDate).format(
                        'DD.MM.YYYY'
                    )}&dateTo=${dayjs(savedDates.endDate).format('DD.MM.YYYY')}&`
                    : ''
                }${brandsSelect.length > 0 ? `brand=${brandsSelect.join(',')}&` : ''}${articlesSelect.length > 0 ? `article=${productsId.join(',')}&` : ''
                }${companiesSelect.length > 0
                    ? `campaignIds=${companiesId.join(',')}&`
                    : ''
                }${categoriesSelect.length > 0
                    ? `category=${JSON.stringify(categoriesSelect.join(','))}&`
                    : ''
                }${tagsSelect.length > 0 ? `tag=${tagsId.join(',')}&` : ''}${metricsSaved.length > 0 ? `metric=${metricsSaved.join(',')}&` : ''
                }`;
            const response = await getData(
                API_URL +
                '/api/v1/advertisement/wb/ad-statistics/?' +
                paramsString.substring(0, paramsString.length - 1) + `${offset > 1 ? ('offset=' + offset) : ''}`
            );
            if (response.values && response.values.length > 2) {
                setHasNext(true);
            } else {
                setHasNext(false);
            }
            //console.log(data);
            //console.log(data ? { dates: [...data.dates, response.dates], values: [...data.values, response.values] } : response);

            //console.log(response)

            setData(response);

            setInfoLoading(false);
            
        } catch (error) { }
    };

    const [metricsSaved, setSavedMetrics] = useState([]);

    const [start, setStart] = useState(true);

    /*useQuery('adStatistics', statisticQuery, {
        onSuccess: (data) => {
            console.log(data);
        }
    })*/

    useEffect(() => {
        if (!start) {
            getInfo();
        } else {
            setStart(false);
        }
    }, [
        savedDates,
        brandsSelect,
        articlesSelect,
        companiesSelect,
        tagsSelect,
        categoriesSelect,
        metricsSaved,
        offset
    ]);

    const removeAllFilters = () => {
        setSelectTags([]);
        setSelectBrands([]);
        setSelectCategories([]);
        setSelectArticles([]);
        setSelectCompanies([]);
        resetDates();
    };

    const saveMetrics = () => {
        setSavedMetrics([
            ...data?.values[0].metrics.metrics_names.filter(
                (temp) => !disabledMetric.includes(temp)
            ),
        ]);
        setMetricFIlter(false);
    };

    useEffect(() => {
        if (metricFilter && metricsSaved.length > 0) {
            setDisabledMetric(
                data?.values[0].metrics.metrics_names.filter(
                    (temp) => !metricsSaved.includes(temp)
                )
            );
        }
    }, [metricFilter]);

    const [dataChanged, setChengedData] = useState(false);

    useEffect(() => {
        if (tags && data?.values && !dataChanged) {
            setChengedData(true);
            setData({
                ...data,
                values: data.values.map((item) => {
                    return {
                        ...item,
                        product: {
                            ...item.product,
                            tags: tags.filter((tag) =>
                                item.product.tags.map((temp) => temp.id).includes(tag.id)
                            ),
                        },
                    };
                }),
            });
        }
    }, [tags, data]);

    const [tags, setTags] = useState([]);

    const [products, setProducts] = useState([]);

    const getAllTags = async () => {
        // if (tags.length === 0) {
        //   try {
        //     const response = await getData(API_URL + '/api/v1/tags/');
        //     setTags(response.tags);
        //   } catch (error) {}
        // }
    };

    const [companies, setCompanies] = useState([]);

    const getAllCompanies = async () => {
        try {
            const response = await getData(API_URL + '/api/v1/advertisement/wb/ad-campaigns/');
            setCompanies(response);
        } catch (error) { }
    };

    const [articles, setArticles] = useState([]);

    const getProducts = async () => {
        try {
            const response = await getData(API_URL + '/api/v1/general/user/products/');
            setArticles(response.map((item) => item.vendorCode));
            setProducts(response);
        } catch (error) { }
    };

    const [categories, setCategories] = useState([]);

    const getCategories = async () => {
        try {
            const response = await getData(API_URL + '/api/v1/general/user/categories/');
            setCategories(response);
        } catch (error) { }
    };

    const [brands, setBrands] = useState([]);

    const getBrands = async () => {
        try {
            const response = await getData(API_URL + '/api/v1/general/user/brands/');
            setBrands(response.filter((item) => item));
        } catch (error) { }
    };

    useEffect(() => {
        getAllTags();
        getAllCompanies();
        getProducts();
        getCategories();
        getBrands();
        getInfo();
    }, []);

    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();

    const customLocale = {
        ...ru,
        localize: {
            ...ru.localize,
            day: (day, options = {}) =>
                ru.localize.day(day, { width: 'abbreviated', ...options }).slice(0, 2),
        },
    };

    function getMonday(d) {
        d = new Date(d);
        var day = d.getDay(),
            diff = d.getDate() - day + (day == 0 ? -6 : 1);
        return new Date(d.setDate(diff));
    }

    Date.prototype.addDays = function (days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    };

    const handleSelect = (ranges) => {
        if (calendarMode === 'Неделя') {
            const monday = getMonday(ranges.selection.endDate);
            const sunday = monday.addDays(6);

            setStartDate(monday);
            setEndDate(sunday);
        } else {
            setStartDate(ranges.selection.startDate);
            setEndDate(ranges.selection.endDate);
        }
    };

    function getFirstDayOfMonth(year, month) {
        return new Date(year, month, 1);
    }

    function getLastDayOfMonth(year, month) {
        return new Date(year, month + 1, 0);
    }

    const [calendarMode, setCalendarMode] = useState(null);

    const changeCalndarMode = (mode) => {
        setCalendarMode((state) => (state === mode ? null : mode));
    };

    const months = [
        'январь',
        'февраль',
        'март',
        'апрель',
        'май',
        'июнь',
        'июль',
        'август',
        'сентябрь',
        'октябрь',
        'ноябрь',
        'декабрь',
    ];

    const years = [2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2024];
    const currentYear = new Date().getFullYear();

    const [selectYear, setSelectYear] = useState(currentYear);

    const [selectMonth, setSelectMonth] = useState('');

    useEffect(() => {
        if (calendarMode === 'Месяц') {
            setStartDate(getFirstDayOfMonth(selectYear, months.indexOf(selectMonth)));
            setEndDate(getLastDayOfMonth(selectYear, months.indexOf(selectMonth)));
        } else {
            const newStartDate = new Date();
            newStartDate.setFullYear(selectYear);
            newStartDate.setMonth(0);
            newStartDate.setDate(1);

            const newEndDate = new Date();
            newEndDate.setFullYear(selectYear);
            newEndDate.setMonth(11);
            newEndDate.setDate(31);

            setStartDate(newStartDate);
            setEndDate(newEndDate);
        }
    }, [selectYear, selectMonth]);

    useEffect(() => {
        setStartDate(null);
        setEndDate(null);
    }, [calendarMode]);

    const saveDate = () => {
        setSavedDates({
            startDate,
            endDate,
        });
    };

    const resetDates = () => {
        setSavedDates({
            startDate: null,
            endDate: null,
        });
        setStartDate(null);
        setEndDate(null);
        setSelectMonth(null);
        setSelectYear(currentYear);
    };

    return (
        <section className={styles.advertisement}>
            <h2 className={styles.title}>Реклама</h2>
            <div className={styles.content}>
                <div className={styles.contentTop}>
                    {calendarOpen && (
                        <>
                            <span
                                className={styles.closeCalendarBg}
                                onClick={() => setOpenCalendar(false)}
                            />
                            <div className={styles.calendarBlock}>
                                {calendarMode === 'Месяц' ? (
                                    <div className={styles.calendarWrap}>
                                        <YearSelect
                                            value={selectYear}
                                            onChange={setSelectYear}
                                            allValues={years}
                                            width={'100%'}
                                        />
                                        <div className={styles.months}>
                                            {months.map((item) => (
                                                <button
                                                    className={selectMonth === item ? styles.active : ''}
                                                    onClick={() => setSelectMonth(item)}
                                                >
                                                    {item}
                                                </button>
                                            ))}
                                        </div>
                                    </div>
                                ) : calendarMode === 'Год' ? (
                                    <div className={styles.calendarWrap}>
                                        <div className={styles.months}>
                                            {years.map((item) => (
                                                <button
                                                    className={selectYear === item ? styles.active : ''}
                                                    onClick={() => setSelectYear(item)}
                                                >
                                                    {item}
                                                </button>
                                            ))}
                                        </div>
                                    </div>
                                ) : (
                                    <div className={styles.calendarWrap}>
                                        <DateRangePicker
                                            ranges={[
                                                {
                                                    startDate: startDate ? startDate : new Date(),
                                                    endDate: endDate ? endDate : new Date(),
                                                    key: 'selection',
                                                },
                                            ]}
                                            onChange={handleSelect}
                                            rangeColors={['#3d91ff']}
                                            showSelectionPreview={false}
                                            // disabledDay={(date) => }
                                            months={12}
                                            direction="vertical"
                                            staticRanges={[]}
                                            inputRanges={[]}
                                            fixedHeight={true}
                                            showPreview={false}
                                            showDateDisplay={false}
                                            dateDisplayFormat="dd.MM.yyyy"
                                            locale={customLocale}
                                        />
                                    </div>
                                )}

                                <div className={styles.infoSide}>
                                    <button
                                        className={`${styles.sideBtn} ${calendarMode === 'Неделя' ? styles.active : ''
                                            }`}
                                        onClick={() => changeCalndarMode('Неделя')}
                                    >
                                        Неделя
                                    </button>
                                    <button
                                        className={`${styles.sideBtn} ${calendarMode === 'Месяц' ? styles.active : ''
                                            }`}
                                        onClick={() => changeCalndarMode('Месяц')}
                                    >
                                        Месяц
                                    </button>
                                    <button
                                        className={`${styles.sideBtn} ${calendarMode === 'Год' ? styles.active : ''
                                            }`}
                                        onClick={() => changeCalndarMode('Год')}
                                    >
                                        Год
                                    </button>
                                    <div className={styles.sideInput}>
                                        <p className={styles.inputTitle}>Начало периода</p>
                                        <input
                                            disabled
                                            type="text"
                                            value={
                                                dayjs(startDate).format('DD.MM.YYYY') === 'Invalid Date'
                                                    ? 'Дата начала'
                                                    : dayjs(startDate).format('DD.MM.YYYY')
                                            }
                                        />
                                    </div>
                                    <div className={styles.sideInput}>
                                        <p className={styles.inputTitle}>Конец периода</p>
                                        <input
                                            disabled
                                            type="text"
                                            value={
                                                dayjs(endDate).format('DD.MM.YYYY') === 'Invalid Date'
                                                    ? 'Дата конца'
                                                    : dayjs(endDate).format('DD.MM.YYYY')
                                            }
                                        />
                                    </div>
                                    <div className={styles.bottomBtns}>
                                        <button
                                            onClick={resetDates}
                                            className={styles.closeChanges}
                                        >
                                            Сбросить
                                        </button>
                                        <button
                                            className={styles.saveChanges}
                                            onClick={() => {
                                                saveDate();
                                                setOpenCalendar(false);
                                            }}
                                        >
                                            Сохранить
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    <button
                        onClick={() => setOpenCalendar(true)}
                        className={styles.calendarBtn}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17"
                            height="16"
                            viewBox="0 0 17 16"
                            fill="none"
                        >
                            <path
                                d="M14.4995 1.24544H13.692V0.625465C13.692 0.277529 13.41 -0.00451172 13.0621 -0.00451172C12.7141 -0.00451172 12.4321 0.277529 12.4321 0.625465V1.24544H9.59843V0.625465C9.59843 0.277529 9.31639 -0.00451172 8.96846 -0.00451172C8.62052 -0.00451172 8.33848 0.277529 8.33848 0.625465V1.24544H5.53608V0.625465C5.53608 0.277529 5.25404 -0.00451172 4.90611 -0.00451172C4.55817 -0.00451172 4.27613 0.277529 4.27613 0.625465V1.24544H3.49991C2.1187 1.24544 0.995 2.36914 0.995 3.75035V13.5C0.995 14.8812 2.1187 16.0049 3.49991 16.0049H14.4995C15.8807 16.0049 17.0044 14.8812 17.0044 13.5V3.75035C17.0044 2.36914 15.8807 1.24544 14.4995 1.24544ZM3.49991 2.5054H4.27613V3.12537C4.27613 3.47331 4.55817 3.75535 4.90611 3.75535C5.25404 3.75535 5.53608 3.47331 5.53608 3.12537V2.5054H8.33848V3.12537C8.33848 3.47331 8.62052 3.75535 8.96846 3.75535C9.31639 3.75535 9.59843 3.47331 9.59843 3.12537V2.5054H12.4321V3.12537C12.4321 3.47331 12.7141 3.75535 13.0621 3.75535C13.41 3.75535 13.692 3.47331 13.692 3.12537V2.5054H14.4995C15.186 2.5054 15.7445 3.06389 15.7445 3.75035V13.5C15.7445 14.1865 15.186 14.7449 14.4995 14.7449H3.49991C2.81344 14.7449 2.25495 14.1865 2.25495 13.5V3.75035C2.25495 3.06389 2.81344 2.5054 3.49991 2.5054Z"
                                fill="#429EFF"
                                stroke="#429EFF"
                                strokeWidth="0.01"
                            />
                            <path
                                d="M13.0625 7.18745C13.4076 7.18745 13.6875 6.90764 13.6875 6.56248C13.6875 6.21731 13.4076 5.9375 13.0625 5.9375C12.7173 5.9375 12.4375 6.21731 12.4375 6.56248C12.4375 6.90764 12.7173 7.18745 13.0625 7.18745Z"
                                fill="#429EFF"
                            />
                            <path
                                d="M10.3437 7.18745C10.6889 7.18745 10.9687 6.90764 10.9687 6.56248C10.9687 6.21731 10.6889 5.9375 10.3437 5.9375C9.99856 5.9375 9.71875 6.21731 9.71875 6.56248C9.71875 6.90764 9.99856 7.18745 10.3437 7.18745Z"
                                fill="#429EFF"
                            />
                            <path
                                d="M7.62498 9.9062C7.97014 9.9062 8.24995 9.62639 8.24995 9.28123C8.24995 8.93606 7.97014 8.65625 7.62498 8.65625C7.27981 8.65625 7 8.93606 7 9.28123C7 9.62639 7.27981 9.9062 7.62498 9.9062Z"
                                fill="#429EFF"
                            />
                            <path
                                d="M10.3437 9.9062C10.6889 9.9062 10.9687 9.62639 10.9687 9.28123C10.9687 8.93606 10.6889 8.65625 10.3437 8.65625C9.99856 8.65625 9.71875 8.93606 9.71875 9.28123C9.71875 9.62639 9.99856 9.9062 10.3437 9.9062Z"
                                fill="#429EFF"
                            />
                            <path
                                d="M10.3437 12.6245C10.6889 12.6245 10.9687 12.3447 10.9687 11.9995C10.9687 11.6543 10.6889 11.3745 10.3437 11.3745C9.99856 11.3745 9.71875 11.6543 9.71875 11.9995C9.71875 12.3447 9.99856 12.6245 10.3437 12.6245Z"
                                fill="#429EFF"
                            />
                            <path
                                d="M13.0625 9.9062C13.4076 9.9062 13.6875 9.62639 13.6875 9.28123C13.6875 8.93606 13.4076 8.65625 13.0625 8.65625C12.7173 8.65625 12.4375 8.93606 12.4375 9.28123C12.4375 9.62639 12.7173 9.9062 13.0625 9.9062Z"
                                fill="#429EFF"
                            />
                            <path
                                d="M13.0625 12.6245C13.4076 12.6245 13.6875 12.3447 13.6875 11.9995C13.6875 11.6543 13.4076 11.3745 13.0625 11.3745C12.7173 11.3745 12.4375 11.6543 12.4375 11.9995C12.4375 12.3447 12.7173 12.6245 13.0625 12.6245Z"
                                fill="#429EFF"
                            />
                            <path
                                d="M4.90623 7.18745C5.25139 7.18745 5.5312 6.90764 5.5312 6.56248C5.5312 6.21731 5.25139 5.9375 4.90623 5.9375C4.56106 5.9375 4.28125 6.21731 4.28125 6.56248C4.28125 6.90764 4.56106 7.18745 4.90623 7.18745Z"
                                fill="#429EFF"
                            />
                            <path
                                d="M4.90623 9.9062C5.25139 9.9062 5.5312 9.62639 5.5312 9.28123C5.5312 8.93606 5.25139 8.65625 4.90623 8.65625C4.56106 8.65625 4.28125 8.93606 4.28125 9.28123C4.28125 9.62639 4.56106 9.9062 4.90623 9.9062Z"
                                fill="#429EFF"
                            />
                            <path
                                d="M4.90623 12.625C5.25139 12.625 5.5312 12.3451 5.5312 12C5.5312 11.6548 5.25139 11.375 4.90623 11.375C4.56106 11.375 4.28125 11.6548 4.28125 12C4.28125 12.3451 4.56106 12.625 4.90623 12.625Z"
                                fill="#429EFF"
                            />
                            <path
                                d="M7.62498 12.6245C7.97014 12.6245 8.24995 12.3447 8.24995 11.9995C8.24995 11.6543 7.97014 11.3745 7.62498 11.3745C7.27981 11.3745 7 11.6543 7 11.9995C7 12.3447 7.27981 12.6245 7.62498 12.6245Z"
                                fill="#429EFF"
                            />
                            <path
                                d="M7.62498 7.18745C7.97014 7.18745 8.24995 6.90764 8.24995 6.56248C8.24995 6.21731 7.97014 5.9375 7.62498 5.9375C7.27981 5.9375 7 6.21731 7 6.56248C7 6.90764 7.27981 7.18745 7.62498 7.18745Z"
                                fill="#429EFF"
                            />
                        </svg>
                        <p>
                            {savedDates.startDate || savedDates.endDate
                                ? `${savedDates.startDate.getDate()} ${months[
                                    savedDates.startDate.getMonth()
                                ].substring(0, 3)} ${savedDates.startDate.getFullYear()}` +
                                ' - ' +
                                `${savedDates.endDate.getDate()} ${months[
                                    savedDates.endDate.getMonth()
                                ].substring(0, 3)} ${savedDates.endDate.getFullYear()}`
                                : 'Выберите дату'}
                        </p>
                    </button>
                    <CheckboxList
                        activeList={companiesSelect}
                        onChange={setSelectCompanies}
                        values={companies?.map((company) => company.name)}
                        id={'company'}
                        title={
                            companiesSelect.length > 0
                                ? `Компании: ${companiesSelect.length}`
                                : 'Все компании'
                        }
                    />
                    <CheckboxList
                        activeList={articlesSelect}
                        onChange={setSelectArticles}
                        values={articles}
                        id={'article'}
                        title={
                            articlesSelect.length > 0
                                ? `Артикулы: ${articlesSelect.length}`
                                : 'Все артикулы'
                        }
                    />
                    <CheckboxList
                        activeList={categoriesSelect}
                        onChange={setSelectCategories}
                        values={categories}
                        id={'category'}
                        title={
                            categoriesSelect.length > 0
                                ? `Категории: ${categoriesSelect.length}`
                                : 'Все категории'
                        }
                    />
                    <CheckboxList
                        activeList={brandsSelect}
                        onChange={setSelectBrands}
                        values={brands}
                        id={'brands'}
                        title={
                            brandsSelect.length > 0
                                ? `Бренды: ${brandsSelect.length}`
                                : 'Все бренды'
                        }
                    />
                    <CheckboxList
                        activeList={tagsSelect}
                        onChange={setSelectTags}
                        values={tags.map((tag) => tag.name)}
                        id={'tags'}
                        title={
                            tagsSelect.length > 0 ? `Теги: ${tagsSelect.length}` : 'Все теги'
                        }
                    />
                    <button className={styles.resetFilters} onClick={removeAllFilters}>
                        Сбросить все
                    </button>
                </div>
                {/* <button className={styles.settings}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M20.8954 9.87443L19.4607 9.56254C19.336 9.18058 19.1815 8.80802 18.999 8.44914L19.7933 7.21368C19.9351 6.99294 19.9039 6.70313 19.7185 6.5177L17.4834 4.28263C17.298 4.09721 17.0082 4.06602 16.7875 4.20787L15.552 5.00212C15.1931 4.81968 14.8206 4.66516 14.4386 4.54041L14.1267 3.10571C14.071 2.84937 13.844 2.6665 13.5817 2.6665H10.4209C10.1586 2.6665 9.93158 2.84937 9.8759 3.10571L9.56401 4.54041C9.18205 4.66516 8.80949 4.81968 8.4506 5.00212L7.21515 4.20787C6.99441 4.06602 6.70459 4.09721 6.51917 4.28263L4.2841 6.5177C4.09867 6.70313 4.06748 6.99294 4.20933 7.21368L5.00358 8.44914C4.82115 8.80802 4.66663 9.18058 4.54187 9.56254L3.10718 9.87443C2.85083 9.93026 2.66797 10.1571 2.66797 10.4195V13.5802C2.66797 13.8426 2.85083 14.0694 3.10718 14.1252L4.54187 14.4371C4.66663 14.8191 4.82115 15.1917 5.00358 15.5505L4.20933 16.786C4.06748 17.0067 4.09867 17.2966 4.2841 17.482L6.51917 19.717C6.70459 19.9025 6.99441 19.9337 7.21515 19.7918L8.4506 18.9976C8.80949 19.18 9.18205 19.3345 9.56401 19.4593L9.8759 20.894C9.93158 21.1503 10.1586 21.3332 10.4209 21.3332H13.5817C13.844 21.3332 14.071 21.1503 14.1267 20.894L14.4386 19.4593C14.8206 19.3345 15.1931 19.18 15.552 18.9976L16.7875 19.7918C17.0082 19.9337 17.298 19.9026 17.4834 19.717L19.7185 17.482C19.9039 17.2966 19.9351 17.0067 19.7933 16.786L18.999 15.5505C19.1815 15.1917 19.336 14.8191 19.4607 14.4371L20.8954 14.1252C21.1518 14.0694 21.3346 13.8426 21.3346 13.5802V10.4195C21.3346 10.1571 21.1518 9.93026 20.8954 9.87443V9.87443ZM15.3479 11.9998C15.3479 13.8451 13.8466 15.3465 12.0013 15.3465C10.156 15.3465 8.65468 13.8451 8.65468 11.9998C8.65468 10.1546 10.156 8.65322 12.0013 8.65322C13.8466 8.65322 15.3479 10.1546 15.3479 11.9998V11.9998Z"
              stroke="#429EFF"
            />
          </svg>
        </button> */}
                <div className={styles.tags}>
                    {tagsSelect.map((tag) => (
                        <button
                            onClick={() => removeTag(tag)}
                            key={tag}
                            className={styles.tag}
                        >
                            {tag}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="17"
                                viewBox="0 0 16 17"
                                fill="none"
                            >
                                <path
                                    d="M8.91615 8.50007L12.8099 4.60608C13.0633 4.35279 13.0633 3.94325 12.8099 3.68997C12.5567 3.43668 12.1471 3.43668 11.8939 3.68997L7.99994 7.58396L4.10614 3.68997C3.85274 3.43668 3.44333 3.43668 3.19005 3.68997C2.93665 3.94325 2.93665 4.35279 3.19005 4.60608L7.08385 8.50007L3.19005 12.3941C2.93665 12.6474 2.93665 13.0569 3.19005 13.3102C3.31628 13.4365 3.48225 13.5 3.6481 13.5C3.81395 13.5 3.9798 13.4365 4.10614 13.3102L7.99994 9.41618L11.8939 13.3102C12.0202 13.4365 12.1861 13.5 12.3519 13.5C12.5178 13.5 12.6836 13.4365 12.8099 13.3102C13.0633 13.0569 13.0633 12.6474 12.8099 12.3941L8.91615 8.50007Z"
                                    fill="#8496AF"
                                />
                            </svg>
                        </button>
                    ))}
                    {brandsSelect.map((brand) => (
                        <button
                            onClick={() => removeBrand(brand)}
                            key={brand}
                            className={styles.tag}
                        >
                            {brand}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="17"
                                viewBox="0 0 16 17"
                                fill="none"
                            >
                                <path
                                    d="M8.91615 8.50007L12.8099 4.60608C13.0633 4.35279 13.0633 3.94325 12.8099 3.68997C12.5567 3.43668 12.1471 3.43668 11.8939 3.68997L7.99994 7.58396L4.10614 3.68997C3.85274 3.43668 3.44333 3.43668 3.19005 3.68997C2.93665 3.94325 2.93665 4.35279 3.19005 4.60608L7.08385 8.50007L3.19005 12.3941C2.93665 12.6474 2.93665 13.0569 3.19005 13.3102C3.31628 13.4365 3.48225 13.5 3.6481 13.5C3.81395 13.5 3.9798 13.4365 4.10614 13.3102L7.99994 9.41618L11.8939 13.3102C12.0202 13.4365 12.1861 13.5 12.3519 13.5C12.5178 13.5 12.6836 13.4365 12.8099 13.3102C13.0633 13.0569 13.0633 12.6474 12.8099 12.3941L8.91615 8.50007Z"
                                    fill="#8496AF"
                                />
                            </svg>
                        </button>
                    ))}
                    {categoriesSelect.map((category) => (
                        <button
                            onClick={() => removeCategory(category)}
                            key={category}
                            className={styles.tag}
                        >
                            {category}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="17"
                                viewBox="0 0 16 17"
                                fill="none"
                            >
                                <path
                                    d="M8.91615 8.50007L12.8099 4.60608C13.0633 4.35279 13.0633 3.94325 12.8099 3.68997C12.5567 3.43668 12.1471 3.43668 11.8939 3.68997L7.99994 7.58396L4.10614 3.68997C3.85274 3.43668 3.44333 3.43668 3.19005 3.68997C2.93665 3.94325 2.93665 4.35279 3.19005 4.60608L7.08385 8.50007L3.19005 12.3941C2.93665 12.6474 2.93665 13.0569 3.19005 13.3102C3.31628 13.4365 3.48225 13.5 3.6481 13.5C3.81395 13.5 3.9798 13.4365 4.10614 13.3102L7.99994 9.41618L11.8939 13.3102C12.0202 13.4365 12.1861 13.5 12.3519 13.5C12.5178 13.5 12.6836 13.4365 12.8099 13.3102C13.0633 13.0569 13.0633 12.6474 12.8099 12.3941L8.91615 8.50007Z"
                                    fill="#8496AF"
                                />
                            </svg>
                        </button>
                    ))}
                    {articlesSelect.map((article) => (
                        <button
                            onClick={() => removeArticle(article)}
                            key={article}
                            className={styles.tag}
                        >
                            {article}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="17"
                                viewBox="0 0 16 17"
                                fill="none"
                            >
                                <path
                                    d="M8.91615 8.50007L12.8099 4.60608C13.0633 4.35279 13.0633 3.94325 12.8099 3.68997C12.5567 3.43668 12.1471 3.43668 11.8939 3.68997L7.99994 7.58396L4.10614 3.68997C3.85274 3.43668 3.44333 3.43668 3.19005 3.68997C2.93665 3.94325 2.93665 4.35279 3.19005 4.60608L7.08385 8.50007L3.19005 12.3941C2.93665 12.6474 2.93665 13.0569 3.19005 13.3102C3.31628 13.4365 3.48225 13.5 3.6481 13.5C3.81395 13.5 3.9798 13.4365 4.10614 13.3102L7.99994 9.41618L11.8939 13.3102C12.0202 13.4365 12.1861 13.5 12.3519 13.5C12.5178 13.5 12.6836 13.4365 12.8099 13.3102C13.0633 13.0569 13.0633 12.6474 12.8099 12.3941L8.91615 8.50007Z"
                                    fill="#8496AF"
                                />
                            </svg>
                        </button>
                    ))}
                    {companiesSelect.map((company) => (
                        <button
                            onClick={() => removeCompany(company)}
                            key={company}
                            className={styles.tag}
                        >
                            {company}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="17"
                                viewBox="0 0 16 17"
                                fill="none"
                            >
                                <path
                                    d="M8.91615 8.50007L12.8099 4.60608C13.0633 4.35279 13.0633 3.94325 12.8099 3.68997C12.5567 3.43668 12.1471 3.43668 11.8939 3.68997L7.99994 7.58396L4.10614 3.68997C3.85274 3.43668 3.44333 3.43668 3.19005 3.68997C2.93665 3.94325 2.93665 4.35279 3.19005 4.60608L7.08385 8.50007L3.19005 12.3941C2.93665 12.6474 2.93665 13.0569 3.19005 13.3102C3.31628 13.4365 3.48225 13.5 3.6481 13.5C3.81395 13.5 3.9798 13.4365 4.10614 13.3102L7.99994 9.41618L11.8939 13.3102C12.0202 13.4365 12.1861 13.5 12.3519 13.5C12.5178 13.5 12.6836 13.4365 12.8099 13.3102C13.0633 13.0569 13.0633 12.6474 12.8099 12.3941L8.91615 8.50007Z"
                                    fill="#8496AF"
                                />
                            </svg>
                        </button>
                    ))}
                </div>
                {
                    data?.values[0] ?
                        <div className={styles.table}>
                            <div className={styles.tableTop}>
                                <div className={styles.product}>Товар</div>
                                <div className={styles.metrics}>
                                    Метрики
                                    <button
                                        onClick={() => setMetricFIlter(true)}
                                        className={styles.filter}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            viewBox="0 0 16 16"
                                            fill="none"
                                        >
                                            <path
                                                d="M15.3741 2.01066H5.84164C5.57957 1.23228 4.84334 0.669922 3.97772 0.669922C3.11211 0.669922 2.37588 1.23228 2.11381 2.01066H0.625868C0.280222 2.01066 0 2.29088 0 2.63652C0 2.98217 0.280222 3.26239 0.625868 3.26239H2.11385C2.37592 4.04076 3.11215 4.60312 3.97776 4.60312C4.84338 4.60312 5.57961 4.04076 5.84168 3.26239H15.3741C15.7198 3.26239 16 2.98217 16 2.63652C16 2.29088 15.7197 2.01066 15.3741 2.01066ZM3.97772 3.35139C3.58355 3.35139 3.26286 3.03069 3.26286 2.63652C3.26286 2.24235 3.58355 1.92166 3.97772 1.92166C4.37189 1.92166 4.69259 2.24235 4.69259 2.63652C4.69259 3.03069 4.37189 3.35139 3.97772 3.35139Z"
                                                fill={`${metricsSaved.length > 0 ? '#429EFF' : '#8496AF'}`}
                                            />
                                            <path
                                                d="M15.3741 7.37394H13.8861C13.624 6.59557 12.8877 6.0332 12.0222 6.0332C11.1566 6.0332 10.4204 6.59557 10.1583 7.37394H0.625868C0.280222 7.37394 0 7.65416 0 7.99981C0 8.34545 0.280222 8.62567 0.625868 8.62567H10.1583C10.4204 9.40405 11.1566 9.96641 12.0222 9.96641C12.8878 9.96641 13.6241 9.40405 13.8861 8.62567H15.3741C15.7198 8.62567 16 8.34545 16 7.99981C16 7.65416 15.7197 7.37394 15.3741 7.37394ZM12.0222 8.71467C11.628 8.71467 11.3073 8.39398 11.3073 7.99981C11.3073 7.60563 11.628 7.28494 12.0222 7.28494C12.4164 7.28494 12.7371 7.60563 12.7371 7.99981C12.7371 8.39398 12.4164 8.71467 12.0222 8.71467Z"
                                                fill={`${metricsSaved.length > 0 ? '#429EFF' : '#8496AF'}`}
                                            />
                                            <path
                                                d="M15.3741 12.7362H8.52315C8.26108 11.9579 7.52485 11.3955 6.65923 11.3955C5.79361 11.3955 5.05739 11.9579 4.79531 12.7362H0.625868C0.280222 12.7362 0 13.0165 0 13.3621C0 13.7078 0.280222 13.988 0.625868 13.988H4.79531C5.05739 14.7663 5.79361 15.3287 6.65923 15.3287C7.52485 15.3287 8.26108 14.7663 8.52315 13.988H15.3741C15.7198 13.988 16 13.7078 16 13.3621C16 13.0165 15.7197 12.7362 15.3741 12.7362ZM6.65923 14.077C6.26506 14.077 5.94437 13.7563 5.94437 13.3622C5.94437 12.968 6.26506 12.6473 6.65923 12.6473C7.0534 12.6473 7.3741 12.9679 7.3741 13.3621C7.3741 13.7563 7.0534 14.077 6.65923 14.077Z"
                                                fill={`${metricsSaved.length > 0 ? '#429EFF' : '#8496AF'}`}
                                            />
                                        </svg>
                                    </button>
                                    {metricFilter && (
                                        <>
                                            <span
                                                onClick={() => setMetricFIlter(false)}
                                                className={styles.filterCloseBg}
                                            />
                                            <div className={styles.metricFilter}>
                                                {data?.values[0].metrics.metrics_names.map((item) => (
                                                    <div key={item} className={styles.metricFilterItem}>
                                                        <button
                                                            onClick={() =>
                                                                setDisabledMetric(
                                                                    disabledMetric.includes(item)
                                                                        ? [
                                                                            ...disabledMetric.filter(
                                                                                (temp) => temp !== item
                                                                            ),
                                                                        ]
                                                                        : [...disabledMetric, item]
                                                                )
                                                            }
                                                            className={`${styles.checkbox} ${!disabledMetric.includes(item) ? styles.check : ''
                                                                }`}
                                                        >
                                                            {!disabledMetric.includes(item) && (
                                                                <svg
                                                                    id="noCloseList"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="14"
                                                                    height="11"
                                                                    viewBox="0 0 14 11"
                                                                    fill="none"
                                                                >
                                                                    <path
                                                                        id="noCloseList"
                                                                        d="M5 10.5L0 5.54L1.59 4L5 7.35L12.41 0L14 1.58L5 10.5Z"
                                                                        fill="white"
                                                                    />
                                                                </svg>
                                                            )}
                                                        </button>
                                                        <p>{item}</p>
                                                    </div>
                                                ))}
                                                <button
                                                    className={styles.metricFilterChange}
                                                    onClick={saveMetrics}
                                                >
                                                    Применить
                                                </button>
                                            </div>
                                        </>
                                    )}
                                </div>
                                <div
                                    className={styles.xScrollWrap}
                                    onScroll={onMainScroll}
                                    id="scrollableOfMain"
                                >
                                    {data?.dates?.map((date, index) => (
                                        <div key={date + index} className={styles.date}>
                                            {date}
                                        </div>
                                    ))}
                                </div>
                                <div className={styles.average}>
                                    Среднее значение
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="14"
                                        height="10"
                                        viewBox="0 0 14 10"
                                        fill="none"
                                    >
                                        <path
                                            d="M0 0.75C0 0.551088 0.0790177 0.360322 0.21967 0.21967C0.360322 0.0790175 0.551088 0 0.75 0H13.25C13.4489 0 13.6397 0.0790175 13.7803 0.21967C13.921 0.360322 14 0.551088 14 0.75C14 0.948912 13.921 1.13968 13.7803 1.28033C13.6397 1.42098 13.4489 1.5 13.25 1.5H0.75C0.551088 1.5 0.360322 1.42098 0.21967 1.28033C0.0790177 1.13968 0 0.948912 0 0.75ZM2.5 4.75C2.5 4.55109 2.57902 4.36032 2.71967 4.21967C2.86032 4.07902 3.05109 4 3.25 4H10.75C10.9489 4 11.1397 4.07902 11.2803 4.21967C11.421 4.36032 11.5 4.55109 11.5 4.75C11.5 4.94891 11.421 5.13968 11.2803 5.28033C11.1397 5.42098 10.9489 5.5 10.75 5.5H3.25C3.05109 5.5 2.86032 5.42098 2.71967 5.28033C2.57902 5.13968 2.5 4.94891 2.5 4.75ZM5.75 8C5.55109 8 5.36032 8.07902 5.21967 8.21967C5.07902 8.36032 5 8.55109 5 8.75C5 8.94891 5.07902 9.13968 5.21967 9.28033C5.36032 9.42098 5.55109 9.5 5.75 9.5H8.25C8.44891 9.5 8.63968 9.42098 8.78033 9.28033C8.92098 9.13968 9 8.94891 9 8.75C9 8.55109 8.92098 8.36032 8.78033 8.21967C8.63968 8.07902 8.44891 8 8.25 8H5.75Z"
                                            fill="#8496AF"
                                        />
                                    </svg>
                                </div>
                            </div>
                            <div className={styles.yScrollWrap} onScroll={(e) => {

                                if ((e.target.scrollTop > (e.target.scrollHeight - e.target.clientHeight - 500)) && !infoLoading && hasNext) {
                                    setInfoLoading(true);
                                    setOffset(offset + 3);
                                }
                            }}>
                                {data?.values?.map((item, index) => (
                                    <ContentItem
                                        allTags={tags}
                                        onScroll={onMainScroll}
                                        key={index}
                                        item={item}
                                    />
                                ))}
                            </div>
                        </div>
                        :
                        <p style={{ marginTop: '30px' }} className={styles.empty}>Нет данных</p>
                }
                <div
                    className={styles.mainScroll}
                    style={{ maxWidth: `${160 * data?.dates?.length - 50}px` }}
                    onScroll={onMainScroll}
                    id="scrollableOfMain"
                >
                    <span style={{ width: `${160 * data?.dates?.length - 50}px` }} />
                </div>
            </div>
        </section>
    );
}

export default Advertisement;
