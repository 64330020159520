// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".X4AnnHOs2J8mElTMV3Mb {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  width: 340px;\n  height: 106px;\n  border-radius: 8px;\n  box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.2);\n  background-color: #fff;\n}\n.X4AnnHOs2J8mElTMV3Mb .JeCCxlVEfLHuS53PxXo0 {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 64px;\n  height: 100%;\n  border-radius: 8px 0 0 8px;\n}\n.X4AnnHOs2J8mElTMV3Mb .JeCCxlVEfLHuS53PxXo0.Z6wF9bEw7hpE6sZ42F7s {\n  background-color: #1AB889;\n}\n.X4AnnHOs2J8mElTMV3Mb .JeCCxlVEfLHuS53PxXo0.gz8MyN1DMeUFOhQZpX4n {\n  background-color: #FF5E5B;\n}\n.X4AnnHOs2J8mElTMV3Mb .JeCCxlVEfLHuS53PxXo0.vgOQluLCYWmeeivw3Mzw {\n  background-color: #8496AF;\n}", "",{"version":3,"sources":["webpack://./src/static/css/popupNotification.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,YAAA;EACA,aAAA;EACA,kBAAA;EACA,0CAAA;EACA,sBAAA;AACJ;AACI;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EAEA,WAAA;EACA,YAAA;EACA,0BAAA;AAAR;AAEQ;EACI,yBAAA;AAAZ;AAGQ;EACI,yBAAA;AADZ;AAIQ;EACI,yBAAA;AAFZ","sourcesContent":[".main {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    width: 340px; \n    height: 106px; \n    border-radius: 8px;    \n    box-shadow: 1px 3px 4px rgba(0,0,0,.2);\n    background-color: #fff;\n\n    .icon{\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        \n        width: 64px;\n        height: 100%;\n        border-radius: 8px 0 0 8px;\n\n        &.success{\n            background-color: #1AB889;\n        }\n\n        &.danger{\n            background-color: #FF5E5B;\n        }\n\n        &.info{\n            background-color: #8496AF;\n        }\n\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "X4AnnHOs2J8mElTMV3Mb",
	"icon": "JeCCxlVEfLHuS53PxXo0",
	"success": "Z6wF9bEw7hpE6sZ42F7s",
	"danger": "gz8MyN1DMeUFOhQZpX4n",
	"info": "vgOQluLCYWmeeivw3Mzw"
};
export default ___CSS_LOADER_EXPORT___;
