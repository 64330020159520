import React, { useState, useEffect } from 'react';
import style from '../Financing.module.scss';
import cx from 'classnames';
import { LoansStore } from '../../../store/Financing/LoansStore';
import { observer } from 'mobx-react-lite';
import LoansTable from './LoansTable.jsx';
import LoansSummary from './LoansSummary.jsx';
import loansStyle from './loansStyle.module.scss';
import { formatNumber } from '../../../static/js/commonFunc';
import CreateModal from './CreateModal/index.jsx';

const Loans = observer(() => {
    const [state] = useState(() => new LoansStore());

    const [showTable, setShowTable] = useState(false);
    const [activeButton, setActiveButton] = useState('summary');

    const handleSummaryClick = () => {
        setShowTable(false);
        setActiveButton('summary');
    };

    const handleTableClick = () => {
        setShowTable(true);
        setActiveButton('table');
    };

    const [createModal, setCreateModal] = useState(false);
    const [modalData, setModalData] = useState([]);

    const remove = (id) => {
        console.log('delete ', id);
    };

    return (
        <main>
            <div className={style.title}>Займы</div>
            {createModal && <CreateModal data={modalData} onClose={() => setCreateModal(false)} />}
            {state.showData ? (
                <>
                    <section className={style.section}>
                        <div className={loansStyle.addLoansContainer}>
                            <div className={loansStyle.containerBtnTableView}>
                                <button
                                    onClick={handleSummaryClick}
                                    className={
                                        activeButton === 'summary' ? loansStyle.active : ''
                                    }
                                >
                                    Сводка
                                </button>
                                <button
                                    onClick={handleTableClick}
                                    className={activeButton === 'table' ? loansStyle.active : ''}
                                >
                                    Таблица
                                </button>
                            </div>
                            {showTable ? (
                                <></>
                            ) : (
                                <div className={loansStyle.totalSumInfo}>
                                    <div className="">
                                        Выплатили всего: {formatNumber(state.total_paid)}
                                    </div>
                                    <div className="">
                                        Итого осталось: {state.total_remaining ?
                                            formatNumber(state.total_remaining) :
                                            formatNumber(state.total_loan_amount - state.total_paid)}
                                    </div>
                                </div>
                            )}

                            <button
                                className={loansStyle.addLoans}
                                onClick={() => setCreateModal(true)}
                            >
                                Добавить кредит/займ
                            </button>
                        </div>
                        {showTable ?
                            <LoansTable />
                            :
                            <LoansSummary
                                showModal={() => setCreateModal(true)}
                                remove={(value) => remove(value)}
                                setModalData={(value) => setModalData(value)}
                            />
                        }
                    </section>
                </>
            ) : (
                <section className={cx(loansStyle['loans-page'])}>
                    <div className={loansStyle['loans-page__empty']}>
                        <div className={loansStyle['loans-page__empty_discriptions']}>
                            Нет ни одного кредита
                        </div>
                        <div className={loansStyle['loans-page__empty_block-added']}>
                            <button onClick={() => setCreateModal(true)}>
                                <svg
                                    width="25"
                                    height="24"
                                    viewBox="0 0 25 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M3.10539 13.1054H11.3946V21.3946C11.3946 21.6878 11.5111 21.9689 11.7184 22.1762C11.9257 22.3835 12.2068 22.5 12.5 22.5C12.7932 22.5 13.0743 22.3835 13.2816 22.1762C13.4889 21.9689 13.6054 21.6878 13.6054 21.3946V13.1054H21.8946C22.1878 13.1054 22.4689 12.9889 22.6762 12.7816C22.8835 12.5743 23 12.2932 23 12C23 11.7068 22.8835 11.4257 22.6762 11.2184C22.4689 11.0111 22.1878 10.8946 21.8946 10.8946H13.6054V2.60539C13.6054 2.31222 13.4889 2.03106 13.2816 1.82376C13.0743 1.61646 12.7932 1.5 12.5 1.5C12.2068 1.5 11.9257 1.61646 11.7184 1.82376C11.5111 2.03106 11.3946 2.31222 11.3946 2.60539V10.8946H3.10539C2.81223 10.8946 2.53107 11.0111 2.32376 11.2184C2.11646 11.4257 2 11.7068 2 12C2 12.2932 2.11646 12.5743 2.32376 12.7816C2.53107 12.9889 2.81223 13.1054 3.10539 13.1054Z"
                                        fill="#429EFF"
                                    />
                                </svg>
                                <span>Добавить кредит/займ</span>
                            </button>
                        </div>
                    </div>
                </section>
            )}
        </main>
    );
});

export default Loans;
