export const getFilterOptions = (key) => {

    switch (true) {
        case (key === 'status'):
            return [
                { name: 'Заказ выполнен', id: 1, searchParam: 'name', style: 'success' },
                { name: 'Частично выполнен', id: 2, searchParam: 'name', style: 'warning' },
                { name: 'Не выполнен', id: 3, searchParam: 'name', style: 'error' },
                { name: 'В пути', id: 4, searchParam: 'name', style: 'onWay' },
            ]

        default:
            return null

    }

}