import React, { createRef, useRef } from 'react';
import { observer } from 'mobx-react-lite';
//import RulesStore from '../../../store/Financing/RulesStore.js';
import BodyTableLine from '../../../components/custom/BodyTableLine.jsx';
import BodyTableLineItem from '../../../components/custom/BodyTableLineItem.jsx';
import style from '../Financing.module.scss';
import ArticleStore from '../../../store/Financing/ArticleStore';
import cx from 'classnames';

const RulesBody = observer(({ data, setItem }) => {
    const obj = {
        income: 'Приход',
        expense: 'Расход',
    };
    return (
        <div className={`${style.rulesWrap} ${style.mini}`}>
            {data.fields?.map((field, index) => (
                <div className={cx(style.CashboxLine, style.rulesItem)} key={index}>
                    <BodyTableLine
                        items="3"
                        event={() => {
                            setItem(field);
                        }}
                    >
                        <BodyTableLineItem
                            additionalClass={'operationType'}
                            value={obj[field.operation_type]}
                        />

                        <BodyTableLineItem
                            value={
                                <p
                                    title={`Если у операции: ${
                                        field.contragent_name_value
                                            ? `Контрагент - ${field.contragent_name_value}` +
                        (field.amount_value
                            ? field.payment_purpose_value
                                ? ' +2 усл'
                                : ' +1 усл'
                            : '')
                                            : field.amount_value
                                                ? 'Сумма - ' +
                        parseInt(field.amount_value).toFixed(0) +
                        (field.payment_purpose_value ? ' +1 усл' : '')
                                                : field.payment_purpose_value
                                                    ? `назначение платежа - ${field.payment_purpose_value}`
                                                    : ''
                                    }`}
                                    className={style.textOverflow}
                                >
                                    <span
                                        style={{ fontWeight: '700', writingMode: 'horizontal-tb' }}
                                    >
                                        Если у операции:{' '}
                                    </span>
                                    {`${
                                        field.contragent_name_value
                                            ? `Контрагент - ${field.contragent_name_value}` +
                        (field.amount_value
                            ? field.payment_purpose_value
                                ? ' +2 усл'
                                : ' +1 усл'
                            : '')
                                            : field.amount_value
                                                ? 'Сумма - ' +
                        parseInt(field.amount_value).toFixed(0) +
                        (field.payment_purpose_value ? ' +1 усл' : '')
                                                : field.payment_purpose_value
                                                    ? `Назначение платежа - ${field.payment_purpose_value}`
                                                    : ''
                                    }`}
                                </p>
                            }
                        />

                        <BodyTableLineItem
                            value={
                                <p>
                                    <span
                                        style={{ fontWeight: '700', writingMode: 'horizontal-tb' }}
                                    >
                                        Тогда изменить:
                                    </span>{' '}
                                    {field.article_name}
                                </p>
                            }
                        />
                    </BodyTableLine>
                </div>
            ))}
        </div>
    );
});

export default RulesBody;
