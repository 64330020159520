import React from "react";
import style from '../../../../Purchase.module.scss'
import FilterItem from "./item.jsx";


const FilterList = ({ data, current, setCurrent, remove }) => {

    return (
        (data.length > 0) &&

        <div className={style.bottomRow}>
            {
                data.map((preset, presetIndex) =>

                    <FilterItem
                        key={presetIndex}
                        data={preset}
                        current={current}
                        setCurrent={setCurrent}
                        remove={remove}
                    />

                )
            }
        </div>
    )
}

export default FilterList