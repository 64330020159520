import React from "react";
import TableLine from "./line.jsx";

const TableRow = ({ data, columns, headRef = false, itemLink, removeItem }) => {

    return (

        data.map((item, index) =>
            <TableLine
                data={item}
                key={index}
                columns={columns}
                itemLink={itemLink}
                headRef={headRef}
                removeItem={removeItem}
            />
        )

    )

}

export default TableRow
