import React from 'react'

const PresetIcon = _ => {

    return (
        <svg viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 1H2C1.44772 1 1 1.44772 1 2V18L7 14L13 18V2C13 1.44772 12.5523 1 12 1Z" fill="#8496AF" stroke="#8496AF" strokeWidth="1.5" />
        </svg>
    )

}

export default PresetIcon


