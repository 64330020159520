import React, { useState } from "react";
import style from "../../../../Purchase.module.scss";
import ExecutionDescription from "../../../../../../feature/ExecutionDescription/index.jsx";

const PlanColumn = ({ plan, fact }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });

    const result = (fact / plan) * 100;

    const handleMouseEnter = (e) => {
        const rect = e.target.getBoundingClientRect(); // Координаты элемента
        setTooltipPosition({
            top: rect.top + window.scrollY + rect.height + 10,
            left: rect.left + window.scrollX,
        });
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <div className={style.plan}>
            <div className={style.value}>
                <span>{fact}/{plan}</span>
                <span
                    className={result < 100 ? style.warning : style.success}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    {result.toFixed(2)}%
                </span>
            </div>

            <ExecutionDescription
                isVisible={isHovered}
                position={tooltipPosition}
                plan={plan}
                fact={fact}
            />
        </div>
    );
};

export default PlanColumn;
