import React, { useRef } from "react";
import style from '../../../Purchase.module.scss'
import cx from 'classnames';
import FilterIcon from '../../../../../shared/ui/icons/Filter.jsx';

const TableHead = ({ columns, headRef, setSortingBy }) => {

    const handleSort = (key) => {
        setSortingBy((prev) => {
            if (prev.key !== key) {
                return { key, value: 'desc' };
            } else if (prev.value === 'desc') {

                return { key, value: 'asc' };
            } else {
                return { key: '', value: '' };
            }
        });
    };

    return (
        <div className={cx(style.row, style.columnName)}>
            <div className={style.xScroll} ref={headRef}>

                {Object.values(columns).map((column, index) => (
                    column.isShow &&
                    <div key={index} style={{ minWidth: column.width }} className={cx(style.column, style.header, column.type === "id" && style.center)}>
                        <span>{column.label}</span>
                        {!column.noSort &&
                            <span onClick={() => handleSort(column.value)}>
                                <FilterIcon />
                            </span>
                        }
                    </div>
                ))}

                <div style={{ minWidth: '15px' }} className={cx(style.column, style.header)}></div>

            </div>


        </div>

    )

}

export default TableHead