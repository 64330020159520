import React, { useState, useRef, useEffect } from 'react'
import style from './FinancingReport.module.scss';
import cx from 'classnames';
import TableRow from './Row.jsx';
import { formatNumber2 } from '../../static/js/commonFunc';
import ShowMore from '../../shared/ui/icons/ShowMore.jsx';
import useSyncScroll from '../../static/js/useSyncScroll.jsx';

const TableRowItem = ({ data, loading = false, headRef = false, level }) => {

    const elementRef = useRef(null);
    const [showChild, toggleChild] = useState(false)    

    const handleAction = (canDo = false) => {
        if (canDo) {
            toggleChild(prev => !prev)
        }
    }

    const childStyle = {
        paddingLeft: (10 * level) + 'px'
    };

    const backGround = `rgb(230, 230, 230, ${level > 0 ? (level * 2) / 10 : 0})`

    const fontSize = (level>3) ? '12px' : '14px';

    const hasChild = Object.entries(data[1]['nestedEl']).length    

    useSyncScroll(headRef, elementRef, { horizontal: true });  
    
    return (
        <>
            <div className={style.row} >
                <div
                    className={cx(style.title, style.label)}
                    style={{
                        cursor: hasChild
                            ? 'pointer'
                            : 'default',
                        background: backGround,
                        borderBottom: '1px solid #dce7f1',
                        fontSize: fontSize
                    }}
                    onClick={() => handleAction(hasChild)}
                >

                    <span style={childStyle}>{data[0]}</span>

                    {
                        hasChild ?
                            <span className={cx(style.showMoreRow, showChild ? style.active : null)}>
                                <ShowMore />
                            </span>
                            :
                            null
                    }

                </div>

                <div
                    className={cx(style['flex'], style['fd-r'], style.xScroll, style.scrolled)}
                    ref={elementRef}
                    style={{
                        overflowX: loading ? 'hidden' : '',
                        alignItems: 'center',
                        borderBottom: '1px solid #dce7f1',
                        background: backGround
                    }}
                >

                    {data[1].value.map((item, index) => (
                        <div key={index} className={style.column}>
                            <span>{formatNumber2(item)}</span>
                        </div>
                    ))}

                </div>
            </div>

            {
                hasChild ?
                    showChild ?
                        <TableRow
                            data={data[1]['nestedEl']}
                            level={level + 1}
                            headRef={headRef}
                        />
                        :
                        null
                    :
                    null
            }

        </>
    )
}

export default TableRowItem