import React, { useState, useEffect, useRef } from "react";
import style from '../../../Purchase.module.scss'
import RigthArrow from "../../../../../shared/ui/icons/RigthArrow.jsx";
import RecycleIcon from "../../../../../shared/ui/icons/Recycle.jsx";
import { deleteConfirmAlert } from "../../../../../static/js/commonFunc";

const ItemAction = ({ remove, id }) => {

    const elementref = useRef(null);

    const [show, toggleShow] = useState(false)

    useEffect(() => {

        const handleOutsideClick = (e) => {
            if (elementref.current && !elementref.current.contains(e.target)) {
                toggleShow(false)
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    return (
        <>
            {
                show &&
                <div ref={elementref} className={style.moreModal}>
                    
                    <button
                        onClick={() => deleteConfirmAlert(() => remove(id))}
                        className={style.remove}
                    >
                        <RecycleIcon /> Удалить
                    </button>
                    <button onClick={() => toggleShow(false)} className={style.close}><RigthArrow /></button>
                </div>
            }
            <span onClick={() => toggleShow(prev => !prev)}>...</span>
        </>
    )

}

export default ItemAction;