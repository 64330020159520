import React from 'react'
import style from '../../../../../Purchase.module.scss'
import SecondFilter from '../../../../../../../shared/ui/icons/SecondFilter.jsx';
import FilterIcon from '../../../../../../../shared/ui/icons/Filter.jsx';

import cx from 'classnames';

const Head = ({ type, setSortingBy }) => {

    const handleSort = (key) => {
        setSortingBy((prev) => {
            if (prev.key !== key) {                
                return { key, value: 'desc' };
            } else if (prev.value === 'desc') {
                
                return { key, value: 'asc' };
            } else {                
                return { key: '', value: '' };
            }
        });
    };

    return (

        <div className={style.row}>
            <div className={style.xScroll}>

                {
                    (type === 'acceptance') &&
                    <div style={{ width: '80px' }} className={cx(style.column, style.header, style.center)}>
                        <span>№</span>
                    </div>
                }

                {
                    (type === 'acceptance') &&
                    <div style={{ width: '80px' }} className={cx(style.column, style.header, style.center)}>
                        <span>№ УПД</span>
                    </div>
                }

                <div style={{ width: '115px' }} className={cx(style.column, style.header, (type === 'acceptance') ? style.date : style.center)}>
                    <span>Дата</span>
                    <span onClick={() => handleSort('created_at')}>
                        {(type === 'acceptance') ? <FilterIcon /> : <SecondFilter />}
                    </span>
                </div>

                <div style={{ width: (type === 'acceptance') ? '150px' : '190px' }} className={cx(style.column, style.header, style.end)}>
                    <span>Сумма</span>
                    <span onClick={() => handleSort('sum')}>
                        {(type === 'acceptance') ? <FilterIcon /> : <SecondFilter />}
                    </span>
                </div>

                {
                    (type === 'acceptance') ?
                        <div style={{ width: '250px' }} className={cx(style.column, style.header)}>
                            <span>Склад</span>
                            <span onClick={() => handleSort('warehouse')}>
                                <FilterIcon />
                            </span>
                        </div>
                        :
                        <div style={{ width: '250px' }} className={cx(style.column, style.header, style.start)}>
                            <span>Контрагент</span>
                            <span onClick={() => handleSort('counterparty')}>
                                <SecondFilter />
                            </span>
                        </div>
                }


                {
                    (type === 'pays') &&
                    <div style={{ width: '300px' }} className={cx(style.column, style.header, style.start)}>
                        <span>Статья</span>
                        <span onClick={() => handleSort('article')}>
                            <SecondFilter />
                        </span>
                    </div>
                }



                <div style={{ width: (type === 'acceptance') ? '485px' : '315px'  }} className={cx(style.column, style.header, style.start)}>
                    <span>Комментарий</span>
                </div>

            </div>
        </div>

    )
}

export default Head