import React, { useRef, useEffect, useState } from 'react'
import style from './FinancingReport.module.scss';
import TableHead from './Header.jsx';
import TableRow from './Row.jsx';
import ScrollBar from '../../components/custom/ScrollBar/index.jsx';

const ReportTable = ({ dateList, dataList, loading, currentMonth, action }) => {

    const headRef = useRef(null);
    const bodyRef = useRef(null);     

    return (

        <section className={style.section}>

            {loading && <p className={style.loadingScreen}>Загрузка...</p>}

            <TableHead dateList={dateList} action={action} headRef={headRef} currentMonth={currentMonth} />

            <div className={style.body} >

                <div className={style.yScroll} ref={bodyRef}>

                    <TableRow data={dataList} loading={loading} headRef={headRef} />

                </div>

            </div>

            <ScrollBar targetRef={headRef} horizontal={true} params={{ left: 370, height: 10 }} />

            <ScrollBar targetRef={bodyRef} params={{top: 215, height: 700}} />

        </section>
    )
}

export default ReportTable