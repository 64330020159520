import React, { useState, useRef, useEffect } from 'react';
import style from '../../../Purchase.module.scss'
import SettingsIcon from '../../../../../shared/ui/icons/Settings.jsx';
import FilterSelection from './selection.jsx';
import TableSettings from './settings.jsx';
import { useNavigate } from 'react-router-dom';
import FilterList from './list/index.jsx';
import PlusIcon from '../../../../../shared/ui/icons/Plus.jsx';
import FilterIcon from '../../../../../shared/ui/icons/Filter.jsx';
import CloseIcon from '../../../../../shared/ui/icons/Close.jsx';
import cx from 'classnames';
import { API_URL, getData, postData, delData } from "../../../../../static/js/request";
import PresetAction from './presetAction.jsx';
import { popupNotification } from '../../../../../static/js/popupNotification.jsx';

const Filter = ({ filters, presetTable, setFilters, newItemLink, columns, editColumns, filterRef }) => {

    const [showSettings, toggleSettings] = useState(false)
    const [showFilters, toggleFilters] = useState(false)
    const [localFilters, setLocalFilters] = useState([])
    const [presetList, setList] = useState([])
    const [currentPreset, setCurrent] = useState(false)

    const getPresets = async () => {

        await getData(API_URL + '/api/v1/general/user/preset/').then((data) => {
            setList(data)
        });

    }

    const showSettingsButtonRef = useRef(null)

    const updateItem = (key, value, multiple) => {
        setLocalFilters((prevItems) => {
            const existingItem = prevItems.find((item) => item.key === key);

            if (existingItem) {
                return prevItems.map((item) => {
                    if (item.key === key) {
                        if (value === null) {
                            return {
                                ...item,
                                value: multiple ? [] : ''
                            };
                        } else if (multiple) {
                            const isValueExists = item.value.some((v) => v.id === value.id);
                            return {
                                ...item,
                                value: isValueExists
                                    ? item.value.filter((v) => v.id !== value.id)
                                    : [...item.value, value]
                            };
                        } else {
                            return {
                                ...item,
                                value
                            };
                        }
                    }
                    return item;
                });
            } else {
                return [
                    ...prevItems,
                    {
                        key,
                        value: value === null ? (multiple ? [] : '') : multiple ? [value] : value
                    }
                ];
            }
        });
    };

    const apply = () => {
        setFilters(localFilters)
        toggleFilters(false)
    }

    const clear = () => {
        setFilters([])
        toggleFilters(false)
    }

    const changeCurrent = (value) => {
        setCurrent(value)
        const selectedPreset = presetList.find((item) => item.id === value)
        setFilters(selectedPreset.filters)
    }

    const newPreset = async (name) => {

        const requestData = {
            name: name,
            table_name: presetTable,
            filters: localFilters
        }

        await postData(API_URL + '/api/v1/general/user/preset/', requestData)
            .then((response) => {
                popupNotification(response, 'create')
                setFilters(localFilters)
                toggleFilters(false)
            })

    }

    const deletePreset = async (id) => {

        await delData(API_URL + '/api/v1/general/user/preset/', { pk: id })
            .then((response) => {
                popupNotification(response, 'delete')
                getPresets()
            })

    }

    const navigate = useNavigate();

    useEffect(() => {
        getPresets()
    }, [])

    return (

        <div className={style.filter} ref={filterRef}>

            <div className={style.topRow}>

                <div className={style.left}>

                    <button onClick={() => toggleFilters(prev => !prev)} className={cx(style.filterButton, showFilters && style.active)}>
                        <span>Фильтр</span>
                        {showFilters ? <CloseIcon /> : <FilterIcon />}
                    </button>

                    {showFilters &&
                        <>
                            <PresetAction
                                create={(value) => newPreset(value)}
                            />

                            <button onClick={() => apply()} className={cx(style.topButton, style.chain)}>
                                <span>Применить</span>
                            </button>
                            <button onClick={() => clear()} className={cx(style.topButton, style.filterButton)}>
                                <span>Очистить</span>
                            </button>
                        </>
                    }

                </div>

                <div className={style.rigth}>

                    <button onClick={() => navigate(newItemLink.value)} className={style.create}>
                        <PlusIcon />
                        <span>{newItemLink.name}</span>
                    </button>

                    <button ref={showSettingsButtonRef} onClick={() => toggleSettings(prev => !prev)} className={style.settings}>
                        <SettingsIcon />
                    </button>

                </div>

            </div>

            <FilterSelection
                isShow={showFilters}
                columns={columns}
                filters={localFilters}
                setFilters={updateItem}
            />

            <FilterList
                data={presetList}
                current={currentPreset}
                setCurrent={(value) => changeCurrent(value)}
                remove={(value) => deletePreset(value)}
            />

            <TableSettings
                columns={columns}
                editColumns={editColumns}
                show={showSettings}
                showSettings={(value) => toggleSettings(value)}
                showSettingsButtonRef={showSettingsButtonRef}
            />

        </div>
    )
}

export default Filter