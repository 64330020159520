import React from "react";
import { Store } from 'react-notifications-component';
import ErrorIcon from "../../shared/ui/icons/Error.jsx";
import WaitIcon from "../../shared/ui/icons/Wait.jsx";
import SuccessIcon from "../../shared/ui/icons/Success.jsx";
import style from '../css/popupNotification.module.scss'
import cx from 'classnames';

const getIcon = (status) => {
    switch (status) {
        case 'success':
            return <SuccessIcon />
        case 'danger':
            return <ErrorIcon />
        case 'info':
            return <WaitIcon />
        default:
            break;
    }
}

const getActionText = (value,) => {
    switch (value) {
        case 'create':
            return 'создан';
        case 'update':
            return 'обновлен';
        case 'delete':
            return 'удален';
        default:
            break;

    }
}

const getNotificationParams = (response, type) => {

    let status, title, text

    if (type === 'info') {
        status = 'info';
        title = 'Ожидание';
        text = 'Загрузка данных';
    }

    else {

        switch (true) {
            case (response.status == 200 || response.status == 201 || response.status == 204):
                status = 'success';
                title = 'Успех';
                text = `Документ успешно ${getActionText(type)}.`;
                break;
            case (response.status == 400):
                status = 'danger';
                title = 'Ошибка';
                text = `Ошибка при ${getActionText(type)}ии документа.`;
                break;
            case (response.status == 401):
                status = 'danger';
                title = 'Ошибка';
                text = `Ошибка при авторизации`;
                break;
            case (response.status == 403):
                status = 'danger';
                title = 'Ошибка';
                text = `Доступ к операции запрещен.`;
                break;
            case (response.status == 404):
                status = 'danger';
                title = 'Ошибка';
                text = `Русурс не найден.`;
                break;
            case (response.status == 500):
                status = 'danger';
                title = 'Ошибка';
                text = `Внутренняя ошибка сервера.`;
                break;
        }

    }

    return { 'status': status, 'title': title, 'text': text }

}



export const popupNotification = (response, type) => {

    const { status, title, text } = getNotificationParams(response, type)

    Store.addNotification({
        insert: 'bottom',
        container: 'bottom-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        title: title,
        message: text,
        type: status,
        dismiss: {
            duration: 5000
        },
        content: () => (
            <div className={style.main}>
                <div className={cx(style.icon, style[status])}>
                    {getIcon(status)}
                </div>
                <div style={{ marginLeft: '10px' }}>
                    <strong>{title}</strong>
                    <p>{text}</p>
                </div>

            </div>
        )

    });

}