import React, { useState, useRef } from "react";
import style from '../../Purchase.module.scss'
import { useNavigate } from "react-router-dom";
import BackArrowIcon from "../../../../shared/ui/components/BackArrowIcon/index.jsx";
import Params from "./params/index.jsx";
import Products from "./products/index.jsx";
import Comment from "./comment/index.jsx";
import Confirm from "./confirn.jsx";
import cx from 'classnames';

const Item = ({ title, itemParams, setItemParams, itemAction }) => {

    const [showModal, setShowModal] = useState(false);

    const navigate = useNavigate();    

    return (

        <>
            <div className={style.itemTitle}>
                <span onClick={() => navigate(`/purchase/${(itemParams.itemType === 'purchase' ? 'orders' : itemParams.itemType)}`)}><BackArrowIcon /></span>
                <h1 className={style.title}>{title}</h1>
            </div>

            <main  className={cx(style.main, style.scrolled)}>

                <section className={style.item}>

                    <Params
                        itemParams={itemParams}
                        setItemParams={setItemParams}
                    />

                    <Products
                        itemParams={itemParams}
                        setItemParams={setItemParams}
                    />

                    <Comment
                        itemParams={itemParams}
                        setItemParams={setItemParams}
                        action={itemAction}
                    />

                </section>                

            </main>            

            {/*<Confirm
                show={showModal}
                toggle={setShowModal}
                action={itemAction}
                message={itemParams.dialogMessage}
            />*/}

        </>

    )
}

export default Item