import React, { useEffect, useRef, useState } from "react";
import style from './styles.modules.scss'
import cx from 'classnames';
import { Checkbox } from "@mui/material";
import DownArrow from "../../../shared/ui/icons/DownArrow.jsx";
import CloseIcon from "../../../shared/ui/icons/Close.jsx";

const MultiSelection = ({ data, defaultOption, action, value, needSearch }) => {

    const [isOpen, setIsOpen] = useState(false);
    const [filter, setFilter] = useState('')
    const selectRef = useRef();

    const filteredData = data.filter((item) => item.name?.toLowerCase().includes(filter?.toLowerCase()));

    useEffect(() => {

        const handleOutsideClick = (e) => {
            if (selectRef.current && !selectRef.current.contains(e.target)) {
                setIsOpen(false);
                setFilter('');
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);


    return (
        <div ref={selectRef} className={style.select} >

            <div className={cx(style.selectContent, isOpen ? style.active : '')}>

                <div className={cx(style.button, isOpen ? style.active : '')} onClick={() => {!isOpen && setIsOpen(true)}}>
                    <div>
                        {
                            isOpen ?
                                <input placeholder='Введите значение' className={style.filter} type="text" onChange={(e) => setFilter(e.target.value)} />
                                :
                                value?.length > 0 ?
                                    <span>Выбрано {value.length}</span>
                                    :
                                    <span>{defaultOption.name}</span>
                        }
                    </div>
                    <div className={style.icons}>

                        {value?.length > 0 &&
                            <div className={style.closeIcon} onClick={() => action(null)}>
                                <CloseIcon />
                            </div>
                        }

                        <DownArrow />
                    </div>
                </div>

                {isOpen && (
                    <div className={cx(style.optionList, isOpen ? style.active : '')}>
                        {filteredData.map((option, index) => (
                            <div

                                className={style.option}
                                key={index}
                                onClick={() => action(option)}
                            >

                                <Checkbox checked={value && value.some((item) => item.id ? (item.id === option.id) : item.name === option.name)} color="primary" />

                                <span>{option.name}</span>

                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    )
}

export default MultiSelection
