import React from "react";
import style from '../../../../../Purchase.module.scss'
import FilterSum from "./sum.jsx";
import FilterSelection from "./selection.jsx";
import FilterComment from "./comment.jsx";


const DocumentFilter = ({ filters, setFilters, type }) => {

    const getFilterValue = (type) => {
        const sorted = filters?.filter(item => item.key === type)
        return sorted[0]?.value || '';
    }

    return (
        <div className={style.filter}>

            <div className={style.left}>

                <FilterSum value={getFilterValue('amount')} action={setFilters} />

                {type === 'acceptance' ?
                    <FilterSelection value={getFilterValue('warehouse')} action={setFilters} column='warehouse' label='Склад' />
                    :
                    <FilterSelection value={getFilterValue('article')} action={setFilters} column='article' label='Статья' />
                }

            </div>

            <div className={style.rigth}>

                <FilterComment value={getFilterValue('comment')} action={setFilters} />

            </div>

        </div>
    )
}

export default DocumentFilter