import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { NewCustomInput } from '../../../../../components/custom/NewCustomInput/NewCustomInput.jsx';
import { API_URL, getData, postData } from '../../../../../static/js/request';
import sortBy from 'lodash.sortby';
import { validateDate } from '@mui/x-date-pickers/internals';

const InputWrap = (props) => {
    const [type, setType] = useState(props.type || 'password');

    return (
        <div className={styles.inputWrap} style={{ width: props.width }}>
            <div className={styles.wrapTop}>
                <p className={styles.inputTitle}>{props.title}</p>
                <button
                    onClick={() => navigator.clipboard.writeText(props.value)}
                    className={styles.copy}
                >
                    Скопировать
                </button>
            </div>
            <input
                style={{ width: '100%' }}
                type={type}
                value={props.value}
                onChange={(e) => props.onChange(e.target.value)}
                placeholder={'Добавьте API'}
                onBlur={props.onBlur}
                className={
                    props.status || props.status === null
                        ? props.value && props.value.length > 0
                            ? styles.success
                            : ''
                        : styles.error
                }
            />
            <button
                className={styles.visible}
                onClick={() => setType(type === 'password' ? 'text' : 'password')}
            >
                {type === 'password' ? (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                    >
                        <rect width="24" height="24" fill="white" />
                        <path
                            d="M3 10C3.85861 11.0414 4.87009 11.9467 6 12.685M6 12.685C7.21621 13.4793 8.57444 14.0311 10 14.31C11.3212 14.5641 12.6788 14.5641 14 14.31C15.4256 14.0311 16.7838 13.4793 18 12.685M6 12.685L4.5 14.5M21 10C20.1414 11.0414 19.1299 11.9467 18 12.685M18 12.685L19.5 14.5M10 14.309L9.5 16.5M14 14.309L14.5 16.5"
                            stroke="#35373A"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                ) : (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                    >
                        <rect width="24" height="24" fill="white" />
                        <path
                            d="M3 12C8.4 4 15.6 4 21 12C15.6 20 8.4 20 3 12Z"
                            stroke="#35373A"
                            strokeWidth="1.5"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M15 12C15 12.7956 14.6839 13.5587 14.1213 14.1213C13.5587 14.6839 12.7956 15 12 15C11.2044 15 10.4413 14.6839 9.87868 14.1213C9.31607 13.5587 9 12.7956 9 12C9 11.2044 9.31607 10.4413 9.87868 9.87868C10.4413 9.31607 11.2044 9 12 9C12.7956 9 13.5587 9.31607 14.1213 9.87868C14.6839 10.4413 15 11.2044 15 12Z"
                            stroke="#35373A"
                            strokeWidth="1.5"
                            strokeLinejoin="round"
                        />
                    </svg>
                )}
            </button>
            {!props.status && <p className={styles.errorMsg}>{props.comment}</p>}
        </div>
    );
};

function ApiRow({ item, allItems, removeApi, setNewApi, setApiChanged, saveModal, newApi }) {
    const [open, setOpen] = useState(newApi.name === item?.name);

    const [name, changeName] = useState(item.name);
    const [inn, changeInn] = useState(item.inn);

    const [defaultInfo, setDefaultInfo] = useState();

    const [info, setInfo] = useState({ ...item });

    useEffect(() => {
        if (JSON.stringify(defaultInfo) !== JSON.stringify(info) && open) {
            setApiChanged(true);
            setNewApi(info);
        } else {
            setApiChanged(false);
        }
    }, [info]);

    function replaceNulls(obj) {
        for (let key in obj) {
            if (obj[key] === null && !key.includes('status')) {
                obj[key] = '';
            }
        }
        return obj;
    }

    const getCurrentInfo = async () => {
        try {
            const response = await getData(
                API_URL + `/api/v1/organisation/${item.id}/`,
            );

            const newInfo = {
                ...response,
                marketplace: response.marketplace.map((item) => ({
                    ...replaceNulls(item),
                    type: 'password',
                })),
                banks: response.banks.map((item) => ({
                    ...replaceNulls(item),
                    type: 'password',
                })),
            };

            setInfo({ ...newInfo });
            setDefaultInfo({
                ...response,
                marketplace: response.marketplace.map((item) => ({
                    ...replaceNulls(item),

                    type: 'password',
                })),
                banks: response.banks.map((item) => ({
                    ...replaceNulls(item),
                    type: 'password',
                })),
            });
        } catch (error) {
            if (item.type === 'custom') {
                setInfo({
                    id: allItems[allItems.length - 1].id + 1,
                    banks: [],
                    marketplace: [],
                    name: item.name,
                    user: item.user,
                });
            }
        }
    };

    useEffect(() => {
        if (open && info.id) {
            getCurrentInfo();
        }
    }, [open]);

    const changeInfo = (category, item, field, value) => {
        const newInfo = { ...info };
        const newCurrentField = newInfo[category].find(
            (temp) => temp.id === item.id,
        );

        newCurrentField[field] = value;

        newInfo[category] = [
            ...newInfo[category].filter((temp) => temp.id !== item.id),
            newCurrentField,
        ];

        setInfo(newInfo);
    };

    const [openCreateMarketModal, setOpenCreateMarketModal] = useState(false);
    const [openCreateBankModal, setOpenCreateBankModal] = useState(false);
    const [openEditMarketModal, setOpenEditMarketModal] = useState(false);

    const changeCurrentApiName = async () => {
        try {
            if (info.name !== name && name.trim().length > 0) {
                setInfo({
                    ...info,
                    name: name,
                });
            }
        } catch (error) {
            console.log(error);
        }
    };
    const changeCurrentOrgInn = async () => {
        try {
            if (info.inn !== inn && inn.trim().length > 0) {
                setInfo({
                    ...info,
                    inn: inn,
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

    const addMp = async (name) => {
        try {
            let data = {
                organisation: item.id,
                marketplace: name,
                apiKey: '',
                apiKey_adv: '',
                apiKey_review: '',
            };

            if (name === 'OZON') {
                data = { ...data, client_id: null };
            }
            setApiChanged(true);

            // const response = await postData(
            //     API_URL + '/api/v1/settings/marketplace/create/',
            //     { ...data }
            // );
            if (info) {
                setInfo({
                    ...info,
                    marketplace: [
                        ...info.marketplace,
                        {
                            marketplace: name,
                            apiKey: null,
                            apiKey_adv: null,
                            apiKey_review: null,
                            comment: null,
                            comment_adv: null,
                            status_adv: null,
                            status_cookie: null,
                            status_review: null,
                            status: null,
                        },
                    ],
                });
                setNewApi({
                    ...info,
                    marketplace: [
                        ...info.marketplace,
                        {
                            marketplace: name,
                            apiKey: null,
                            apiKey_adv: null,
                            apiKey_review: null,
                            comment: null,
                            comment_adv: null,
                            status_adv: null,
                            status_cookie: null,
                            status_review: null,
                            status: null,
                        },
                    ],
                });
            } else {
                setInfo({
                    ...item,
                    marketplace: [
                        ...item.marketplace,
                        {
                            marketplace: name,
                            apiKey: null,
                            apiKey_adv: null,
                            apiKey_review: null,
                            comment: null,
                            comment_adv: null,
                            status_adv: null,
                            status_cookie: null,
                            status_review: null,
                            status: null,
                        },
                    ],
                });
                setNewApi({
                    ...item,
                    marketplace: [
                        ...item.marketplace,
                        {
                            marketplace: name,
                            apiKey: null,
                            apiKey_adv: null,
                            apiKey_review: null,
                            comment: null,
                            comment_adv: null,
                            status_adv: null,
                            status_cookie: null,
                            status_review: null,
                            status: null,
                        },
                    ],
                });
            }
        } catch (error) {
            console.log(error);
        }

        setOpenCreateMarketModal(false);
    };

    const addBank = async (name) => {
        try {
            setApiChanged(true);
            if (info) {
                setInfo({
                    ...info,
                    banks: [
                        ...info.banks,
                        {
                            bank_name: name,
                            comment: null,
                            status: null,
                        },
                    ],
                });
                setNewApi({
                    ...info,
                    banks: [
                        ...info.banks,
                        {
                            bank_name: name,
                            comment: null,
                            status: null,
                        },
                    ],
                });
            } else {
                setInfo({
                    ...item,
                    banks: [
                        ...item.banks,
                        {
                            bank_name: name,
                            comment: null,
                            status: null,
                        },
                    ],
                });
                setNewApi({
                    ...item,
                    banks: [
                        ...item.banks,
                        {
                            bank_name: name,
                            comment: null,
                            status: null,
                        },
                    ],
                });
            }
        } catch (error) {
        }

        setOpenCreateBankModal(false);
    };

    const infoInputBlur = async (value, name, type, objectName) => {
        // try {
        //     if (value.trim().length > 0) {
        //         const newObj = { ...info };
        //         newObj[type].find((temp) =>
        //             type === 'marketplace'
        //                 ? temp.marketplace === objectName
        //                 : temp.bank_name === objectName
        //         )[name] = value;
        //
        //         const response = await postData(
        //             API_URL + '/api/v1/settings/organisation/create/',
        //             newObj
        //         );
        //     }
        // } catch (error) {}
    };

    useEffect(() => {
        if (!info.id) {
            setNewApi({ ...info });
        }
    }, [info]);

    /*console.log(newApi);
    console.log(item);*/
    return (
        <div className={styles.row}>
            <button
                onClick={() => {
                    if (!saveModal || newApi.name === item?.name) {
                        setOpen(!open);
                    }
                }}
                className={`${styles.titleContent} ${open ? styles.open : ''}`}
            >
                <p className={styles.title}>
                    {info?.name || item.name}
                    {!item.status && (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                        >
                            <g fill="none">
                                <path
                                    stroke="#ff523a"
                                    strokeWidth="1.5"
                                    d="M5.312 10.762C8.23 5.587 9.689 3 12 3c2.31 0 3.77 2.587 6.688 7.762l.364.644c2.425 4.3 3.638 6.45 2.542 8.022S17.786 21 12.364 21h-.728c-5.422 0-8.134 0-9.23-1.572s.117-3.722 2.542-8.022z"
                                />
                                <path
                                    stroke="#ff523a"
                                    strokeLinecap="round"
                                    strokeWidth="1.5"
                                    d="M12 8v5"
                                />
                                <circle cx="12" cy="16" r="1" fill="#ff523a" />
                            </g>
                        </svg>
                    )}
                </p>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                >
                    <path
                        d="M5.91615 5.40311L9.80995 1.19523C10.0634 0.921526 10.0634 0.478983 9.80995 0.205279C9.55667 -0.0684262 9.14714 -0.0684262 8.89386 0.205279L4.99994 4.41315L1.10614 0.205279C0.85274 -0.0684262 0.443335 -0.0684262 0.190051 0.205279C-0.0633505 0.478983 -0.0633505 0.921526 0.190051 1.19523L4.08385 5.40311C4.08385 5.40311 4.59251 6 4.99994 6C5.40737 6 5.91615 5.40311 5.91615 5.40311Z"
                        fill="#35373A"
                    />
                </svg>

            </button>
            {open && (
                <div className={styles.openContent}>
                    <div className={styles.infoTopRow}>
                        <div className={styles.main_info_org}>
                            <div className={styles.name}>
                                <p>Название</p>
                                <NewCustomInput
                                    onBlur={changeCurrentApiName}
                                    value={name}
                                    onChange={changeName}
                                    width={'320px'}
                                    placeholder={'Введите название'}
                                />
                            </div>
                            <div className={styles.name}>
                            <p>ИНН</p>
                            <NewCustomInput
                                onBlur={changeCurrentOrgInn}
                                value={inn}
                                onChange={changeInn}
                                width={'320px'}
                                placeholder={'Введите ИНН'}
                            />
                        </div>
                        </div>
                        <div className={styles.buttons}>
                            {openCreateMarketModal && (
                                <div className={styles.createMarketModal}>
                                    <span
                                        className={styles.bgClose}
                                        onClick={() => setOpenCreateMarketModal(false)}
                                    />
                                    <div className={styles.modalContent}>
                                        <button
                                            disabled={info?.marketplace
                                                ?.map((temp) => temp.marketplace)
                                                .includes('WB')}
                                            className={styles.createMpBtn}
                                            onClick={() => addMp('WB')}
                                        >
                                            WB
                                        </button>
                                        <button
                                            disabled={info?.marketplace
                                                ?.map((temp) => temp.marketplace)
                                                .includes('OZON')}
                                            className={styles.createMpBtn}
                                            onClick={() => addMp('OZON')}
                                        >
                                            OZON
                                        </button>
                                    </div>
                                </div>
                            )}
                            {openEditMarketModal && (
                                <div
                                    className={`${styles.createMarketModal} ${styles.removeModal}`}
                                >
                                    <span
                                        className={styles.bgClose}
                                        onClick={() => setOpenEditMarketModal(false)}
                                    />
                                    <div className={styles.modalContent}>
                                        <button
                                            className={styles.remove}
                                            onClick={() => removeApi(item.id)}
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="17"
                                                viewBox="0 0 16 17"
                                                fill="none"
                                            >
                                                <path
                                                    d="M0.711921 5.07487H1.88576V16.2994C1.88622 16.4519 1.9482 16.5981 2.05817 16.7059C2.16814 16.8138 2.31716 16.8746 2.47268 16.875H13.2337C13.3893 16.8746 13.5383 16.8138 13.6482 16.7059C13.7582 16.5981 13.8202 16.4519 13.8207 16.2994V5.07487H15.2881C15.4437 5.07487 15.593 5.01423 15.7031 4.90629C15.8132 4.79835 15.875 4.65195 15.875 4.4993C15.875 4.34665 15.8132 4.20026 15.7031 4.09232C15.593 3.98438 15.4437 3.92374 15.2881 3.92374H12.4902C12.4694 2.9445 12.0914 2.00499 11.4249 1.27552C10.612 0.431733 9.42828 0 7.89248 0C6.35667 0 5.2019 0.431733 4.46856 1.28548C3.86859 2.03357 3.57548 2.97458 3.64678 3.92374H0.711921C0.634845 3.92374 0.558525 3.93863 0.487316 3.96755C0.416108 3.99648 0.351406 4.03887 0.296905 4.09232C0.242405 4.14577 0.199172 4.20921 0.169677 4.27905C0.140181 4.34888 0.125 4.42372 0.125 4.4993C0.125 4.57489 0.140181 4.64973 0.169677 4.71956C0.199172 4.78939 0.242405 4.85284 0.296905 4.90629C0.351406 4.95973 0.416108 5.00213 0.487316 5.03105C0.558525 5.05998 0.634845 5.07487 0.711921 5.07487V5.07487ZM5.34907 2.02431C5.84804 1.43903 6.69906 1.15137 7.88281 1.15137C9.07623 1.15137 9.97631 1.45847 10.5536 2.05322C11.0179 2.57253 11.284 3.23371 11.3067 3.92398H4.82062C4.7485 3.24825 4.93703 2.57052 5.34907 2.02431V2.02431ZM12.6468 5.07487V15.7236H3.05886V5.07487H12.6468Z"
                                                    fill="#FF5E5B"
                                                />
                                                <path
                                                    d="M5.99415 14.0927C6.14967 14.0923 6.29869 14.0315 6.40866 13.9237C6.51863 13.8158 6.58061 13.6697 6.58107 13.5172V6.89783C6.58107 6.74518 6.51923 6.59878 6.40916 6.49084C6.29909 6.3829 6.14981 6.32227 5.99415 6.32227C5.83849 6.32227 5.6892 6.3829 5.57913 6.49084C5.46906 6.59878 5.40723 6.74518 5.40723 6.89783V13.5172C5.40768 13.6697 5.46967 13.8158 5.57964 13.9237C5.68961 14.0315 5.83863 14.0923 5.99415 14.0927V14.0927Z"
                                                    fill="#FF5E5B"
                                                />
                                                <path
                                                    d="M9.71193 14.0927C9.86745 14.0923 10.0165 14.0315 10.1264 13.9237C10.2364 13.8158 10.2984 13.6697 10.2988 13.5172V6.89783C10.2988 6.74518 10.237 6.59878 10.1269 6.49084C10.0169 6.3829 9.86759 6.32227 9.71193 6.32227C9.55627 6.32227 9.40698 6.3829 9.29691 6.49084C9.18684 6.59878 9.125 6.74518 9.125 6.89783V13.5172C9.12546 13.6697 9.18745 13.8158 9.29742 13.9237C9.40739 14.0315 9.55641 14.0923 9.71193 14.0927V14.0927Z"
                                                    fill="#FF5E5B"
                                                />
                                            </svg>
                                            Удалить организацию
                                        </button>
                                    </div>
                                </div>
                            )}
                            {openCreateBankModal && (
                                <div className={`${styles.createMarketModal} ${styles.banks}`}>
                                    <span
                                        className={styles.bgClose}
                                        onClick={() => setOpenCreateBankModal(false)}
                                    />
                                    <div className={styles.modalContent}>
                                        <button
                                            disabled={info?.banks
                                                ?.map((temp) => temp.bank_name)
                                                .includes('Точка')}
                                            className={styles.createMpBtn}
                                            onClick={() => addBank('Точка')}
                                        >
                                            Точка
                                        </button>
                                    </div>
                                </div>
                            )}
                            <button
                                className={styles.add}
                                onClick={() => setOpenCreateMarketModal(true)}
                            >
                                Подключить МП
                            </button>
                            <button
                                onClick={() => setOpenCreateBankModal(true)}
                                className={styles.add}
                            >
                                Подключить Банк
                            </button>
                            <button
                                className={styles.settings}
                                onClick={() => setOpenEditMarketModal(true)}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                >
                                    <path
                                        d="M20.8945 9.87492L19.4598 9.56303C19.335 9.18107 19.1805 8.80851 18.998 8.44963L19.7923 7.21417C19.9341 6.99343 19.903 6.70361 19.7175 6.51819L17.4825 4.28312C17.297 4.0977 17.0072 4.06651 16.7865 4.20835L15.551 5.0026C15.1921 4.82017 14.8196 4.66565 14.4376 4.54089L14.1257 3.1062C14.07 2.84985 13.843 2.66699 13.5807 2.66699H10.4199C10.1576 2.66699 9.9306 2.84985 9.87492 3.1062L9.56303 4.54089C9.18107 4.66565 8.80851 4.82017 8.44963 5.0026L7.21417 4.20835C6.99343 4.06651 6.70361 4.0977 6.51819 4.28312L4.28312 6.51819C4.0977 6.70361 4.06651 6.99343 4.20835 7.21417L5.0026 8.44963C4.82017 8.80851 4.66565 9.18107 4.54089 9.56303L3.1062 9.87492C2.84985 9.93075 2.66699 10.1576 2.66699 10.4199V13.5807C2.66699 13.843 2.84985 14.0699 3.1062 14.1257L4.54089 14.4376C4.66565 14.8196 4.82017 15.1921 5.0026 15.551L4.20835 16.7865C4.06651 17.0072 4.0977 17.297 4.28312 17.4825L6.51819 19.7175C6.70361 19.903 6.99343 19.9341 7.21417 19.7923L8.44963 18.998C8.80851 19.1805 9.18107 19.335 9.56303 19.4598L9.87492 20.8945C9.9306 21.1508 10.1576 21.3337 10.4199 21.3337H13.5807C13.843 21.3337 14.07 21.1508 14.1257 20.8945L14.4376 19.4598C14.8196 19.335 15.1921 19.1805 15.551 18.998L16.7865 19.7923C17.0072 19.9341 17.297 19.9031 17.4825 19.7175L19.7175 17.4825C19.903 17.297 19.9341 17.0072 19.7923 16.7865L18.998 15.551C19.1805 15.1921 19.335 14.8196 19.4598 14.4376L20.8945 14.1257C21.1508 14.0699 21.3337 13.843 21.3337 13.5807V10.4199C21.3337 10.1576 21.1508 9.93075 20.8945 9.87492V9.87492ZM15.3469 12.0003C15.3469 13.8456 13.8456 15.3469 12.0003 15.3469C10.1551 15.3469 8.65371 13.8456 8.65371 12.0003C8.65371 10.1551 10.1551 8.65371 12.0003 8.65371C13.8456 8.65371 15.3469 10.1551 15.3469 12.0003V12.0003Z"
                                        stroke="#35373A"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div className={styles.blocks}>
                        {info?.marketplace?.length > 0 && (
                            <div className={styles.mps}>
                                <p className={styles.mpsTitle}>Маркетплейс</p>
                                {info?.marketplace &&
                                    sortBy(info?.marketplace, 'id')
                                        ?.filter((temp) => temp.marketplace !== 'МойСклад')
                                        .map((temp, index) => (
                                            <div key={index} className={styles.block}>
                                                <p className={styles.blockTitle}>
                                                    <span>API</span> {temp.marketplace}
                                                    {temp.marketplace === 'WB' ? (
                                                        <div className={styles.inputs}>
                                                            <InputWrap
                                                                comment={temp.comment}
                                                                status={temp.status}
                                                                title="Статистика"
                                                                value={temp.apiKey || ''}
                                                                width="33%"
                                                                type={temp.type}
                                                                onChange={(value) => {
                                                                    changeInfo(
                                                                        'marketplace',
                                                                        temp,
                                                                        'apiKey',
                                                                        value,
                                                                    );
                                                                }}
                                                                onBlur={() => {
                                                                    infoInputBlur(
                                                                        temp.apiKey,
                                                                        'apiKey',
                                                                        'marketplace',
                                                                        'WB',
                                                                    );
                                                                }}
                                                            />
                                                            <InputWrap
                                                                comment={temp.comment_adv}
                                                                status={temp.status_adv}
                                                                title="Реклама"
                                                                value={temp.apiKey_adv || ''}
                                                                width="33%"
                                                                type={temp.type}
                                                                onChange={(value) => {
                                                                    changeInfo(
                                                                        'marketplace',
                                                                        temp,
                                                                        'apiKey_adv',
                                                                        value,
                                                                    );
                                                                }}
                                                                onBlur={() => {
                                                                    infoInputBlur(
                                                                        temp.apiKey,
                                                                        'apiKey',
                                                                        'marketplace',
                                                                        'WB',
                                                                    );
                                                                }}
                                                            />
                                                            <InputWrap
                                                                comment={temp.comment_review}
                                                                status={temp.status_review}
                                                                title="Отзывы"
                                                                value={temp.apiKey_review || ''}
                                                                width="33%"
                                                                type={temp.type}
                                                                onChange={(value) => {
                                                                    changeInfo(
                                                                        'marketplace',
                                                                        temp,
                                                                        'apiKey_review',
                                                                        value,
                                                                    );
                                                                }}
                                                                onBlur={() => {
                                                                    infoInputBlur(
                                                                        temp.apiKey_review,
                                                                        'apiKey_review',
                                                                        'marketplace',
                                                                        'WB',
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <div className={styles.inputs}>
                                                            <InputWrap
                                                                comment={temp.comment}
                                                                status={temp.status}
                                                                title="API-key"
                                                                value={temp.apiKey || ''}
                                                                width="100%"
                                                                type={temp.type}
                                                                onChange={(value) => {
                                                                    changeInfo(
                                                                        'marketplace',
                                                                        temp,
                                                                        'apiKey',
                                                                        value,
                                                                    );
                                                                }}
                                                                onBlur={() => {
                                                                    infoInputBlur(
                                                                        temp.apiKey,
                                                                        'apiKey',
                                                                        'marketplace',
                                                                        'OZON',
                                                                    );
                                                                }}
                                                            />
                                                            <InputWrap
                                                                comment={temp.comment}
                                                                status={true}
                                                                title="Client id"
                                                                value={temp.client_id || ''}
                                                                width="48%"
                                                                type={temp.type}
                                                                onChange={(value) => {
                                                                    changeInfo(
                                                                        'marketplace',
                                                                        temp,
                                                                        'client_id',
                                                                        value,
                                                                    );
                                                                }}
                                                                onBlur={() => {
                                                                    infoInputBlur(
                                                                        temp.client_id,
                                                                        'client_id',
                                                                        'marketplace',
                                                                        'OZON',
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                </p>
                                            </div>
                                        ))}
                            </div>
                        )}
                        {info?.banks?.length > 0 && (
                            <div className={styles.mps}>
                                <p className={styles.mpsTitle}>Банки</p>
                                {info?.banks &&
                                    sortBy(info?.banks, 'id')?.map((temp) => (
                                        <div className={styles.block}>
                                            <p className={styles.blockTitle}>
                                                <span>API</span> {temp.bank_name}
                                                <InputWrap
                                                    comment={temp.comment}
                                                    status={temp.status}
                                                    title="API-key"
                                                    value={temp.access_token || ''}
                                                    width="100%"
                                                    type={temp.type}
                                                    onChange={(value) => {
                                                        changeInfo('banks', temp, 'access_token', value);
                                                    }}
                                                    onBlur={() => {
                                                        infoInputBlur(
                                                            temp.access_token,
                                                            'access_token',
                                                            'banks',
                                                            temp.bank_name,
                                                        );
                                                    }}
                                                />
                                            </p>
                                        </div>
                                    ))}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}

export default ApiRow;
