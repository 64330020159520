import React from 'react'

const WaitIcon = _ => {

    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.97729 6.31603L5.97727 6.31605L5.97729 6.31603Z" fill="white" stroke="white" strokeWidth="1.5625" />
            <path d="M11.4697 12.5301C11.7625 12.823 12.2374 12.823 12.5303 12.5301C12.8232 12.2372 12.8232 11.7624 12.5303 11.4695L9.53033 8.46948C9.23744 8.1766 8.76255 8.1766 8.46967 8.46948C8.17678 8.76237 8.17678 9.23726 8.46967 9.53015L11.4697 12.5301Z" fill="white" />
        </svg>

    )

}

export default WaitIcon


