import React from 'react'
import style from '../../../../Purchase.module.scss'
import FilterIcon from '../../../../../../shared/ui/icons/Filter.jsx'
import cx from 'classnames';

const Head = ({type}) => {

    const handleSort = (value) => {
        return true
    }

    const getCountHeader = _ => {
        switch (type) {
            case 'shipment':
                return 'Отгрузили'                
            case 'acceptance':
                return 'Приняли'
            case 'purchase':
                return 'Заказали'
            default:
                break;
        }
    }

    return (

        <div className={style.row}>
            <div className={style.xScroll} >

                <div style={{ width: '80px' }} className={cx(style.column, style.header, style.center)}>
                    <span>№</span>
                </div>

                <div style={{ width: '60px' }} className={cx(style.column, style.header, style.center)}>
                    <span>Фото</span>
                </div>

                <div style={{ width: (type !== 'shipment' ? '566px' : '766px') }} className={cx(style.column, style.header, style.start)}>
                    <span>Наименование</span>
                    <span onClick={() => handleSort(column.value)}>
                        <FilterIcon />
                    </span>
                </div>

                {type !== 'shipment' ?
                    <div style={{ width: '200px' }} className={cx(style.column, style.header, style.end)}>
                        <span>{(type==='acceptance') ? 'В заказе, шт' : 'Принято'}</span>
                        <span onClick={() => handleSort(column.value)}>
                            <FilterIcon />
                        </span>
                    </div>
                    :
                    null
                }

                <div style={{ width: '200px' }} className={cx(style.column, style.header, style.end)}>
                    <span>Остаток</span>
                    <span onClick={() => handleSort(column.value)}>
                        <FilterIcon />
                    </span>
                </div>

                <div style={{ width: '200px' }} className={cx(style.column, style.header, style.end)}>
                    <span>Цена</span>
                    <span onClick={() => handleSort(column.value)}>
                        <FilterIcon />
                    </span>
                </div>

                <div style={{ width: '200px' }} className={cx(style.column, style.header, style.end)}>
                    <span>{getCountHeader()}, шт</span>
                    <span onClick={() => handleSort(column.value)}>
                        <FilterIcon />
                    </span>
                </div>

                <div style={{ width: '200px' }} className={cx(style.column, style.header, style.end)}>
                    <span>Сумма</span>
                    <span onClick={() => handleSort(column.value)}>
                        <FilterIcon />
                    </span>
                </div>

                <div style={{ width: '60px' }} className={cx(style.column, style.header)}></div>

            </div>
        </div>

    )
}

export default Head