import React, { useState } from "react";
import style from '../../../Purchase.module.scss'
import cx from 'classnames';
import RecycleIcon from "../../../../../shared/ui/icons/Recycle.jsx";

const PresetForm = ({ create = false, remove = false, id = false, defaultName = '', leftOutset }) => {

    const [name, setName] = useState(defaultName ?? '')

    return (
        <div className={style.moreModal} style={{ top: 0, left: leftOutset }}>
            <div className={style.presetForm}>
                <input value={name} onChange={(e) => setName(e.target.value)} />

                <div className={style.buttons}>
                    {create &&
                        <button onClick={() => create(name)} className={cx(style.topButton, style.create, style.savePreset)}>
                            <span>Сохранить</span>
                        </button>
                    }
                    
                        <button onClick={() => remove(id)} className={cx(style.topButton, style.remove)}>
                            <RecycleIcon />
                        </button>
                   
                </div>

            </div>
        </div>
    )
}

export default PresetForm