import React from "react";
import style from '../../../../../Purchase.module.scss'
import cx from 'classnames';
import { formatNumber2 } from "../../../../../../../static/js/commonFunc";
import { format, parseISO } from 'date-fns';
import RecycleIcon from "../../../../../../../shared/ui/icons/Recycle.jsx";
import BorderedPlusIcon from "../../../../../../../shared/ui/icons/BorderedPlus.jsx";
import { API_URL, postData, delData, putData } from "../../../../../../../static/js/request.js";
import { useParams } from 'react-router-dom';
import ArticleStore from "../../../../../../../store/Financing/ArticleStore";
import { popupNotification } from "../../../../../../../static/js/popupNotification.jsx";
import { deleteConfirmAlert } from "../../../../../../../static/js/commonFunc";

const Row = ({ name, user, data, type, documentType, toggleUpdate, getPayID=false, alertRef }) => {

    const { id } = useParams()

    const getArticleName = (id) => {
        return ArticleStore.fields[id]
    }

    const add = async (payID, warehouse = false) => {

        if (type === 'acceptance') {

            const requestData = {
                user: user,
                warehouse: warehouse,
                purchase: parseInt(id),
                id: payID
            };

            putData(API_URL + `/api/v1/purchase/acceptance/`, JSON.stringify(requestData))
                .then((response) => {
                    popupNotification(response, 'update')
                })


        }

        else {

            const requestData = {
                [documentType]: id,
                payment_source: 'касса',
                operation_cashbox: payID
            };

            postData(API_URL + `/api/v1/purchase/payments/${documentType}/create/`, requestData)
                .then((response) => {
                    popupNotification(response, 'create')
                })

        }

        toggleUpdate(true)

    }

    const remove = async (payID, warehouse = false) => {

        if (type === 'acceptance') {

            const requestData = {
                user: user,
                warehouse: warehouse,
                purchase: null,
                id: payID
            };

            putData(API_URL + `/api/v1/purchase/acceptance/`, JSON.stringify(requestData))
                .then((response) => {
                    popupNotification(response, 'delete')                    
                }) 
                .finally(toggleUpdate(true))   
                            

        }

        else {

            const removingID = getPayID(payID)            

            delData(API_URL + `/api/v1/purchase/payments/${documentType}/${id}/`, { id: removingID })
                .then((response) => {
                    popupNotification(response, 'delete')
                    
                })
                .finally(toggleUpdate(true))   

        }        

    }


    return (

        data && data.map((item, index) =>

            <div className={style.row} key={index}>
                <div className={style.xScroll}>

                    {
                        (type === 'acceptance') &&
                        <div style={{ width: '80px' }} className={cx(style.column, style.center)}>
                            {item.id}
                        </div>
                    }

                    {
                        (type === 'acceptance') &&
                        <div style={{ width: '80px' }} className={cx(style.column, style.center)}>
                            {item.purchase}
                        </div>
                    }

                    <div style={{ width: '115px' }} className={cx(style.column, style.date)}>
                        {type === 'acceptance' ? format(parseISO(item.acceptance_datetime), 'dd.MM.yyyy') : item.executed}
                    </div>



                    <div style={{ width: (type === 'acceptance') ? '150px' : '190px' }} className={cx(style.column, style.end)}>
                        {formatNumber2((type === 'acceptance') ? item.sum : item.amount)}
                    </div>

                    {
                        (type === 'acceptance') ?
                            <div style={{ width: '250px' }} className={cx(style.column, style.start)}>
                                {item.warehouse.name}
                            </div>
                            :
                            <div style={{ width: '250px' }} className={cx(style.column, style.start)}>
                                ООО «Название компании»
                            </div>
                    }

                    {
                        (type === 'pays') &&
                        <div style={{ width: '300px' }} className={cx(style.column, style.start)}>
                            {getArticleName(item.article)}
                        </div>
                    }

                    <div style={{ width: (type === 'acceptance') ? '430px' : '260px' }} className={cx(style.column, style.start)}>
                        {item.comment}
                    </div>

                    <div style={{ width: '45px' }} className={cx(style.column, style.header, style.end, style.action)}>

                        {name === "Добавленные" ?
                            <span onClick={() => deleteConfirmAlert( () => remove(item.id, (type === 'acceptance') && item.warehouse.id),alertRef )}><RecycleIcon /></span>
                            :
                            <span onClick={() => add(item.id, (type === 'acceptance') && item.warehouse.id)}><BorderedPlusIcon /></span>
                        }

                    </div>

                </div >
            </div>

        )


    )

}

export default Row