import React, { useEffect, useState } from 'react';
import List from '../../view/list/index.jsx';
import DataArray from './data.json'
import { API_URL, postData, delData } from '../../../../static/js/request.js';
import { transformFilters } from '../../../../static/js/transformFilters.jsx';
import { popupNotification } from '../../../../static/js/popupNotification.jsx';

const AcceptanceList = () => {

    const [columns, editColumns] = useState({
        id: { label: '№', isShow: true, value: 'id', type: 'id', noSort: true, width: "70px" },
        purchase: { label: '№ Заказа', isShow: true, value: 'purchase', type: 'id', noSort: true, width: "118px" },
        created_at: { label: 'Дата', isShow: true, value: 'created_at', filterValue: 'created_at', type: 'date', width: "148px" },
        warehouse: { label: 'Склад', isShow: true, value: 'warehouse', filterValue: 'warehouse_id', type: 'object', width: "198px", needSearch: true },
        counterparty: { label: 'Контрагент', isShow: true, value: 'counterparty', filterValue: 'counterparty', type: 'object', width: "329px", needSearch: true },
        organisation: { label: 'Организация', isShow: true, value: 'organisation', filterValue: 'organisation', type: 'object', width: "238px" },
        sum: { label: 'Сумма', isShow: true, value: 'sum', type: 'pay', width: "138px" },
        acceptanceproducts: { label: "Товары", filterValue: 'acceptanceproducts', needSearch: true },
        status: { label: 'Статус', isShow: true, value: 'status', filterValue: 'status', type: 'status', width: "188px" },
        comment: { label: 'Комментарий', isShow: true, value: 'comment', type: 'string', width: "329px" }
    })

    const [filters, setFilters] = useState([])

    const [sortingBy, setSortingBy] = useState({
        key: '',
        value: ''
    })

    const [data, setData] = useState(null)

    const transformedItems = filters
        .map((item) => {
            const transformedValue = Array.isArray(item.value)
                ? item.value
                    .map((v) => {
                        if (typeof v === 'object') {
                            return v.id || v.name;
                        }
                        return v;
                    })
                    .filter(Boolean)
                : item.value;

            if (Array.isArray(transformedValue) && transformedValue.length === 0) {
                return null;
            }

            return {
                key: item.key,
                value: transformedValue
            };
        })
        .filter(Boolean);

    const getData = async () => {

        try {

            const transformedFilters = transformFilters(filters)

            const requestData = {
                sorted: sortingBy.key ? [{ key: sortingBy.key, value: sortingBy.value }] : [],
                filters: transformedFilters
            };

            await postData(API_URL + `/api/v1/purchase/acceptance/list/`, requestData)
                .then((response) => response.json())
                .then((response) => {

                    setData(response.data.length > 0 && response.data)

                });

        } catch (error) {
            console.log("Не удалось получить данные", error)
        }
    }

    const removeItem = async (id) => {

        await delData(API_URL + '/api/v1/purchase/acceptance/', { id: id })
            .then((response) => {
                popupNotification(response, 'delete')
            })
            .then(getData())
    }

    useEffect(() => {
        getData()
    }, [sortingBy, filters])

    return (
        <List
            title="Приемки"
            presetTable="acceptanceList"
            newItemLink={{ name: "Создать приемку", value: "/purchase/acceptance/item/" }}
            filters={filters}
            setFilters={(value) => setFilters(value)}
            columns={columns}
            editColumns={(value) => editColumns(value)}
            data={data}
            setSortingBy={(item) => setSortingBy(item)}
            removeItem={(value) => removeItem(value)}
        />
    )

}

export default AcceptanceList