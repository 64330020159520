import React, { useState, useEffect, useRef } from "react"
import planFactstyle from '../PlanFact.module.scss' // Добавил новых стилей
import style from '../../../../components/custom/CustomSelect/style.module.scss'
import Arrow from "../icons/arrow.jsx";
import cx from 'classnames'
import { Checkbox } from "@mui/material";

const MultiSelect = ({ width, title, data, filter, alias, action }) => {

    const [isOpen, setIsOpen] = useState(false)
    const selectRef = useRef(null)

    const handleOptionClick = (value) => {        
        action(alias, value)
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (selectRef.current && !selectRef.current.contains(event.target)) {
                setIsOpen(false)
            }
        }
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, []);

    return (

        <div ref={selectRef} className={style.customSelect} style={{ width: width ? width : '' }}>

            <div className={cx(style.customSelectContent, isOpen ? style.active : '')}>

                <div className={cx(style.button, planFactstyle.option, isOpen && style.active , (filter.length > 0) && planFactstyle.checked )} onClick={() => setIsOpen(!isOpen)}>

                    <div className={planFactstyle.moved}>{title}</div>
                    {(filter.length > 0) && <div className={planFactstyle.moved}>{filter.length}</div>}                    
                    <div className={planFactstyle.arrow}><Arrow /></div>                    

                </div>

                {isOpen && (
                    <div className={cx(style.optionList, isOpen ? style.active : '')}>
                        {data.map((item, index) => (
                            <div
                                className={cx(style.option, planFactstyle.list_item)}
                                key={index}
                                onClick={() => handleOptionClick(item)}
                            >

                                <Checkbox checked={filter.includes(item)}                                    
                                    color="primary"
                                />

                                <span>{item.name}</span>

                            </div>
                        ))}
                    </div>
                )}

            </div>

        </div>

    )
}

export default MultiSelect