import React from "react";
import style from '../../../../Purchase.module.scss'
import cx from 'classnames';
import { formatNumber2 } from "../../../../../../static/js/commonFunc";

const Bottom = ({ data }) => {   

    return (

        <div className={style.bottom}>
            <div className={cx(style.column, style.label)} style={{width: "1306px"}}>
                <span><b>Всего:</b></span>
            </div>
            <div className={cx(style.values, ((data.itemType === 'shipment' && data.products.length<3) ? style.resized : null))}>
                <div className={cx(style.column, style.end)} style={{ width: "200px" }}>
                    <span><b>{data.products.reduce((total, product) => total + Number(product.count_plan ?? product.count), 0)}</b></span>
                </div>
                <div className={cx(style.column, style.end)} style={{ width: "200px" }}>
                    <span><b>{formatNumber2(data.products.reduce((total, product) => total + product.price * (product.count_plan ?? product.count), 0))}</b></span>
                </div>
            </div>

        </div>

    )
}

export default Bottom