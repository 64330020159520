import React, { useState, useEffect } from "react";

import style from '../../../Purchase.module.scss'
import { NewCustomInput } from "../../../../../components/custom/NewCustomInput/NewCustomInput.jsx";
import DateSelection from "../../../../../feature/DateSelection/index.jsx";
import CustomSelectNew from "../../../../../feature/SelectWithLabel/index.jsx";
import { Checkbox } from "@mui/material";
import cx from 'classnames';
import AddictionSide from './add/index.jsx'
import CustomTooltip from "../../../../../components/custom/CustomTooltip.jsx";


const TopRow = ({ itemParams, setItemParams }) => {

    return (
        <div className={cx(style.topRow, style.item)}>

            <div className={style.left}>

                <div className={style.column}>
                    <div className={style.pageTitle}><span>{itemParams.formTitle}</span></div>
                </div>

                <div className={style.column}>
                    <NewCustomInput
                        type={'text'}
                        value={itemParams.id || ''}
                        onChange={(value) => setItemParams(prev => ({
                            ...prev,
                            id: value
                        }))}
                        width={'80px'}
                        placeholder="№"
                        numeric
                        disabled

                    />
                </div>

                <div className={cx(style.column, style.date)}>
                    <DateSelection
                        selectedDate={itemParams.actionDate}
                        setSelectedDate={(value) => setItemParams(prev => ({
                            ...prev,
                            actionDate: value
                        }))}
                    />
                </div>



                <div className={style.column}>
                    <CustomSelectNew
                        options={[
                            { name: 'Заказ выполнен', style: 'success' },
                            { name: 'Частично выполнен', style: 'warning' },
                            { name: 'Не выполнен', style: 'error' },
                            { name: 'В пути', style: 'onWay' },
                        ]}
                        defaultOption={{ name: 'Статус' }}
                        onOptionChange={(value) => setItemParams(prev => ({
                            ...prev,
                            status: value.name
                        }))}
                        fieldName={'status'}
                        value={itemParams.status}
                        externalStyle={style}
                    />
                </div>



                <div className={style.column}>
                    <CustomTooltip
                        title="Если это поле отмечено, то этот документ вступит в силу для изменения остатков и расчета себестоимости"
                        placement="top-start"
                    >
                        <div>
                            <Checkbox
                                checked={itemParams.conducted}
                                color="primary"
                                size="large"
                                onChange={() => setItemParams(prev => ({
                                    ...prev,
                                    conducted: !prev.conducted
                                }))}
                            />
                            <span>Проведено</span>
                        </div>
                    </CustomTooltip>

                </div>

            </div>

            <AddictionSide itemParams={itemParams} />

        </div>
    )
}

export default TopRow