import React from "react";
import style from '../../../../Purchase.module.scss'
import cx from 'classnames';
import { formatNumber2 } from "../../../../../../static/js/commonFunc";
import RecycleIcon from "../../../../../../shared/ui/icons/Recycle.jsx";
import EditValue from "./edit.jsx";

const Row = ({ type, data, products, remove, edit }) => {

    const getProductValue = (id, type) => {

        if (products) {

            const res = products.filter(item => item.id === id)

            if (res[0][type]) {

                if (type === 'photo_url') {
                    return <img src={res[0][type]} loading="lazy" />
                }

                else {
                    return res[0][type]
                }

            }

            else {
                return null
            }

        }

        else {
            return null
        }



    }


    return (

        data.map((item, index) =>

            <div className={style.row} key={index}>
                <div className={style.xScroll}>

                    <div style={{ width: '80px' }} className={cx(style.column, style.center)}>
                        {item.product}
                    </div>

                    <div style={{ width: '60px' }} className={cx(style.column, style.center)}>
                        {getProductValue(item.product, 'photo_url')}
                    </div>

                    <div style={{ width: (type !== 'shipment' ? '566px' : '766px') }} className={cx(style.column, style.start)}>
                        {getProductValue(item.product, 'name')}
                    </div>

                    {
                        type !== 'shipment' && (
                            <div style={{ width: '200px' }} className={cx(style.column, style.end)}>
                                {(type === 'acceptance') ? (
                                    <EditValue
                                        value={item.count_fact || 0}
                                        column="count_fact"
                                        action={edit}
                                        id={item.product}
                                    />
                                ) : (
                                    '0'
                                )}
                            </div>
                        )
                    }

                    <div style={{ width: '200px' }} className={cx(style.column, style.end)}>
                        0
                    </div>

                    <div style={{ width: '200px' }} className={cx(style.column, style.end)}>
                        <EditValue
                            value={item.price}
                            column="price"
                            convertFunction={(value) => formatNumber2(value)}
                            action={edit}
                            id={item.product}
                        />

                    </div>

                    <div style={{ width: '200px' }} className={cx(style.column, style.end)}>
                        <EditValue
                            value={(type === 'acceptance') ? (item.count_plan ?? item.count_fact) : item.count}
                            column={(type === 'acceptance') ? "count_plan" : "count"}
                            action={edit}
                            id={item.product}
                        />
                    </div>

                    <div style={{ width: '200px' }} className={cx(style.column, style.end)}>
                        {formatNumber2(item.price * (item.count_plan ?? item.count))}
                    </div>

                    <div style={{ width: '60px' }} className={cx(style.column, style.header, style.end)}>

                        <span onClick={() => remove(item.product)}><RecycleIcon /></span>

                    </div>

                </div >
            </div>

        )


    )

}

export default Row