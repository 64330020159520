import React, { useState, useRef, useEffect } from 'react'
import style from '../../../Purchase.module.scss'
import { NewCustomInput } from "../../../../../components/custom/NewCustomInput/NewCustomInput.jsx";
import { Checkbox } from "@mui/material";
import cx from 'classnames';

const Search = ({ productList, setProductList, addedList }) => {

    const [filteredData, setFilteredData] = useState([]);
    const [searchValue, setSearchValue] = useState('')

    const elementRef = useRef(null)

    const handleInputChange = (searchData) => {
        const value = searchData.toLowerCase();
        setSearchValue(value);        

    };   

    const checkedLenth = productList && productList.filter(item => item.checked).length

    const getFirstChecked = () => {
        const list = productList && productList.filter(item => item.checked)

        return (list.length > 0) && `${list[0].name && list[0].name} ${list[0].vendorCode && list[0].vendorCode}`

    }

    const checkProduct = (id) => {        

        setProductList((prev) =>
            prev.map((product) =>
                product.id === id ? { ...product, checked: !product.checked } : product
            )
        );

    }

    const clickProduct = (id) => {  

        checkProduct(id)
        setSearchValue('');
        setFilteredData([]);

    }

    useEffect(() => {

        const element = elementRef.current;

        const handleOutsideClick = (e) => {

            if (element && !element.contains(e.target)) {
                setSearchValue('');
                setFilteredData([]);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };

    }, []);

    useEffect(() => {
        if (searchValue) {
            const filtered = productList.filter(
                (item) =>
                    item.id.toString().includes(searchValue) ||
                    (item.name && item.name.toLowerCase().includes(searchValue)) ||
                    item.vendorCode?.toLowerCase().includes(searchValue)
            );
    
            const addedIDs = new Set(addedList.map((item) => item.product));
    
            const parsedFilteredData = filtered.filter((item) => !addedIDs.has(item.id));
    
            setFilteredData(parsedFilteredData);
        } else {
            setFilteredData([]);
        }
    }, [productList, searchValue, addedList]);

    return (
        <div className={style.column}>
            <div className={style.select} ref={elementRef}>
                <div className={style.selectContent}>
                    <NewCustomInput
                        value={searchValue}
                        onChange={(value) => handleInputChange(value)}
                        width={'600px'}
                        placeholder={checkedLenth > 0 ?
                            (checkedLenth > 1 ?
                                `Выбрано ${checkedLenth} товаров`
                                :
                                `${getFirstChecked()}`)
                            :
                            'Добавить позицию - введите наименование, код, штрихкод или артикул'}
                    />

                    {
                        filteredData.length > 0
                        &&
                        <div className={cx(style.optionList, style.active)}>{
                            filteredData.map((item, index) =>
                                <div key={index} className={cx(style.option, style.product)}>
                                    <Checkbox
                                        checked={!!item.checked}
                                        size="large"
                                        onChange={() => checkProduct(item.id)}
                                    />
                                    <div className={style.info} onClick={() => clickProduct(item.id)}>
                                        {item.photo_url && <span><img src={item.photo_url} loading="lazy" /></span>}
                                        {item.name && <span>{item.name}</span>}
                                        {item.vendorCode && <span>{item.vendorCode}</span>}
                                    </div>

                                </div>
                            )
                        }
                        </div>

                    }

                </div>

            </div>
        </div>
    )
}

export default Search