import React from 'react'

const ErrorIcon = _ => {

    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13.0397 6.10797L12.8181 14.2557H11.1874L10.9602 6.10797H13.0397ZM11.9999 17.8921C11.6249 17.8921 11.303 17.7595 11.034 17.4943C10.7651 17.2254 10.6325 16.9034 10.6363 16.5284C10.6325 16.1572 10.7651 15.839 11.034 15.5739C11.303 15.3087 11.6249 15.1762 11.9999 15.1762C12.3598 15.1762 12.6761 15.3087 12.9488 15.5739C13.2215 15.839 13.3598 16.1572 13.3636 16.5284C13.3598 16.7784 13.2935 17.0076 13.1647 17.2159C13.0397 17.4205 12.8749 17.5852 12.6704 17.7102C12.4659 17.8315 12.2424 17.8921 11.9999 17.8921Z" fill="white" />
        </svg>
    )

}

export default ErrorIcon


