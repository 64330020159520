import React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: 'rgba(41, 55, 76, 1)',
        color: '#fff',
        boxShadow: theme.shadows[1],
        padding: 20,
        borderRadius: 8,
        fontFamily: 'inherit'
    }
}));


const CustomTooltip = ({ title, placement, children }) => {
    return (
        <StyledTooltip
            title={<Typography style={{ fontFamily: 'inherit' }}>{title}</Typography>}
            placement={placement}
            arrow={false}
        >
            {children}
        </StyledTooltip>
    );
};

export default CustomTooltip;