import React from 'react'

const MagnifierIcon = _ => {

    return (
        <svg viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.9604 16.2555L14.9613 16.2555C15.0534 16.2558 15.1446 16.237 15.2291 16.2003C15.3134 16.1638 15.3892 16.1104 15.4521 16.0434C15.5792 15.9109 15.65 15.7339 15.65 15.55C15.65 15.3658 15.5789 15.1885 15.4514 15.0559L15.4514 15.0559L15.4501 15.0545L11.1156 10.6647C12.1127 9.50883 12.6482 8.01714 12.6144 6.48117C12.5795 4.89768 11.9422 3.38838 10.8344 2.2671C9.72659 1.14577 8.23349 0.498783 6.66539 0.461116C5.09728 0.42345 3.57541 0.998021 2.41609 2.06494C1.25681 3.13181 0.549242 4.60885 0.439971 6.18888C0.330702 7.76891 0.828086 9.33094 1.82912 10.5503C2.8302 11.7697 4.25806 12.5526 5.81607 12.7357C7.33048 12.9138 8.85407 12.5118 10.0875 11.6123L14.4728 16.0449C14.536 16.1105 14.6115 16.163 14.6949 16.1991L14.7546 16.0615L14.695 16.1991C14.7788 16.2355 14.8691 16.2546 14.9604 16.2555ZM1.71319 6.53891C1.71322 5.5823 1.99391 4.6473 2.5196 3.85215C3.04531 3.05699 3.79234 2.43747 4.66606 2.07171C5.53976 1.70594 6.50107 1.61027 7.42847 1.79671C8.35589 1.98316 9.208 2.4434 9.87696 3.11949C10.5459 3.79561 11.0017 4.65722 11.1863 5.59545C11.371 6.5337 11.2762 7.5062 10.914 8.38991C10.5519 9.27361 9.93864 10.0287 9.15218 10.5598C8.36577 11.0908 7.44138 11.3742 6.49588 11.3742C5.2283 11.3731 4.01264 10.8637 3.11584 9.95728C2.21898 9.05086 1.71434 7.82147 1.71319 6.53891Z" fill="#8496AF" stroke="#8496AF" strokeWidth="0.3"/>
        </svg>
    )

}

export default MagnifierIcon


